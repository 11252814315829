import environment from 'environment';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { AddClickTrackingMutationVariables } from '@cca/chatbot-graphql-types';

import apolloClient from '../../../services/apolloClient';

import { addClickTrackingGql } from './queries.gql';

export const submitClickTracking = createAsyncThunk<
  void,
  AddClickTrackingMutationVariables
>('clickTracking/submit', async ({ clickTracking }) => {
  if (environment.enableClickTracking) {
    await apolloClient.mutate({
      mutation: addClickTrackingGql,
      variables: {
        clickTracking,
      },
    });
  }
});
