import { graphql } from '@cca/chatbot-graphql-types';

const flowFeedbackFragmentGql = graphql(/* GraphQL*/ `
  fragment FlowFeedbackMessageFields on FlowFeedbackMessage {
    id
    scope
    type
    label
    flowContext
  }
`);

export default flowFeedbackFragmentGql;
