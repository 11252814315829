import { Box } from 'theme-ui';

import { WithSizeProp } from '../../types/size';
import { StarIcon } from '../icons';

type Props = WithSizeProp<{
  fillPercentage?: number;
  hovered?: boolean;
  disabled?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}>;

const Star = ({
  size,
  fillPercentage,
  onMouseEnter,
  onMouseLeave,
  onClick,
  disabled,
  hovered,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        cursor: !disabled ? 'pointer' : undefined,
      }}
      onMouseEnter={!disabled ? onMouseEnter : undefined}
      onMouseLeave={!disabled ? onMouseLeave : undefined}
      onClick={!disabled ? onClick : undefined}
    >
      <StarIcon
        size={size}
        color="primary"
        backgroundColor="grey2"
        fillPercentage={hovered ? 100 : fillPercentage}
      />
    </Box>
  );
};

export default Star;
