import { Box } from 'theme-ui';

import { Position } from '@cca/util';

type Props = {
  direction: Extract<Position, Position.Left | Position.Right>;
  color: string;
};

const ArrowHead = ({ direction, color }: Props) => {
  // NOTE: If you the arrow should point to the right
  // simply change `borderRight` to `borderLeft` below
  return (
    <Box
      sx={{
        width: 0,
        height: 0,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        [direction === Position.Left ? 'borderRight' : 'borderLeft']:
          `10px solid ${color}`,
      }}
    />
  );
};

export default ArrowHead;
