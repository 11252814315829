import { Font } from '../global-fonts/GlobalFonts';
import EurostileNextLtProBoldWoff from '../global-fonts/fonts/EurostileNextLTPro-Bold.woff';
import EurostileNextLtProBoldWoff2 from '../global-fonts/fonts/EurostileNextLTPro-Bold.woff2';
import SourceSansProLatin400Woff from '../global-fonts/fonts/source-sans-pro-latin-400.woff';
import SourceSansProLatin400Woff2 from '../global-fonts/fonts/source-sans-pro-latin-400.woff2';
import SourceSansProLatin600Woff from '../global-fonts/fonts/source-sans-pro-latin-600.woff';
import SourceSansProLatin600Woff2 from '../global-fonts/fonts/source-sans-pro-latin-600.woff2';
import SourceSansProLatin700Woff from '../global-fonts/fonts/source-sans-pro-latin-700.woff';
import SourceSansProLatin700Woff2 from '../global-fonts/fonts/source-sans-pro-latin-700.woff2';

const fonts: Font[] = [
  {
    name: 'Eurostile Next LT Pro',
    url: {
      woff: EurostileNextLtProBoldWoff,
      woff2: EurostileNextLtProBoldWoff2,
    },
    weight: 400,
  },
  {
    name: 'Source Sans Pro',
    url: {
      woff: SourceSansProLatin400Woff,
      woff2: SourceSansProLatin400Woff2,
    },
    weight: 400,
  },
  {
    name: 'Source Sans Pro',
    url: {
      woff: SourceSansProLatin600Woff,
      woff2: SourceSansProLatin600Woff2,
    },
    weight: 600,
  },
  {
    name: 'Source Sans Pro',
    url: {
      woff: SourceSansProLatin700Woff,
      woff2: SourceSansProLatin700Woff2,
    },
    weight: 700,
  },
];

export default fonts;
