import { ForwardedRef } from 'react';
import { Box } from 'theme-ui';
import { XOR } from 'ts-essentials';

import { EnergyEfficiencyClassEu2017_1369 } from '@cca/chatbot-graphql-types';

import { forwardRefWithDisplayName } from '../../../hocs';
import {
  EnergyEfficiencyLabel,
  LegacyEnergyEfficiencyLabel,
} from '../energy-efficiency/';

type LegacyEnergyEfficiencyProps = {
  grade: CCA.GraphQLEnergyEfficiencyClass;
};

type EnergyEfficiencyProps = {
  gradeEu2017_1369: EnergyEfficiencyClassEu2017_1369;
};

export type ProductEfficiencyProps = XOR<
  LegacyEnergyEfficiencyProps,
  EnergyEfficiencyProps
>;

const isLegacy = (
  props: LegacyEnergyEfficiencyProps | EnergyEfficiencyProps,
): props is LegacyEnergyEfficiencyProps => 'grade' in props;

const ProductEfficiency = (
  props: ProductEfficiencyProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Box ref={ref}>
      {!isLegacy(props) ? (
        <EnergyEfficiencyLabel grade={props.gradeEu2017_1369} />
      ) : (
        <LegacyEnergyEfficiencyLabel grade={props.grade} />
      )}
    </Box>
  );
};

export default forwardRefWithDisplayName(
  ProductEfficiency,
  'ProductEfficiency',
);
