/** @jsxImportSource theme-ui */
import useThemeBase from '../../../theme/provider/useThemeBase';

type Props = { color?: string };
const HomeIcon = ({ color = 'iconPrimary' }: Props) => {
  const theme = useThemeBase();

  const mmIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.968"
      height="16.769"
      viewBox="0 0 19.968 16.769"
    >
      <g transform="translate(-0.016 -0.099)">
        <path
          sx={{ fill: color }}
          d="M7.968,16.769H3V8.87H0L9.984,0l9.984,8.87h-3v7.9H12V10.856H7.968Z"
          transform="translate(0.016 0.099)"
        />
      </g>
    </svg>
  );
  const seIcon = (
    <svg
      width="16.769"
      height="16.769"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        sx={{ fill: color }}
        d="M 40 17.778 L 40 40 L 26.667 40 L 26.667 24.444 L 13.333 24.444 L 13.333 40 L 0 40 L 0 17.778 L 17.778 0 L 22.222 0 Z"
      />
    </svg>
  );

  return theme === 'mm' ? mmIcon : seIcon;
};

export default HomeIcon;
