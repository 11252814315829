import { Theme } from 'theme-ui';

const sharedStyles = {
  backgroundColor: 'grey1',
  backgroundSize: 'contain',
  borderRadius: '13px',
  borderStyle: 'solid',
  borderColor: 'white',
  borderWidth: 'thin',
};

export default {
  layout: {
    intentFeedback: {
      ...sharedStyles,
      moreFeedback: {
        ...sharedStyles,
        px: '4px',
        py: '2px',
        position: 'absolute',
        lineHeight: 0,
        cursor: 'pointer',
        ':disabled': {
          opacity: 0.4,
          cursor: 'not-allowed',
          '&:hover': {
            backgroundColor: 'white',
            boxShadow: 'none',
          },
        },
      },
    },
  },
} as Theme;
