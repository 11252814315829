import { ComponentType, ForwardedRef } from 'react';
import { Box, Flex } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';
import { Text } from '../../text';
import Button, { ButtonProps } from '../Button';

export type QuickReplyOptionProps = { label: string; onClick: () => void };
type Props = {
  options: QuickReplyOptionProps[];
  fullWidth?: boolean;
  disabled?: boolean;
  ButtonComponent?: ComponentType<ButtonProps>;
};
const QuickReply = (
  {
    options,
    fullWidth = true,
    disabled = false,
    ButtonComponent = Button,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Flex ref={ref}>
      {options.map((option, optionIndex) => (
        <Box
          key={optionIndex}
          sx={{
            flex: '1 0 auto',
            ...(optionIndex < options.length - 1 && { paddingRight: '10px' }),
            ...(optionIndex > 0 && { paddingLeft: '10px' }),
          }}
        >
          <Button
            key={optionIndex}
            onClick={() => option.onClick()}
            fullWidth={fullWidth}
            outlined
            disabled={disabled}
          >
            <Text>{option.label}</Text>
          </Button>
        </Box>
      ))}
    </Flex>
  );
};

export default forwardRefWithDisplayName(QuickReply, 'QuickReply');
