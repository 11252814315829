import { ClickActionInput } from '@cca/chatbot-graphql-types';

const createAction = <P = void>(type: string) => {
  return (parameters: P): ClickActionInput => {
    return {
      type,
      ...(parameters && { parameters }),
    };
  };
};

export const ClickTrackingActions = {
  NavigationShow: createAction('navigation.show'),
  ListSelect: createAction<{ index: number }>('list.select'),
  ProductOpenPDP: createAction('product.openPdp'),
  ProductCheckAvailability: createAction<{ disabled: boolean }>(
    'product.checkAvailability',
  ),
  ProductCardExpand: createAction<{ expand: boolean }>('product.card.expand'),
  ProductSuggestionSelect: createAction<{ suggestion: string }>(
    'product.suggestion.select',
  ),
  SurveyModalCancel: createAction('survey.modal.cancel'),
  SurveyModalSubmit: createAction('survey.modal.submit'),
  SurveyModalShow: createAction<{ disabled: boolean }>('survey.modal.show'),
  ExternalSurveyShow: createAction<{ disabled: boolean }>(
    'survey.external.show',
  ),
  OutletCardSelect: createAction<{ outletId: number }>('outlet.card.select'),
  OutletCardGoBack: createAction<{ outletId: number }>('outlet.card.goBack'),
  OutletOpenODP: createAction('outlet.openOdp'),
  LocationSuggestionSelect: createAction<{ suggestion: string }>(
    'location.suggestion.select',
  ),
  LinkClick: createAction<{ title: string; url: string }>('link.click'),
  FaqCardSelectFaq: createAction<{ faqId: number }>('faq.card.select.faq'),
  FaqCardSelectService: createAction('faq.card.select.service'),
  FrameMinimize: createAction('frame.minimize'),
  ButtonClick: createAction<{ disabled: boolean; label: string }>(
    'button.click',
  ),
  FlowFeedbackVote: createAction<{ disabled: boolean }>('flowFeedback.vote'),
};
