import { Box, Label, Radio } from 'theme-ui';

export type SurveyCategoryListItemProps = {
  label: string;
  checked: boolean;
  onChange?: () => void;
};
const SurveyCategoryListItem = ({
  label,
  checked,
  onChange,
}: SurveyCategoryListItemProps) => {
  return (
    <Box as="li" variant="layout.surveyCategory.listItem">
      <Label>
        <Radio
          checked={checked}
          onChange={onChange}
          variant="layout.surveyCategory.radioButton"
        />
        {label}
      </Label>
    </Box>
  );
};

export default SurveyCategoryListItem;
