import { FeedbackValue } from '@cca/chatbot-graphql-types';

import { FeedbackState } from './types';

const feedbackState = new Map([
  [FeedbackValue.Like, FeedbackState.POSITIVE],
  [FeedbackValue.Dislike, FeedbackState.NEGATIVE],
]);

export function getFeedbackState(feedbackValue: FeedbackValue) {
  return feedbackState.get(feedbackValue);
}
