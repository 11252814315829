import Feature from '../product-feature/Feature';

export type ProductFeature = {
  name: string;
  value: string;
};

type Props = {
  features: ProductFeature[];
};
const ProductFeatureList = ({ features }: Props) => {
  return (
    <>
      {features.map((feature) => (
        <Feature key={feature.name} name={feature.name} value={feature.value} />
      ))}
    </>
  );
};

export default ProductFeatureList;
