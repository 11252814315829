import {
  actionFragmentGql,
  botAvatarFragmentGql,
  cardFragmentGql,
  contactCardFragmentGql,
  contactCardOpeningsFragmentGql,
  faqCardFragmentGql,
  faqWithScoreFragmentGql,
  flowFeedbackFragmentGql,
  interpolatedTextFragmentGql,
  locationSearchInputFragmentGql,
  outletWithDistanceFragmentGql,
  outletsCardFragmentGql,
  productCardFragmentGql,
  productFragmentGql,
  productSearchInputFragmentGql,
  quickReplyFragmentGql,
  serviceSiteFragmentGql,
  surveyButtonFragmentGql,
  textFragmentGql,
} from '@cca/chatbot-graphql-fragments';

import {
  ApiResponseFieldsFragment,
  MessagesFieldsFragment,
  withFragment,
} from './';

export type MessageFields = ReturnType<typeof withMessage>;

export function withMessage(fragment: MessagesFieldsFragment) {
  switch (fragment.__typename) {
    case 'BotAvatarMessage':
      return withFragment(botAvatarFragmentGql, fragment);
    case 'CardMessage': {
      const card = withFragment(cardFragmentGql, fragment);
      return {
        ...card,
        list: card.list?.map((item) => ({
          ...item,
          action: withFragment(actionFragmentGql, item.action),
        })),
      };
    }
    case 'ContactCardMessage': {
      const contactCard = withFragment(contactCardFragmentGql, fragment);
      return {
        ...contactCard,
        weekdays: withFragment(
          contactCardOpeningsFragmentGql,
          contactCard.weekdays,
        ),
        holidays: withFragment(
          contactCardOpeningsFragmentGql,
          contactCard.holidays,
        ),
      };
    }
    case 'FaqCardMessage': {
      const faqCard = withFragment(faqCardFragmentGql, fragment);
      return {
        ...faqCard,
        serviceSite: withFragment(serviceSiteFragmentGql, faqCard.serviceSite),
        faqWithScore: faqCard.faqWithScore.map((faq) =>
          withFragment(faqWithScoreFragmentGql, faq),
        ),
      };
    }
    case 'FlowFeedbackMessage':
      return withFragment(flowFeedbackFragmentGql, fragment);
    case 'InterpolatedTextMessage':
      return withFragment(interpolatedTextFragmentGql, fragment);
    case 'LocationSearchInputMessage': {
      const locationSearchInput = withFragment(
        locationSearchInputFragmentGql,
        fragment,
      );
      return {
        ...locationSearchInput,
        action: withFragment(actionFragmentGql, locationSearchInput.action),
        locationDefaultFailureAction: withFragment(
          actionFragmentGql,
          locationSearchInput.locationDefaultFailureAction,
        ),
        locationPermissionFailureAction: withFragment(
          actionFragmentGql,
          locationSearchInput.locationPermissionFailureAction,
        ),
        locationTimeoutFailureAction: withFragment(
          actionFragmentGql,
          locationSearchInput.locationTimeoutFailureAction,
        ),
      };
    }
    case 'OutletsCardMessage': {
      const outletsCard = withFragment(outletsCardFragmentGql, fragment);
      return {
        ...outletsCard,
        outletsWithDistance: outletsCard.outletsWithDistance.map((outlet) =>
          withFragment(outletWithDistanceFragmentGql, outlet),
        ),
      };
    }
    case 'ProductCardMessage': {
      const productCard = withFragment(productCardFragmentGql, fragment);
      return {
        ...productCard,
        product: withFragment(productFragmentGql, productCard.product),
        availabilityAction: withFragment(
          actionFragmentGql,
          productCard.availabilityAction,
        ),
      };
    }
    case 'ProductSearchInputMessage': {
      const productSearchInput = withFragment(
        productSearchInputFragmentGql,
        fragment,
      );
      return {
        ...productSearchInput,
        action: withFragment(actionFragmentGql, productSearchInput.action),
      };
    }
    case 'QuickReplyMessage': {
      const quickReply = withFragment(quickReplyFragmentGql, fragment);
      return {
        ...quickReply,
        options: quickReply.options.map((option) => ({
          ...option,
          action: withFragment(actionFragmentGql, option.action),
        })),
      };
    }
    case 'SurveyButtonMessage':
      return withFragment(surveyButtonFragmentGql, fragment);
    case 'TextMessage':
      return withFragment(textFragmentGql, fragment);
  }
}

export type BotResponseWithFragments = Omit<
  ApiResponseFieldsFragment,
  'messages'
> &
  Record<keyof Pick<ApiResponseFieldsFragment, 'messages'>, MessageFields[]>;
