/** @jsxImportSource theme-ui */
import { AnimatePresence, motion } from 'framer-motion';
import { ForwardedRef, ReactNode, useContext, useMemo } from 'react';
import { Fragment } from 'react';
import { createPortal } from 'react-dom';

import { forwardRefWithDisplayName } from '../../hocs';
import ThemeContext from '../../theme/provider/ThemeContext';

export type ModalProps = {
  children: ReactNode;
  visible?: boolean;
};

const Modal = (
  { children, visible = false }: ModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { portal } = useContext(ThemeContext);

  const portalContent = useMemo(
    () =>
      createPortal(
        <AnimatePresence>
          {visible && (
            <motion.div
              ref={ref}
              sx={{
                position: 'absolute',
                zIndex: 9999,
                left: 0,
                top: 0,
                backgroundColor: 'white',
                height: '100%',
                width: '100%',
                boxShadow: 'primary',
              }}
              initial={{ opacity: 0, x: '-100%' }}
              animate={{
                opacity: 1,
                x: '0',
                transition: {
                  transitionDelay: 0.3,
                  ease: 'circOut',
                },
              }}
              exit={{
                opacity: 0,
                x: '-100%',
                transition: {
                  transitionDelay: 0.3,
                  ease: 'circIn',
                },
              }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>,
        portal,
      ),
    [visible, ref, children, portal],
  );

  return <Fragment>{portalContent}</Fragment>;
};

export default forwardRefWithDisplayName(Modal, 'Modal');
