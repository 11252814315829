import { createContext } from 'react';

import { UiTheme } from '../types';

export type ThemeContextProps = {
  portal: HTMLElement;
  theme: UiTheme;
};

const ThemeContext = createContext<ThemeContextProps>({
  portal: document.getElementById('root')!,
  theme: 'mm',
});

export default ThemeContext;
