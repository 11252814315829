import { createFeature, reducers } from '@cca/store';

import { getLocationSuggestions } from './getLocationSuggestions.state';
import { LocationSuggestion } from './types';

export interface LocationState {
  locationSuggestions: LocationSuggestion[];
}

export const initialLocationState: LocationState = {
  locationSuggestions: [],
};

export const { addThunks, locationReducer } = createFeature({
  name: 'location',
  initialState: initialLocationState,
  reducers: {
    ...reducers<LocationState>().createSetters(['locationSuggestions']),
  },
});
export const { locationActions } = addThunks({
  getLocationSuggestions,
});
