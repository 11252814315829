import { ForwardedRef, ReactNode } from 'react';
import { Box, Flex } from 'theme-ui';

import { Position } from '@cca/util';

import { forwardRefWithDisplayName } from '../../../hocs';
import { ArrowIcon } from '../../icons';

type Props = {
  children: ReactNode;
  showBackIcon?: ReactNode;
  onBackClick?: () => void;
};
const CardHeader = (
  { children, showBackIcon, onBackClick = () => {} }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Flex ref={ref} variant="layout.cardHeader">
      {showBackIcon && (
        <Flex
          sx={{
            cursor: 'pointer',
            padding: '0 24px',
            alignSelf: 'stretch',
            alignItems: 'center',
          }}
          onClick={() => onBackClick()}
        >
          <ArrowIcon orientation={Position.Left} />
        </Flex>
      )}
      <Box sx={{ padding: `16px 24px 16px ${showBackIcon ? '0' : '24px'}` }}>
        {children}
      </Box>
    </Flex>
  );
};

export default forwardRefWithDisplayName(CardHeader, 'CardHeader');
