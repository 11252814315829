import { DateTime } from 'luxon';

import { OpeningTimeEntry, isOpened, wasOpened } from '../../util';

function convertOpeningTimes(
  openingTimes: OpeningTimeEntry,
  timezone?: string,
): Record<keyof OpeningTimeEntry, DateTime> {
  return {
    openingTime: DateTime.fromFormat(openingTimes.openingTime, 'hh:mm', {
      zone: timezone,
    }),
    closingTime: DateTime.fromFormat(openingTimes.closingTime, 'hh:mm', {
      zone: timezone,
    }),
  };
}

export function isAvailable(
  openingTimes: OpeningTimeEntry,
  timezone?: string,
): boolean {
  const { openingTime, closingTime } = convertOpeningTimes(
    openingTimes,
    timezone,
  );
  return isOpened(DateTime.local(), openingTime, closingTime);
}

export function wasAvailable(
  dateTime: DateTime,
  openingTimes: OpeningTimeEntry,
  timezone?: string,
): boolean {
  const { openingTime, closingTime } = convertOpeningTimes(
    openingTimes,
    timezone,
  );
  return wasOpened(dateTime, openingTime, closingTime);
}
