import { ForwardedRef } from 'react';

import { forwardRefWithDisplayName } from '../../../../hocs';
import { HomeIcon } from '../../../icons';
import { OpeningTime, OpeningTimes } from '../../../opening-times';
import { StructuredEntry } from '../../../structured-content';

export type OpeningTimeEntryProps = {
  openingTimes: OpeningTime[];
  openingTimeExceptionMessage?: string;
};

type Props = OpeningTimeEntryProps;

const OpeningTimeEntry = (
  { openingTimes, openingTimeExceptionMessage }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => (
  <StructuredEntry ref={ref} icon={<HomeIcon color="grey3" />}>
    <OpeningTimes
      openingTimes={openingTimes}
      openingTimeExceptionMessage={openingTimeExceptionMessage}
    />
  </StructuredEntry>
);

export default forwardRefWithDisplayName(OpeningTimeEntry, 'OpeningTimeEntry');
