import { graphql } from '@cca/chatbot-graphql-types';

export const apiResponseFragmentsGql = graphql(/* GraphQL*/ `
  fragment ApiResponseFields on BotResponse {
    id
    sessionId
    messages {
      ...MessagesFields
    }
  }
`);
