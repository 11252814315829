import { ForwardedRef, ReactNode } from 'react';
import { Box, Container } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';

type Props = {
  children: ReactNode;
};
const Footer = ({ children }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <Container ref={ref} variant="layout.footer">
      <Box
        sx={{
          position: 'absolute',
          margin: '20px',
          left: '0',
          top: '0',
          right: '0',
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default forwardRefWithDisplayName(Footer, 'Footer');
