import { ForwardedRef, ReactNode } from 'react';
import { Container } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';

type Props = {
  children: ReactNode;
};
const Header = ({ children }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <Container ref={ref} variant="layout.header">
      {children}
    </Container>
  );
};

export default forwardRefWithDisplayName(Header, 'Header');
