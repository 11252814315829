import { graphql } from '@cca/chatbot-graphql-types';

const productSearchCardFragmentGql = graphql(/* GraphQL*/ `
  fragment ProductSearchInputMessageFields on ProductSearchInputMessage {
    id
    scope
    type
    placeholder
    action {
      ...ActionFields
    }
  }
`);

export default productSearchCardFragmentGql;
