import flow from 'lodash/flow';

import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { messageFragmentGql } from '@cca/chatbot-graphql-fragments';
import { withFragment, withMessage } from '@cca/chatbot-graphql-types';

import apolloClient from '../../../services/apolloClient';
import logger from '../../../services/logger';
import { inputActions } from '../input';

import { apiResponseFragmentsGql } from './fragments.gql';
import { messagesActions } from './index';
import { startConversationGql } from './queries.gql';

export const startConversation = createAsyncThunk(
  'messages/startConversation',
  async (_, { dispatch, signal }) => {
    try {
      dispatch(inputActions.setShowTypingIndicator(true));
      const result = await apolloClient.mutate({
        mutation: startConversationGql,
        context: {
          fetchOptions: {
            signal,
          },
        },
      });

      if (result.data) {
        const resolvedBotMessage = flow(
          (fragment) => withFragment(apiResponseFragmentsGql, fragment),
          (fragment) => ({
            ...fragment,
            messages: withFragment(messageFragmentGql, fragment.messages),
          }),
          (fragment) => ({
            ...fragment,
            messages: fragment.messages.map(withMessage),
          }),
        )(result.data.startConversation);

        dispatch(messagesActions.handleBotMessage(resolvedBotMessage));
        dispatch(messagesActions.setActiveResponseIds([resolvedBotMessage.id]));
      } else {
        const error = new ApolloError({ graphQLErrors: result.errors });
        logger.unknownError(error);
        dispatch(messagesActions.triggerErrorMessage());
      }
    } catch (error) {
      logger.unknownError(error);
      dispatch(messagesActions.triggerErrorMessage());
    } finally {
      dispatch(inputActions.setShowTypingIndicator(false));
    }
  },
);
