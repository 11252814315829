export type OnlineAvailabilityState = {
  status: 'AVAILABLE' | 'PARTIAL_AVAILABLE' | 'NOT_AVAILABLE';
  label: string;
};

export enum StatusColors {
  'AVAILABLE' = '#70c118',
  'PARTIAL_AVAILABLE' = '#ffa550',
  'NOT_AVAILABLE' = '#918e8c',
}
