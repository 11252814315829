import { ForwardedRef } from 'react';
import { ThemeUIStyleObject, Heading as UiHeading } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../hocs';
import { Text } from '../text';

type Props = { children: string; variant?: string; sx?: ThemeUIStyleObject };
const Heading = (
  { children, variant = 'heading', sx = {} }: Props,
  ref: ForwardedRef<HTMLHeadingElement>,
) => {
  return (
    <UiHeading ref={ref} variant={variant} sx={sx}>
      <Text>{children}</Text>
    </UiHeading>
  );
};

export default forwardRefWithDisplayName(Heading, 'Heading');
