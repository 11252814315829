import { createFeature } from '@cca/store';

import { submitUserAgent } from './submitUserAgent.state';

export interface UserAgentState {}

export const initialUserAgentState: UserAgentState = {};

export const { addThunks, userAgentReducer } = createFeature({
  name: 'userAgent',
  initialState: initialUserAgentState,
  reducers: {},
});

export const { userAgentActions } = addThunks({ submitUserAgent });
