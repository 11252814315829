import { graphql } from '@cca/chatbot-graphql-types';

const serviceSiteFragmentGql = graphql(/* GraphQL*/ `
  fragment ServiceSiteFields on ServiceSite {
    name
    image
    url
  }
`);

export default serviceSiteFragmentGql;
