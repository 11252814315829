import { ForwardedRef, useMemo, useState } from 'react';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';

import { Position } from '@cca/util';

import { forwardRefWithDisplayName } from '../../hocs';
import { DislikeIcon, LikeIcon } from '../icons';

import AnimatedFeedbackThumb from './AnimatedFeedbackThumb';
import { FeedbackState } from './types';

export type ThumbsFeedbackProps = {
  state?: FeedbackState;
  disabledAfterClick?: boolean;
  onPositive?: () => void;
  onNegative?: () => void;
  onAnimationComplete?: () => void;
  disable?: boolean;
  iconHeight?: number;
  feedbackContainerSx?: ThemeUIStyleObject;
  hidePositive?: boolean;
  hideNegative?: boolean;
};

type Props = ThumbsFeedbackProps;
const ThumbsFeedback = (
  {
    state,
    disabledAfterClick = false,
    onPositive,
    onNegative,
    onAnimationComplete,
    disable = false,
    iconHeight = 25,
    feedbackContainerSx,
    hidePositive = false,
    hideNegative = false,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const [disabled, setDisabled] = useState<boolean>(disable);
  const defaultColor = useMemo(
    () => (disabled ? 'grey2' : 'grey4'),
    [disabled],
  );

  return (
    <Flex ref={ref} sx={{ alignItems: 'center' }}>
      <Box
        sx={{
          ...(!disabled && { cursor: 'pointer' }),
          marginLeft: '12px',
          borderRadius: '4px',
          visibility: hidePositive && 'hidden',
          ...feedbackContainerSx,
        }}
      >
        <AnimatedFeedbackThumb
          rotate={Position.Right}
          disabledAfterClick={disabledAfterClick}
          onClick={onPositive}
          disabled={disabled}
          onDisable={() => setDisabled(true)}
          onClickAnimationComplete={onAnimationComplete}
        >
          <LikeIcon
            color={state === FeedbackState.POSITIVE ? 'green' : defaultColor}
            hoverColor={!disabled && 'green'}
            height={iconHeight}
          />
        </AnimatedFeedbackThumb>
      </Box>

      <Box
        sx={{
          ...(!disabled && { cursor: 'pointer' }),
          marginLeft: '4px',
          borderRadius: '4px',
          visibility: hideNegative && 'hidden',
          ...feedbackContainerSx,
        }}
      >
        <AnimatedFeedbackThumb
          rotate={Position.Left}
          disabledAfterClick={disabledAfterClick}
          onClick={onNegative}
          disabled={disabled}
          onDisable={() => setDisabled(true)}
          onClickAnimationComplete={onAnimationComplete}
        >
          <DislikeIcon
            color={state === FeedbackState.NEGATIVE ? 'red' : defaultColor}
            hoverColor={!disabled && 'red'}
            height={iconHeight}
          />
        </AnimatedFeedbackThumb>
      </Box>
    </Flex>
  );
};

export default forwardRefWithDisplayName(ThumbsFeedback, 'ThumbsFeedback');
