import { useMemo } from 'react';

import { Global, css } from '@emotion/react';

export type Font = {
  name: string;
  url: {
    woff: string;
    woff2: string;
  };
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  style?: 'normal';
  stretch?: 'normal';
};

export type GlobalFontsProps = {
  fonts: Font[];
};
const GlobalFonts = ({ fonts }: GlobalFontsProps) => {
  const styles = useMemo(
    () =>
      fonts.map(
        (font) => css`
          @font-face {
            font-family: '${font.name}';
            font-weight: ${font.weight ?? 400};
            font-style: ${font.style ?? 'normal'};
            font-stretch: ${font.stretch ?? 'normal'};
            src:
              url(${font.url.woff2}) format('woff2'),
              url(${font.url.woff}) format('woff');
          }
        `,
      ),
    [fonts],
  );

  return <Global styles={styles} />;
};

export default GlobalFonts;
