import { createAsyncThunk } from '@reduxjs/toolkit';

import { AddIntentFeedbackMutationVariables } from '@cca/chatbot-graphql-types';

import apolloClient from '../../../services/apolloClient';

import { addIntentFeedbackGql } from './queries.gql';

export const submitIntentFeedback = createAsyncThunk<
  void,
  AddIntentFeedbackMutationVariables
>('feedback/submitIntentFeedback', async ({ feedback }) => {
  await apolloClient.mutate({
    mutation: addIntentFeedbackGql,
    variables: {
      feedback,
    },
  });
});
