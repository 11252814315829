import { Badge as UiBadge } from 'theme-ui';

import { Text } from '../text';

export type BadgeProps = { children: string | string[] };
const Badge = ({ children }: BadgeProps) => {
  return (
    <UiBadge variant="primary">
      <Text>{Array.isArray(children) ? children.join(' ') : children}</Text>
    </UiBadge>
  );
};

export default Badge;
