import findLast from 'lodash/findLast';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useEventListener } from '@react-hookz/web';

import { isBot } from '@cca/ui';

import logger from '../../services/logger';
import { useAppDispatch, useAppStateSelector } from '../../store';
import { clickTrackingActions } from '../../store/actions/click-tracking';
import { messagesCombiners } from '../../store/actions/messages/combiners';

import ClickTrackingContext from './ClickTrackingContext';
import useClickTracking from './useClickTracking';

type Props = PropsWithChildren<{}>;
const ClickTrackingContainer = ({ children }: Props) => {
  const click = useClickTracking();
  const dispatch = useAppDispatch();
  const [rendered, setRendered] = useState<Date>(new Date());

  const history = useAppStateSelector(messagesCombiners.getMessageHistory);
  const currentSessionId = useAppStateSelector(
    (state) => state.messages.currentSessionId,
  );
  const lastMessage = useMemo(
    () => findLast(history, (message) => message.isBot),
    [history],
  );
  const responseId = useMemo(
    () => (isBot(lastMessage) ? lastMessage.dialogflowResponseId : '<invalid>'),
    [lastMessage],
  );

  const onClick = useCallback(
    (event: MouseEvent) => {
      click.track.botState({ responseId });
      click.track.position({
        x: event.clientX,
        y: event.clientY,
        viewPortWidth: window.innerWidth,
        viewPortHeight: window.innerHeight,
      });
      click.track.sessionId(currentSessionId);
      click.track.rendered(rendered);
      logger.info('Click tracked', click.track.getResult());
      dispatch(
        clickTrackingActions.submitClickTracking({
          clickTracking: click.track.getResult(),
        }),
      );
      click.submit();
    },
    [click, currentSessionId, dispatch, responseId, rendered],
  );

  useEventListener(document.body, 'click', onClick);

  useEffect(() => {
    setRendered(new Date());
  }, []);

  return useMemo(
    () => (
      <ClickTrackingContext.Provider
        value={{
          uiComponent: (props: Parameters<typeof click.track.uiComponent>[0]) =>
            click.track.uiComponent(props),
          context: (props: Parameters<typeof click.track.context>[0]) =>
            click.track.context(props),
          rendered: (props: Parameters<typeof click.track.rendered>[0]) =>
            click.track.rendered(props),
          action: (props: Parameters<typeof click.track.action>[0]) =>
            click.track.action(props),
        }}
      >
        {children}
      </ClickTrackingContext.Provider>
    ),
    // Since `useClickTracking` works on the basis of references and avoids react's update
    // mechanism due to performance reasons, we do not include `click` in the dependency list.
    // `click` triggers too many updates because `click.track` is reassigned after
    // each click.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children],
  );
};

export default ClickTrackingContainer;
