import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { BotAvatarKind, MessageFields } from '@cca/chatbot-graphql-types';
import { translate } from '@cca/i18n';
import { BotMessage } from '@cca/ui';

import { messagesActions } from './index';

function addErrorAvatar(dialogflowResponseId: string) {
  const id = uuid();
  const errorAvatar: BotMessage<MessageFields> = {
    id,
    animate: true,
    isBot: true,
    dialogflowResponseId,
    created: DateTime.local(),
    message: {
      __typename: 'BotAvatarMessage',
      id,
      scope: 'history',
      type: 'common/bot-avatar',
      kind: BotAvatarKind.error,
    },
  };
  return messagesActions.addMessage(errorAvatar);
}

function addErrorMessage(dialogflowResponseId: string) {
  const id = uuid();
  const errorMessage: BotMessage<MessageFields> = {
    id,
    animate: true,
    isBot: true,
    dialogflowResponseId,
    created: DateTime.local(),
    message: {
      __typename: 'TextMessage',
      id,
      scope: 'history',
      type: 'common/text',
      text: translate('error.general'),
      intent: null,
    },
  };
  return messagesActions.addMessage(errorMessage);
}

export const triggerErrorMessage = createAsyncThunk(
  'messages/triggerError',
  async (_, { dispatch }) => {
    const responseId = `error-${uuid()}`;

    dispatch(addErrorAvatar(responseId));
    dispatch(addErrorMessage(responseId));
    dispatch(messagesActions.setActiveResponseIds([responseId]));
  },
);
