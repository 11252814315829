import { DependencyList, EffectCallback } from 'react';

import { useCustomCompareEffect } from '@react-hookz/web';

/**
 * A `useEffect` version in which the handler is called only for each update of a
 * trigger dependency (element of `triggers`). The hook ensures that the handler
 * accesses always the latest values of each dependency.
 * `triggers` is a subset of `dependencies`.
 *
 * The hook is useful especially if a effect depends on functions (not created
 * by `useCallback` hook) which would trigger the effect handler too frequently.
 *
 * @example
 * const [dep] = useState('update');
 * function funcDep() {...}
 * useEffectWithTriggers(() => console.log(funcDep(dep)), [funcDep, dep], [dep])
 */
const useEffectWithTriggers = (
  handler: EffectCallback,
  dependencies: DependencyList,
  triggers: DependencyList,
) => {
  const triggerIndexes = dependencies
    .map<[unknown, number]>((dependency, dependencyIndex) => [
      dependency,
      dependencyIndex,
    ])
    .filter(
      ([dependency]) =>
        typeof triggers.find((trigger) => trigger === dependency) !==
        'undefined',
    )
    .map(([_, dependencyIndex]) => dependencyIndex);

  useCustomCompareEffect(
    handler,
    dependencies,
    (previousDependencies, nextDependencies) =>
      triggerIndexes
        .map((index) => previousDependencies[index] === nextDependencies[index])
        .reduce((result, value) => result || value, false),
  );
};

export default useEffectWithTriggers;
