import { Theme } from 'theme-ui';

export default {
  layout: {
    popover: {
      backgroundColor: 'grey5',
      padding: '10px 20px',
      borderRadius: ' 8px',
      fontSize: '12px',
      boxShadow:
        'rgba(0, 0, 0, 0.07) 0px 3px 4px 0px, rgba(0, 0, 0, 0.06) 0px 3px 3px -2px, rgba(0, 0, 0, 0.1) 0px 1px 8px 0px',
      color: 'white',
    },
  },
} as Theme;
