/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion';
import { useState } from 'react';

import * as colors from '../../../theme/mm/colors';

type Props = {
  color?: keyof typeof colors;
  hoverColor?: keyof typeof colors | false;
  height?: number;
};
const LikeIcon = ({
  color = 'iconPrimary',
  hoverColor = 'iconSecondary',
  height = 32,
}: Props) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const animate = {
    fill: colors[color],
    transition: { duration: 0 },
    ...(hoverColor && hovered && { fill: colors[hoverColor] }),
  };

  return (
    <motion.div
      animate={animate}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        viewBox="0 0 34.848 33.396"
      >
        <g transform="translate(0 0)">
          <g transform="translate(0 0)">
            <g transform="translate(0 0)">
              <path
                d="M34.848,30.632a3.226,3.226,0,0,0-.945-2.286,3.6,3.6,0,0,0,.929-2.772,3.753,3.753,0,0,0-3.787-3.292H22.076a20.877,20.877,0,0,0,1.156-5.808c0-3.149-2.676-5.808-4.356-5.808a4.56,4.56,0,0,0-2.631.884.726.726,0,0,0-.273.568v4.924L11.789,26.1l-.173.089v-.277a.726.726,0,0,0-.726-.726H3.63A3.633,3.633,0,0,0,0,28.817V40.433a3.633,3.633,0,0,0,3.63,3.63H7.986a3.638,3.638,0,0,0,3.417-2.4,9.465,9.465,0,0,0,3.844.948H28.574a3.34,3.34,0,0,0,3.29-2.535,3.278,3.278,0,0,0-.261-2.182,3.255,3.255,0,0,0,1.453-4.356A3.254,3.254,0,0,0,34.848,30.632Zm-3.062,1.792a.726.726,0,0,0-.415,1.25,1.8,1.8,0,0,1-1.035,3.106.726.726,0,0,0-.415,1.25,1.8,1.8,0,0,1,.526,1.732,1.879,1.879,0,0,1-1.872,1.4H15.246a6.883,6.883,0,0,1-3.842-1.24.727.727,0,0,0-1.24.514,2.181,2.181,0,0,1-2.178,2.178H3.63a2.181,2.181,0,0,1-2.178-2.178V28.817A2.181,2.181,0,0,1,3.63,26.639h6.534v.726a.729.729,0,0,0,.346.619.742.742,0,0,0,.706.03l1.452-.726A.725.725,0,0,0,13,26.944l4.356-9.438a.74.74,0,0,0,.067-.3V12.512a3.062,3.062,0,0,1,1.452-.393c.8,0,2.9,1.978,2.9,4.356a22.609,22.609,0,0,1-1.4,6.276.725.725,0,0,0,.678.984h9.991a2.292,2.292,0,0,1,2.341,1.973,2.169,2.169,0,0,1-1.034,2.055.727.727,0,0,0,.049,1.265,1.8,1.8,0,0,1-.616,3.4Z"
                transform="translate(0 -10.667)"
              />
            </g>
          </g>
          <g transform="translate(10.164 15.972)">
            <path
              d="M150.059,245.333a.726.726,0,0,0-.726.726v13.068a.726.726,0,1,0,1.452,0V246.059A.726.726,0,0,0,150.059,245.333Z"
              transform="translate(-149.333 -245.333)"
            />
          </g>
        </g>
      </svg>
    </motion.div>
  );
};

export default LikeIcon;
