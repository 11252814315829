import {
  ForwardRefRenderFunction,
  ReactNode,
  Ref,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import * as React from 'react';
import { Box, Flex, Input } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../hocs';

export type RelevantKeys = 'ArrowDown' | 'ArrowUp' | 'Enter' | 'Escape';

export interface TextInputRefObject {
  value: string;
  clear: () => void;
  blur: () => void;
  focus: () => void;
  addEventListener: HTMLElement['addEventListener'];
}

export type TextInputProps = {
  placeholder?: string;
  icon?: ReactNode;
  actionIcon?: ReactNode;
  clearOnSubmit?: boolean;
  onSubmit?: (submitValue: string) => void;
  onInput?: (input: string) => void;
  onBlur?: (input: string) => void;
  onFocus?: (input: string) => void;
  onKeyDown?: (
    key: RelevantKeys,
    input: string,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  disabled?: boolean;
};
const TextInput: ForwardRefRenderFunction<
  TextInputRefObject,
  TextInputProps
> = (
  {
    placeholder,
    icon,
    actionIcon,
    clearOnSubmit = false,
    onSubmit = () => {},
    onInput = () => {},
    onBlur = () => {},
    onFocus = () => {},
    onKeyDown = () => {},
    disabled = false,
  }: TextInputProps,
  ref: Ref<TextInputRefObject>,
) => {
  const boxRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');

  useImperativeHandle(ref, () => {
    return {
      value: inputValue,
      clear: () => setInputValue(''),
      blur: () => inputRef.current?.blur(),
      focus: () => inputRef.current?.focus(),
      addEventListener: (
        type: Parameters<TextInputRefObject['addEventListener']>[0],
        listener: Parameters<TextInputRefObject['addEventListener']>[1],
        capture: Parameters<TextInputRefObject['addEventListener']>[2],
      ) => {
        boxRef.current?.removeEventListener(type, listener, capture);
        return boxRef.current?.addEventListener(type, listener, capture);
      },
    };
  }, [inputValue]);

  const submitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (inputValue !== '') {
      onSubmit(inputValue);
      if (clearOnSubmit) {
        setInputValue('');
      }
    }
  };

  return (
    <Box
      ref={boxRef as unknown as Ref<HTMLDivElement>}
      as="form"
      sx={{ position: 'relative' }}
      onSubmit={submitHandler}
    >
      {icon && (
        <Flex
          sx={{
            position: 'absolute',
            left: '12px',
            top: '14px',
            cursor: 'pointer',
          }}
          onClick={() => inputRef.current?.focus()}
        >
          {icon}
        </Flex>
      )}
      <Input
        ref={inputRef}
        placeholder={!disabled ? placeholder : ''}
        sx={{
          ...(icon && { paddingLeft: '48px' }),
          ...(actionIcon && { paddingRight: '52px' }),
          ...(disabled && { backgroundColor: 'grey1', cursor: 'not-allowed' }),
        }}
        value={inputValue}
        onInput={(e) => onInput((e.target as HTMLInputElement).value)}
        onChange={(changeEvent) => setInputValue(changeEvent.target.value)}
        onBlur={(e) => onBlur((e.target as HTMLInputElement).value)}
        onFocus={(e) => onFocus((e.target as HTMLInputElement).value)}
        onKeyDown={(e) =>
          onKeyDown(
            e.key as RelevantKeys,
            (e.target as HTMLInputElement).value,
            e,
          )
        }
        disabled={disabled}
      />

      {actionIcon && inputValue && (
        <Flex
          sx={{
            position: 'absolute',
            right: '16px',
            top: '0',
            height: '50px',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => inputRef.current?.focus()}
        >
          {actionIcon}
        </Flex>
      )}
    </Box>
  );
};

export default forwardRefWithDisplayName(TextInput, 'TextInput');
