import { useScroll } from 'framer-motion';
import { ComponentType, useMemo, useRef } from 'react';

import {
  AnimatedBotAvatar,
  BotMessage,
  ChatHistory,
  Frame,
  HeaderSlot,
} from '@cca/ui';
import { Position } from '@cca/util';

import { useAppStateSelector } from '../../store';
import { messagesSelectors } from '../../store/actions/messages';
import { messagesCombiners } from '../../store/actions/messages/combiners';
import { MinimizeButton, NavigationButton } from '../buttons';
import ChatbotInput from '../chatbot-input/ChatbotInput';
import ClickTrackingMessageWrapper from '../click-tracking/ClickTrackingMessageWrapper';
import {
  TBotAvatar,
  TBotMessageEntry,
  TFooter,
  THeader,
  TMain,
  TUserMessageEntry,
} from '../click-tracking/components';

type Props = {};
// eslint-disable-next-line no-empty-pattern
const ChatbotFrame = ({}: Props) => {
  const messages = useAppStateSelector(messagesCombiners.getMessageHistory);

  const showTypingIndicator = useAppStateSelector(
    (state) => state.input.showTypingIndicator,
  );
  const activeResponseIds = useAppStateSelector(
    (state) => state.messages.activeResponseIds,
  );
  const firstMessageOfActiveResponse = useAppStateSelector(
    messagesSelectors.getFirstMessageOfActiveResponse,
  );
  const frameMainRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll({ container: frameMainRef });

  const componentLoaders = useMemo(
    () =>
      messages
        .filter((message): message is BotMessage<any> => message.isBot)
        .reduce<Record<string, () => Promise<{ default: ComponentType<any> }>>>(
          (result, message) => ({
            ...result,
            [message.message.type]: () =>
              import(`../messages/${message.message.type}`),
          }),
          {},
        ),
    [messages],
  );

  return (
    <Frame>
      <THeader>
        <HeaderSlot position={Position.Center}>
          <AnimatedBotAvatar scrollY={scrollY}>
            <TBotAvatar height={40} />
          </AnimatedBotAvatar>
        </HeaderSlot>
        {/*<HeaderSlot position={Position.Right}>*/}
        {/*  <MaximizeIcon />*/}
        {/*</HeaderSlot>*/}
        <HeaderSlot position={Position.Right}>
          <MinimizeButton />
        </HeaderSlot>
      </THeader>
      <TMain floatingSlot={<NavigationButton />} ref={frameMainRef}>
        <ChatHistory
          messages={messages}
          showTypingIndicator={showTypingIndicator}
          activeResponseIds={activeResponseIds}
          firstMessageOfActiveResponse={firstMessageOfActiveResponse}
          componentLoaders={componentLoaders}
          MessageWrapperComponent={ClickTrackingMessageWrapper}
          UserMessageEntryComponent={TUserMessageEntry}
          BotMessageEntryComponent={TBotMessageEntry}
        />
      </TMain>
      <TFooter>
        <ChatbotInput />
      </TFooter>
    </Frame>
  );
};

export default ChatbotFrame;
