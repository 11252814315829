import { useCallback, useState } from 'react';

export type Value<T> = {
  current: T;
};

type SetValue<T> = (updatedValue: T) => void;

/**
 * `useValue` more or less shares a common interface to `useState`.
 * However, updates of the value are not propagated to the React component.
 * This is useful for operations that should not have any implications for the
 * UI, i.e. for which no re-rendering is required.
 */
const useValue = <T,>(defaultValue: T): [Readonly<Value<T>>, SetValue<T>] => {
  const [value] = useState<{ current: T }>({ current: defaultValue });

  const setValue = useCallback<SetValue<T>>(
    (updatedValue: T) => {
      value.current = updatedValue;
    },
    [value],
  );

  return [value, setValue];
};

export default useValue;
