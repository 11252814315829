import { Theme } from 'theme-ui';

export default {
  layout: {
    productTileSalesPrice: {
      color: 'red',
    },
    productTileUnitPriceText: { color: 'blackHover' },
  },
} as Theme;
