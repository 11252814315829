import { Theme } from 'theme-ui';

export default {
  layout: {
    frame: {
      flexDirection: 'column',
      height: '100vh',
    },
    header: {
      display: 'flex',
      position: 'relative',
      flex: '0 0 auto',
      bg: 'grey5',
      boxShadow:
        'rgba(0, 0, 0, 0.07) 0px 8px 10px 1px, rgba(0, 0, 0, 0.06) 0px 3px 14px 2px, rgba(0, 0, 0, 0.1) 0px 5px 5px -3px;',
      zIndex: '1',
    },
    main: {
      // https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336
      flex: 'auto',
      overflow: 'hidden',
      padding: '120px 20px 0 20px',
      bg: 'grey1',
      overflowY: 'auto',
    },
    mainRegular: {
      // https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336
      flex: 'auto',
      overflow: 'hidden',
      padding: '20px',
      bg: 'grey1',
      overflowY: 'auto',
    },
    footer: {
      flex: '0 0 auto',
      bg: 'grey1',
      position: 'relative',
      height: '90px',
    },
  },
} as Theme;
