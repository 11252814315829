import { Theme } from 'theme-ui';

import * as colors from './colors';

const baseTheme: Theme = {
  config: {
    useCustomProperties: true,
    initialColorModeName: 'default',
    useColorSchemeMediaQuery: false,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: '"Source Sans Pro", sans-serif',
    fallback:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'MM Headline Pro WebTT, sans-serif',
    prominent: 'MM Text Pro WebTT, sans-serif',
    monospace: '"Source Code Pro", monospace',
    price: 'MediaMarktPreise',
  },
  fontSizes: [13, 15, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 400,
    normal: 400,
    semiBold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  radii: {
    borderRadius: 8,
  },
  colors,
  shadows: {
    primary: '0 3px 3px #00000029',
    secondary: '0 3px 6px #00000029',
  },
  layout: {},
  messages: {
    bubble: {
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 1,
      paddingRight: 1,
      listStyle: 'none',
      borderRadius: 8,
      width: 253,
      backgroundColor: 'rgba(0,0,0,0,)',
      boxShadow: '0 1px 3px #00000029',
      border: 'none',
    },
    in: {
      variant: 'messages.bubble',
      marginLeft: 4,
      alignSelf: 'flex-start',
      backgroundColor: '#FFFFFF',
    },
    out: {
      variant: 'messages.bubble',
      marginRight: 4,
      alignSelf: 'flex-end',
      backgroundColor: '#CDCACA',
    },
  },
  buttons: {
    suggestion: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'rgba(205, 202, 202, 1)',
      borderRadius: 8,
      color: 'rgba(0, 0, 0, 1)',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      outline: 'none',
      cursor: 'pointer',
      transition: '0.3s ease all',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 1)',
        borderColor: 'rgba(0, 0, 0, 1)',
        color: 'rgba(255,255,255,1)',
      },
    },
  },
  cards: {
    basic: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 8,
      maxWidth: 253,
      marginTop: 2,
      marginBottom: 2,
      backgroundColor: 'rgba(255,255,255,1)',
      boxShadow: '0 1px 3px #00000029',
      border: 'none',
      overflow: 'hidden',
    },
  },
  forms: {},
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 4,
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 3,
    },
    h4: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2,
    },
    h5: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1,
    },
    h6: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
};

export default baseTheme;
