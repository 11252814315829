import { ForwardedRef, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { ThemeUIStyleObject, Text as UIText } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../hocs';

import LinkParser, { LinkOptions, OnLinkClickHandler } from './LinkParser';
import MarkParser, { MarkOptions } from './MarkParser';
import ParagraphParser, { ParagraphOptions } from './ParagraphParser';

const ALLOWED_ELEMENTS = [
  'mark',
  'br',
  'a',
  'blockquote',
  'code',
  'em',
  'li',
  'ol',
  'p',
  'pre',
  'strong',
  'ul',
  'del',
];

// for more plugins => https://github.com/remarkjs/remark/blob/main/doc/plugins.md#list-of-plugins
export type TextProps = {
  children: string;
  // sx prop is not passed through if the parent comp has the jsx pragma
  styles?: ThemeUIStyleObject;
  onLinkClick?: OnLinkClickHandler;
};

type Props = TextProps;
const Text = (
  { children, styles = {}, onLinkClick = () => {} }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const customComponents = useMemo<ReactMarkdownOptions['components']>(
    () => ({
      a: (props: LinkOptions) => (
        <LinkParser {...props} onLinkClick={onLinkClick} />
      ),
      p: (props: ParagraphOptions) => <ParagraphParser {...props} />,
      mark: (props: MarkOptions) => <MarkParser {...props} />,
    }),
    [onLinkClick],
  );

  return (
    <UIText ref={ref} sx={styles} as="div">
      <ReactMarkdown
        components={customComponents}
        remarkPlugins={[gfm, breaks]}
        // Note: We need rehype here in order to render HTML-Tags (like <mark>)
        // as this is potentially dangerous we use rehypeSanitize together with allowedElements to only whitelist "trusted" tags
        // Additional note: We have to use the <mark> tag instead of a 'custom' markdown symbol ("==" would be quite common for highlighting)
        // because ReactMarkdown does not support custom symbols
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
        linkTarget="_blank"
        allowedElements={ALLOWED_ELEMENTS}
      >
        {children
          // we have to trim the input string as react-markdown can not handle these spaces
          .trim()
          // add the possibility to use \\ explicitly for line breaks within strings
          .replace(/\\\\/g, '\n')
          // add the possibility for multiple new lines after another
          // e.g. to make manual space between paragraphs possible
          .split('\n')
          .map((chunk) => (chunk === '' ? '\n &nbsp;' : chunk)) // nbsp hack => https://github.com/remarkjs/react-markdown/issues/278#issuecomment-628264062
          .join('\n')}
      </ReactMarkdown>
    </UIText>
  );
};

export default forwardRefWithDisplayName(Text, 'Text');
