// Better version of https://github.com/streamich/react-use/blob/master/src/useEnsuredForwardedRef.ts
// which returns a ref proxy to use within a component. All ref updates are
// forwarded outwards to the `ForwardedRef`.
// If you would like to process a ref within a component and forward the same ref one level up
// in the component hierarchy, then this is the right hook for you.
import { ForwardedRef, MutableRefObject, RefObject, useMemo } from 'react';

import { setForwardedRef } from '../ref';

const useForwardedRefProxy = <T = undefined>(
  ref: ForwardedRef<T>,
  initialValue: T | null,
): RefObject<T> => {
  const ensuredRef: MutableRefObject<T | null> = useMemo(
    () => ({ current: initialValue }),
    [initialValue],
  );

  return useMemo(
    () => ({
      set current(value: T | null) {
        ensuredRef.current = value;
        setForwardedRef(ref, value);
      },
      get current(): T | null {
        return ensuredRef.current;
      },
    }),
    [ensuredRef, ref],
  );
};

export default useForwardedRefProxy;
