/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion';
import { ComponentType, ForwardedRef, useMemo, useState } from 'react';
import { Flex } from 'theme-ui';

import { FeedbackValue } from '@cca/chatbot-graphql-types';

import { forwardRefWithDisplayName } from '../../hocs';
import { Button, ButtonProps } from '../button';
import { Text, TextProps } from '../text';
import Themed from '../utility/themed/Themed';

import ThumbsFeedback, { ThumbsFeedbackProps } from './ThumbsFeedback';
import { getFeedbackState } from './util';

const buttonWidth = '170px';
const transitionDelay = 0.15;

export type FlowFeedbackProps = {
  label: string;
  state?: FeedbackValue;
  showSurveyButtonAfterClick?: boolean;
  onSurveyTrigger?: () => void;
  onLike?: () => void;
  onDislike?: () => void;
  disabledAfterClick?: boolean;
  translate: (key: string) => string;
  disable?: boolean;
  surveyButtonDisabled?: boolean;
  ThumbsFeedbackComponent?: ComponentType<ThumbsFeedbackProps>;
  ButtonComponent?: ComponentType<ButtonProps>;
  TextComponent?: ComponentType<TextProps>;
};
const FlowFeedback = (
  {
    label,
    state,
    onLike = () => {},
    onDislike = () => {},
    disabledAfterClick = false,
    showSurveyButtonAfterClick = false,
    onSurveyTrigger,
    translate,
    disable = false,
    surveyButtonDisabled = false,
    ThumbsFeedbackComponent = ThumbsFeedback,
    ButtonComponent = Button,
    TextComponent = Text,
  }: FlowFeedbackProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const [feedbackAnimationComplete, setFeedbackAnimationComplete] =
    useState<boolean>(false);
  const initialHorizontalTransition = useMemo(() => {
    return showSurveyButtonAfterClick ? buttonWidth : '0';
  }, [showSurveyButtonAfterClick]);

  return (
    <motion.div
      ref={ref}
      initial={{ x: initialHorizontalTransition }}
      animate={{
        x:
          showSurveyButtonAfterClick && feedbackAnimationComplete
            ? '0'
            : initialHorizontalTransition,
        transition: {
          delay: transitionDelay,
        },
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Themed mmSx={{ fontSize: '14px' }} seSx={{ fontSize: '11px' }}>
          <TextComponent
            styles={{
              fontFamily: 'prominent',
              textAlign: 'right',
              color: 'grey4',
            }}
          >
            {label}
          </TextComponent>
        </Themed>
        <ThumbsFeedbackComponent
          state={state && getFeedbackState(state)}
          disabledAfterClick={disabledAfterClick}
          onPositive={onLike}
          onNegative={onDislike}
          onAnimationComplete={() => setFeedbackAnimationComplete(true)}
          disable={disable}
        />
        {showSurveyButtonAfterClick && (
          <motion.div
            sx={{ width: buttonWidth }}
            initial={{
              opacity: 0,
              marginLeft: 0,
            }}
            animate={{
              opacity: feedbackAnimationComplete ? 1 : 0,
              marginLeft: feedbackAnimationComplete ? 15 : 0,
              transition: {
                delay: transitionDelay,
              },
            }}
          >
            <ButtonComponent
              size="small"
              fullWidth
              outlined
              onClick={onSurveyTrigger}
              disabled={surveyButtonDisabled}
            >
              {translate('feedback.moreFeedback')}
            </ButtonComponent>
          </motion.div>
        )}
      </Flex>
    </motion.div>
  );
};

export default forwardRefWithDisplayName(FlowFeedback, 'FlowFeedback');
