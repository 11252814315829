import { useCallback } from 'react';

import { DialogflowActionTypes } from '@cca/chatbot-graphql-types';
import { useTranslation } from '@cca/i18n';

import { useAppDispatch, useAppStateSelector } from '../../store';
import { inputActions } from '../../store/actions/input';
import { messagesActions } from '../../store/actions/messages';
import { ClickTrackingActions } from '../click-tracking/actions';
import { TNavigationButton } from '../click-tracking/components';
import useClickTrackingContext from '../click-tracking/useClickTrackingContext';

type Props = {};
// eslint-disable-next-line no-empty-pattern
const NavigationButton = ({}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sessionId = useAppStateSelector(
    (state) => state.messages.currentSessionId,
  );
  const clickTrackingContext = useClickTrackingContext();

  const onClick = useCallback(() => {
    clickTrackingContext.action(ClickTrackingActions.NavigationShow());
    dispatch(
      messagesActions.executeBotAction({
        sessionId,
        action: {
          type: DialogflowActionTypes.event,
          value: 'events.navigation.show',
        },
      }),
    );
    dispatch(inputActions.focusInput());
  }, [clickTrackingContext, dispatch, sessionId]);

  return (
    <TNavigationButton onClick={onClick} popoverLabel={t('frame.navigation')} />
  );
};

export default NavigationButton;
