/** @jsxImportSource theme-ui */
import useThemeBase from '../../../theme/provider/useThemeBase';

type Props = { color?: string };
const PhoneIcon = ({ color = 'iconPrimary' }: Props) => {
  const theme = useThemeBase();

  const mmIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.761"
      height="19.547"
      viewBox="0 0 15.761 19.547"
    >
      <g transform="translate(-0.046 -0.241)">
        <path
          sx={{ fill: color }}
          d="M6.429,5.858a18.508,18.508,0,0,1-1.958.7c-.925.216-1.076.809-.794,1.568a14.25,14.25,0,0,0,4.343,6.119c.717.594,1.277.551,1.751-.341a17.082,17.082,0,0,1,1.185-1.7l4.807,3.646c-1.853,4.256-2.89,4.568-6.732,2.295C3.552,14.906.4,10.2.016,3.7-.1,1.716.4.989,2.261.465A10.517,10.517,0,0,0,3.508.128c.686-.29,1-.088,1.22.632.51,1.67,1.1,3.315,1.7,5.1"
          transform="translate(0.046 0.241)"
        />
      </g>
    </svg>
  );
  const seIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.761"
      height="15.761"
      viewBox="0 0 37 37"
    >
      <g transform="matrix(1, 0, 0, 1, -6.0002, -4.9996)">
        <path
          sx={{ fill: color }}
          d="M32.9102,41.9376 C30.2662,41.9376 27.6622,41.2816 25.3342,40.0296 L22.4782,38.4956 C16.9482,35.5216 12.4142,30.9896 9.4422,25.4616 L7.9082,22.6076 C6.6562,20.2776 6.0002,17.6756 6.0002,15.0296 L6.0002,10.9416 L11.9462,4.9996 L18.1882,15.7256 L12.7882,21.1256 L14.4162,24.1536 C16.5522,28.1296 19.8122,31.3876 23.7862,33.5216 L26.8162,35.1516 L32.2102,29.7576 L42.9382,35.9996 L37.0002,41.9376 L37.0002,41.9376 L32.9102,41.9376 Z"
        />
      </g>
    </svg>
  );

  return theme === 'mm' ? mmIcon : seIcon;
};

export default PhoneIcon;
