import { Font } from '../global-fonts/GlobalFonts';
import MMHeadlineProWebTTRegularWoff from '../global-fonts/fonts/MMHeadlineProWebTT-Regular.woff';
import MMHeadlineProWebTTRegularWoff2 from '../global-fonts/fonts/MMHeadlineProWebTT-Regular.woff2';
import MMTextProWebTTBoldWoff from '../global-fonts/fonts/MMTextProWebTT-Bold_subset.woff';
import MMTextProWebTTBoldWoff2 from '../global-fonts/fonts/MMTextProWebTT-Bold_subset.woff2';
import MMTextProWebTTRegularWoff from '../global-fonts/fonts/MMTextProWebTT-Regular_subset.woff';
import MMTextProWebTTRegularWoff2 from '../global-fonts/fonts/MMTextProWebTT-Regular_subset.woff2';
import MMTextProWebTTSemiBoldWoff from '../global-fonts/fonts/MMTextProWebTT-Semibold_subset.woff';
import MMTextProWebTTSemiBoldWoff2 from '../global-fonts/fonts/MMTextProWebTT-Semibold_subset.woff2';
import MMPricesWoff from '../global-fonts/fonts/MediaMarktPreise.woff';
import MMPricesWoff2 from '../global-fonts/fonts/MediaMarktPreise.woff2';
import SourceSansProLatin400Woff from '../global-fonts/fonts/source-sans-pro-latin-400.woff';
import SourceSansProLatin400Woff2 from '../global-fonts/fonts/source-sans-pro-latin-400.woff2';
import SourceSansProLatin600Woff from '../global-fonts/fonts/source-sans-pro-latin-600.woff';
import SourceSansProLatin600Woff2 from '../global-fonts/fonts/source-sans-pro-latin-600.woff2';
import SourceSansProLatin700Woff from '../global-fonts/fonts/source-sans-pro-latin-700.woff';
import SourceSansProLatin700Woff2 from '../global-fonts/fonts/source-sans-pro-latin-700.woff2';

const fonts: Font[] = [
  {
    name: 'MM Text Pro WebTT',
    url: {
      woff: MMTextProWebTTRegularWoff,
      woff2: MMTextProWebTTRegularWoff2,
    },
    weight: 400,
  },
  {
    name: 'MM Text Pro WebTT',
    url: {
      woff: MMTextProWebTTSemiBoldWoff,
      woff2: MMTextProWebTTSemiBoldWoff2,
    },
    weight: 600,
  },
  {
    name: 'MM Text Pro WebTT',
    url: {
      woff: MMTextProWebTTBoldWoff,
      woff2: MMTextProWebTTBoldWoff2,
    },
    weight: 700,
  },
  {
    name: 'MediaMarktPreise',
    url: {
      woff: MMPricesWoff,
      woff2: MMPricesWoff2,
    },
    weight: 400,
  },
  {
    name: 'MM Headline Pro WebTT',
    url: {
      woff: MMHeadlineProWebTTRegularWoff,
      woff2: MMHeadlineProWebTTRegularWoff2,
    },
    weight: 400,
  },
  {
    name: 'Source Sans Pro',
    url: {
      woff: SourceSansProLatin400Woff,
      woff2: SourceSansProLatin400Woff2,
    },
    weight: 400,
  },
  {
    name: 'Source Sans Pro',
    url: {
      woff: SourceSansProLatin600Woff,
      woff2: SourceSansProLatin600Woff2,
    },
    weight: 600,
  },
  {
    name: 'Source Sans Pro',
    url: {
      woff: SourceSansProLatin700Woff,
      woff2: SourceSansProLatin700Woff2,
    },
    weight: 700,
  },
];

export default fonts;
