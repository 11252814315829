import { ForwardedRef, ReactNode, RefAttributes, useMemo } from 'react';
import { Button as UIButton } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../hocs';
import useThemeBase from '../../theme/provider/useThemeBase';

import { mmStyles } from './Button.theme.mm';
import { seStyles } from './Button.theme.se';

export type ButtonProps = {
  onClick?: () => void;
  round?: boolean;
  squared?: boolean;
  fullWidth?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  size?: 'small' | 'normal';
  color?: 'primary' | 'secondary';
  inverted?: boolean;
  children?: ReactNode;
} & RefAttributes<HTMLButtonElement>;

const Button = (
  {
    children,
    round = false,
    squared = false,
    fullWidth = false,
    outlined = false,
    disabled = false,
    size = 'normal',
    color = 'primary',
    inverted = false,
    onClick = () => {},
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const theme = useThemeBase();

  const styles = useMemo(() => (theme === 'mm' ? mmStyles : seStyles), [theme]);

  return (
    <UIButton
      ref={ref}
      onClick={!disabled ? onClick : () => {}}
      sx={Object.assign(
        {},
        round && styles.round,
        squared && styles.squared,
        fullWidth && styles.fullWidth,
        outlined && styles.outlined,
        size === 'small' && styles.small,
        color === 'secondary' && !inverted && styles.secondary,
        color === 'secondary' && inverted && styles.invertedSecondary,
        color === 'primary' && inverted && styles.invertedPrimary,
        disabled && styles.disabled,
        disabled &&
          color === 'secondary' &&
          !outlined &&
          !inverted &&
          styles.disabledSecondary,
        disabled &&
          color === 'primary' &&
          !outlined &&
          !inverted &&
          styles.disabledPrimary,
      )}
    >
      {children}
    </UIButton>
  );
};

export default forwardRefWithDisplayName(Button, 'Button');
