import { ComponentType, ForwardedRef } from 'react';

import { Position } from '@cca/util';

import { forwardRefWithDisplayName } from '../../../hocs';
import { UserMessage as IUserMessage } from '../types';
import { UserMessageInputWithProps } from '../types';

import ChatHistoryEntry from './ChatHistoryEntry';
import UserMessageEntryMessage from './UserMessageEntryMessage';

export type UserMessageEntryProps = {
  message: IUserMessage;
  MessageComponent?: ComponentType<UserMessageInputWithProps>;
};

type Props = UserMessageEntryProps;
const UserMessageEntry = (
  { message, MessageComponent = UserMessageEntryMessage }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <ChatHistoryEntry
      ref={ref}
      orientation={Position.Right}
      animate={message.animate}
      messageId={message.id}
    >
      <MessageComponent orientation={Position.Right} value={message.value} />
    </ChatHistoryEntry>
  );
};

export default forwardRefWithDisplayName(UserMessageEntry, 'UserMessageEntry');
