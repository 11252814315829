import { graphql } from '@cca/chatbot-graphql-types';

const cardFragmentGql = graphql(/* GraphQL*/ `
  fragment CardMessageFields on CardMessage {
    id
    scope
    type
    #   TODO: the problem here is that there are many message types which have heading. on gql union types the types
    #   TODO: must match if we do not want to define prop aliases
    cardHeading: heading
    cardSubheading: subheading
    list {
      action {
        ...ActionFields
      }
      label
      description
      url
    }
    preventDisable
  }
`);

export default cardFragmentGql;
