import uniq from 'lodash/uniq';

export function interpolate(
  input: string,
  replacements: { [key: string]: string | number | boolean | undefined },
): string {
  const matches = uniq(
    Array.from(input.matchAll(/{{(?<key>[a-zA-Z.]+)}}/g))
      .map((match) => match.groups?.key)
      .filter((match): match is string => !!match),
  );

  return matches.reduce(
    (previousValue, match) =>
      previousValue.replace(
        new RegExp(`{{${match}}}`, 'g'),
        replacements[match]?.toString() ?? '',
      ),
    input,
  );
}
