import { graphql } from '@cca/chatbot-graphql-types';

export const addFeedbackSurveyGql = graphql(/* GraphQL */ `
  mutation AddFeedbackSurvey($survey: FeedbackSurveyInput!) {
    survey(survey: $survey) {
      created
      sessionId
    }
  }
`);
