import { Theme } from 'theme-ui';

export default {
  layout: {
    serviceSiteListItem: {
      margin: 0,
      listStyle: 'none',
      '&:nth-of-type(1)': {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        '>img': {
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        },
      },
    },
  },
} as Theme;
