import { graphql } from '@cca/chatbot-graphql-types';

const locationSearchCardFragmentGql = graphql(/* GraphQL*/ `
  fragment LocationSearchInputMessageFields on LocationSearchInputMessage {
    id
    scope
    type
    action {
      ...ActionFields
    }
    locationDefaultFailureAction {
      ...ActionFields
    }
    locationPermissionFailureAction {
      ...ActionFields
    }
    locationTimeoutFailureAction {
      ...ActionFields
    }
    placeholder
    enableLocationLabel
  }
`);

export default locationSearchCardFragmentGql;
