import { ForwardedRef } from 'react';

import { forwardRefWithDisplayName } from '../../../../hocs';
import { PhoneIcon } from '../../../icons';
import { StructuredEntry } from '../../../structured-content';

export type PhoneEntryProps = {
  phoneNumber: string;
};

type Props = PhoneEntryProps;

const PhoneEntry = (
  { phoneNumber }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <StructuredEntry ref={ref} icon={<PhoneIcon color="grey3" />}>
      {phoneNumber}
    </StructuredEntry>
  );
};

export default forwardRefWithDisplayName(PhoneEntry, 'PhoneEntry');
