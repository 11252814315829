import { reverse } from 'ramda';

import logger from '../../services/logger';

import { ClickTrackingContextOptions } from './ClickTrackingContext';
import { ClickTracking } from './types';

function contains<Prop extends keyof ClickTracking>(
  result: Partial<ClickTracking>,
  properties: Prop[],
): result is Partial<ClickTracking> &
  Pick<ClickTracking, (typeof properties)[number]> {
  return properties
    .map((property) => property in result)
    .reduce((containsResult, inResult) => containsResult && inResult);
}

function createClickTracking(): ClickTrackingContextOptions & {
  getResult: () => ClickTracking;
} {
  const uiComponents: string[] = [];
  const result: Partial<ClickTracking> = {};

  const getResult = (): ClickTracking => {
    if (
      contains(result, ['position', 'botState', 'rendered', 'sessionId']) &&
      uiComponents.length > 0
    ) {
      return { ...result, uiComponent: reverse(uiComponents).join('/') };
    }
    logger.error('ClickTracking: Properties missing', result);
    throw new Error('ClickTracking: Properties missing');
  };

  const uiComponent: ClickTrackingContextOptions['uiComponent'] = (name) => {
    uiComponents.push(name);
  };

  const position: ClickTrackingContextOptions['position'] = (props) => {
    result.position = props;
  };

  const botState: ClickTrackingContextOptions['botState'] = (props) => {
    result.botState = props;
  };

  const context: ClickTrackingContextOptions['context'] = (props) => {
    result.context = props;
  };

  const rendered: ClickTrackingContextOptions['rendered'] = (props) => {
    // In order ensure that each clicked element has a rendered date,
    // we assign one within the `ClickTrackingContainer`. For messages, we explicitly track
    // the actual rendered date. In this case, we prevent the reassignment of the created date
    // since `ClickTrackingContainer` would override it.
    result.rendered = result.rendered ?? props;
  };

  const sessionId: ClickTrackingContextOptions['sessionId'] = (props) => {
    result.sessionId = props;
  };

  const action: ClickTrackingContextOptions['action'] = (props) => {
    result.action = props;
  };

  return {
    getResult,
    uiComponent,
    position,
    botState,
    context,
    rendered,
    sessionId,
    action,
  };
}

const ClickTrackingApi = {
  submit: () => {
    return (ClickTrackingApi.track = createClickTracking());
  },
  track: createClickTracking(),
};

const useClickTracking = () => {
  return ClickTrackingApi;
};

export default useClickTracking;
