import { ForwardedRef, ReactNode } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';

type Props = {
  children: ReactNode;
  styles?: ThemeUIStyleObject;
};
const CardBody = (
  { children, styles = {} }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Box ref={ref} variant="layout.cardBody" sx={styles}>
      {children}
    </Box>
  );
};

export default forwardRefWithDisplayName(CardBody, 'CardBody');
