import { flow } from 'lodash';

import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { messageFragmentGql } from '@cca/chatbot-graphql-fragments';
import { withFragment, withMessage } from '@cca/chatbot-graphql-types';

import apolloClient from '../../../services/apolloClient';
import logger from '../../../services/logger';
import { inputActions } from '../input';

import { apiResponseFragmentsGql } from './fragments.gql';
import { messagesActions } from './index';
import { sendMessageGql } from './queries.gql';

export const sendMessage = createAsyncThunk<
  void,
  {
    sessionId: string;
    message: string;
  }
>('messages/send', async ({ sessionId, message }, { dispatch }) => {
  try {
    dispatch(inputActions.setShowTypingIndicator(true));
    dispatch(messagesActions.setActiveResponseIds([]));

    const result = await apolloClient.mutate({
      mutation: sendMessageGql,
      variables: {
        sessionId,
        message,
      },
    });

    if (result.data) {
      const resolvedBotMessage = flow(
        (fragment) => withFragment(apiResponseFragmentsGql, fragment),
        (fragment) => ({
          ...fragment,
          messages: withFragment(messageFragmentGql, fragment.messages),
        }),
        (fragment) => ({
          ...fragment,
          messages: fragment.messages.map(withMessage),
        }),
      )(result.data.sendMessage);

      /**
       * Note: We set resetInput to true here in order to ensure that the input field matches the current flow.
       * This is necessary because the input field does not change it's mode
       * when the flow was switched by a text (instead of an action like clicking a button) directly.
       */
      dispatch(
        messagesActions.handleBotMessage({
          ...resolvedBotMessage,
          resetInput: true,
        }),
      );
      dispatch(messagesActions.setActiveResponseIds([resolvedBotMessage.id]));
    } else {
      const error = new ApolloError({ graphQLErrors: result.errors });
      logger.unknownError(error);
      dispatch(messagesActions.triggerErrorMessage());
    }
  } catch (error) {
    logger.unknownError(error);
    dispatch(messagesActions.triggerErrorMessage());
  } finally {
    dispatch(inputActions.setShowTypingIndicator(false));
  }
});
