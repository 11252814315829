import { ForwardedRef, MutableRefObject, RefCallback } from 'react';

export function isMutableRefObject<T>(
  ref: ForwardedRef<T>,
): ref is MutableRefObject<T> {
  return (
    ref !== null &&
    typeof ref === 'object' &&
    'current' in (ref as MutableRefObject<T>)
  );
}

export function isRefCallback<T>(ref: ForwardedRef<T>): ref is RefCallback<T> {
  return typeof ref === 'function';
}

export function setForwardedRef<T>(
  ref: ForwardedRef<T>,
  value: T | null,
): void {
  if (isMutableRefObject<T | null>(ref)) {
    ref.current = value;
  } else if (isRefCallback<T | null>(ref)) {
    ref(value);
  }
}
