import { ForwardedRef } from 'react';
import { Heading as UiHeading } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../hocs';
import { Text } from '../text';

type Props = { children: string };
const SubHeading = (
  { children }: Props,
  ref: ForwardedRef<HTMLHeadingElement>,
) => {
  return (
    <UiHeading ref={ref} variant="subHeading" as="h3">
      <Text>{children}</Text>
    </UiHeading>
  );
};

export default forwardRefWithDisplayName(SubHeading, 'SubHeading');
