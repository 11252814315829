import { ForwardedRef } from 'react';
import { Flex } from 'theme-ui';

import { Position } from '@cca/util';

import { forwardRefWithDisplayName } from '../../../hocs';
import useThemeBase from '../../../theme/provider/useThemeBase';
import { ArrowIcon } from '../../icons';

type Props = {
  onClick?: () => void;
};
const BackButton = (
  { onClick = () => {} }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const theme = useThemeBase();

  return (
    <Flex
      ref={ref}
      sx={{ cursor: 'pointer', height: '21.305px', alignItems: 'flex-end' }}
      onClick={() => onClick()}
    >
      <ArrowIcon
        {...(theme === 'se' && { color: 'white' })}
        orientation={Position.Left}
      />
    </Flex>
  );
};

export default forwardRefWithDisplayName(BackButton, 'BackButton');
