import { ForwardedRef } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';

type Props = {
  sx?: ThemeUIStyleObject;
};
const CardDivider = ({ sx }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <Box
      ref={ref}
      sx={{
        height: '1px',
        backgroundColor: 'grey2',
        ...sx,
      }}
    />
  );
};

export default forwardRefWithDisplayName(CardDivider, 'CardDivider');
