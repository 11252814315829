import { AnimationControls } from 'framer-motion';
import { RefObject } from 'react';

import { Value } from '@cca/util-react';

const slideHeight = (ref: RefObject<HTMLDivElement>) => {
  return () => {
    if (!ref.current) {
      return 0;
    }
    return Array.from(ref.current.childNodes)
      .filter((child) => child.nodeType === Node.ELEMENT_NODE)
      .map((child) => (child as HTMLElement).offsetHeight)
      .reduce((sum, height) => sum + height, 0);
  };
};

const showSlide = (
  animation: AnimationControls,
  isHighestSlide: Value<boolean>,
) => {
  return (immediately?: boolean) => {
    animation.start('visible', immediately ? { duration: 0 } : undefined);
    if (isHighestSlide.current) {
      animation.start('grown', immediately ? { duration: 0 } : undefined);
    }
  };
};

const hideSlide = (
  animation: AnimationControls,
  isHighestSlide: Value<boolean>,
) => {
  return (immediately?: boolean) => {
    animation.start('hidden', immediately ? { duration: 0 } : undefined);
    if (isHighestSlide.current) {
      animation.start('shrunk', immediately ? { duration: 0 } : undefined);
    }
  };
};

const slideHeightChanged = (ref: RefObject<HTMLDivElement>) => {
  return (callback: () => void) => {
    // Since images mostly load after the images themselves are initialized in the DOM,
    // we have to send further "ready" events after the slide component is already rendered
    // in order to determine the right slide height.
    ref.current
      ?.querySelectorAll('img')
      .forEach((image) =>
        image.addEventListener('load', () => callback(), { once: true }),
      );
  };
};

export { slideHeight, showSlide, hideSlide, slideHeightChanged };
