import {
  ComponentType,
  ForwardedRef,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Box } from 'theme-ui';

import { ProductFieldsFragment } from '@cca/chatbot-graphql-types';
import { setForwardedRef } from '@cca/util-react';

import { forwardRefWithDisplayName } from '../../../hocs';
import { Button, ButtonProps } from '../../button';
import { Card, CardBody, CardDivider } from '../../card';
import { Text, TextProps } from '../../text';
import ProductAvailability, {
  ProductAvailabilityProps,
} from '../product-availability/ProductAvailability';
import ProductEfficiency, {
  ProductEfficiencyProps,
} from '../product-efficiency/ProductEfficiency';
import ExpandButton, {
  ExpandButtonProps,
} from '../product-expand/ExpandButton';
import ProductExpand, {
  ProductExpandProps,
} from '../product-expand/ProductExpand';
import ProductImage, { ProductImageProps } from '../product-image/ProductImage';
import ProductPrice, { ProductPriceProps } from '../product-price/ProductPrice';
import ProductRating, {
  ProductRatingProps,
} from '../product-rating/ProductRating';

export type ProductTileProps = {
  product: ProductFieldsFragment;
  locale: string;
  availableLabel: string;
  soonAvailableLabel: string;
  preorderLabel: string;
  notAvailableLabel: string;
  checkAvailabilityLabel: string;
  purchaseLabel: string;
  hideAvailabilityButton?: boolean;
  onCheckAvailability?: (product: ProductFieldsFragment) => void;
  onInitiatePurchase?: (product: ProductFieldsFragment) => void;
  onClick?: () => void;
  disabled?: boolean;
  ProductAvailabilityComponent?: ComponentType<ProductAvailabilityProps>;
  ProductEfficiencyComponent?: ComponentType<ProductEfficiencyProps>;
  ProductImageComponent?: ComponentType<ProductImageProps>;
  ProductRatingComponent?: ComponentType<ProductRatingProps>;
  ProductPriceComponent?: ComponentType<ProductPriceProps>;
  ProductExpandComponent?: ComponentType<ProductExpandProps>;
  TextComponent?: ComponentType<TextProps>;
  ButtonComponent?: ComponentType<ButtonProps>;
  ExpandButtonComponent?: ComponentType<ExpandButtonProps>;
} & Pick<ProductPriceProps, 'strikePriceCrossedOut' | 'strikePricePrefix'>;
const ProductTile = (
  {
    product,
    locale,
    availableLabel,
    soonAvailableLabel,
    preorderLabel,
    notAvailableLabel,
    checkAvailabilityLabel,
    purchaseLabel,
    hideAvailabilityButton = false,
    onCheckAvailability = () => {},
    onInitiatePurchase = () => {},
    onClick = () => {},
    disabled = false,
    strikePriceCrossedOut,
    strikePricePrefix,
    ProductAvailabilityComponent = ProductAvailability,
    ProductEfficiencyComponent = ProductEfficiency,
    ProductImageComponent = ProductImage,
    ProductRatingComponent = ProductRating,
    ProductPriceComponent = ProductPrice,
    ProductExpandComponent = ProductExpand,
    TextComponent = Text,
    ButtonComponent = Button,
    ExpandButtonComponent = ExpandButton,
  }: ProductTileProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const productExpandRef = useRef<HTMLDivElement>(null);
  const checkAvailabilityButtonRef = useRef<HTMLButtonElement>(null);
  const initiatePurchaseButtonRef = useRef<HTMLButtonElement>(null);

  const cardRefLoaded = useCallback(
    (current: HTMLDivElement | null) => {
      setForwardedRef(ref, current);
      current?.addEventListener('click', (event) => {
        if (
          event.target instanceof HTMLElement &&
          !productExpandRef.current?.contains(event.target) &&
          !checkAvailabilityButtonRef.current?.contains(event.target) &&
          !initiatePurchaseButtonRef.current?.contains(event.target)
        ) {
          onClick();
        }
      });
    },
    // TODO: onClick as dependency triggers too many updates (maybe onClick handlers are not memoized?)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref],
  );

  const productEfficiencyGrade = useMemo(() => {
    if (product.energyEfficiencyClassEu2017_1369) {
      return { gradeEu2017_1369: product.energyEfficiencyClassEu2017_1369 };
    } else if (product.energyEfficiencyClass) {
      return { grade: product.energyEfficiencyClass };
    }
  }, [product.energyEfficiencyClass, product.energyEfficiencyClassEu2017_1369]);

  return (
    <Card ref={cardRefLoaded} clickable>
      <CardBody styles={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
        >
          <ProductAvailabilityComponent
            onlineAvailability={product.onlineAvailability}
            availableLabel={availableLabel}
            notAvailableLabel={notAvailableLabel}
            soonAvailableLabel={soonAvailableLabel}
            preorderLabel={preorderLabel}
          />
          {productEfficiencyGrade && (
            <ProductEfficiencyComponent {...productEfficiencyGrade} />
          )}
        </Box>
        <ProductImageComponent url={product.imageUrl} alt={product.name} />
        <ProductRatingComponent
          averageRating={product.rating?.averageRating}
          ratingsCount={product.rating?.ratingsCount}
        />
        <Box sx={{ fontSize: 18, marginBottom: 20 }}>
          <TextComponent>{product.name}</TextComponent>
        </Box>
        <ProductPriceComponent
          product={product}
          strikePricePrefix={strikePricePrefix}
          strikePriceCrossedOut={strikePriceCrossedOut}
          locale={locale}
        />
        <CardDivider sx={{ margin: '16px -16px 16px -16px' }} />
        {!hideAvailabilityButton && (
          <Box>
            <ButtonComponent
              ref={checkAvailabilityButtonRef}
              color="secondary"
              inverted
              fullWidth
              onClick={() => onCheckAvailability(product)}
              disabled={disabled}
            >
              <Text>{checkAvailabilityLabel}</Text>
            </ButtonComponent>
          </Box>
        )}
        <Box sx={{ ...(!hideAvailabilityButton && { paddingTop: '10px' }) }}>
          <ButtonComponent
            ref={initiatePurchaseButtonRef}
            color="secondary"
            fullWidth
            onClick={() => onInitiatePurchase(product)}
          >
            <Text>{purchaseLabel}</Text>
          </ButtonComponent>
        </Box>
        {product.mainFeatures.length > 0 && (
          <>
            <CardDivider sx={{ margin: '16px -16px 0 -16px' }} />
            <ProductExpandComponent
              ref={productExpandRef}
              features={product.mainFeatures}
              ExpandButtonComponent={ExpandButtonComponent}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default forwardRefWithDisplayName(ProductTile, 'ProductTile');
