import environment from 'environment';
import { Settings } from 'luxon';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { ApolloProvider } from '@apollo/client';

import { I18nLoader } from '@cca/i18n';
import { LoadingOverlay, ThemeProvider } from '@cca/ui';

import App from './App';
import ClickTrackingContainer from './components/click-tracking/ClickTrackingContainer';
import './index.css';
import './polyfills';
import reportWebVitals from './reportWebVitals';
import apolloClient from './services/apolloClient';
import { store } from './store';

Settings.defaultLocale = environment.locale;

const portal = document.getElementById('portal')!;
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ThemeProvider portal={portal} theme={environment.theme}>
          <Suspense fallback={<LoadingOverlay visible />}>
            <I18nLoader
              apolloClient={apolloClient}
              language={environment.locale}
              debug={environment.isDevelopment || environment.isQa}
            >
              <ClickTrackingContainer>
                <App />
              </ClickTrackingContainer>
            </I18nLoader>
          </Suspense>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
