import { ForwardedRef } from 'react';

import { forwardRefWithDisplayName } from '../../../hocs';
import useThemeBase from '../../../theme/provider/useThemeBase';
import { MenuIcon } from '../../icons';
import { Popover } from '../../popover';
import Button from '../Button';

export type NavigationButtonProps = {
  onClick?: () => void;
  popoverLabel: string;
};
const NavigationButton = (
  { onClick = () => {}, popoverLabel }: NavigationButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const theme = useThemeBase();

  return (
    <Popover text={popoverLabel}>
      <Button ref={ref} round={theme === 'mm'} squared onClick={onClick}>
        <MenuIcon color="white" size="sm" />
      </Button>
    </Popover>
  );
};

export default forwardRefWithDisplayName(NavigationButton, 'NavigationButton');
