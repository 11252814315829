import { graphql } from '@cca/chatbot-graphql-types';

const textFragmentGql = graphql(/* GraphQL*/ `
  fragment TextMessageFields on TextMessage {
    id
    scope
    type
    text
    intent {
      id
      displayName
    }
  }
`);

export default textFragmentGql;
