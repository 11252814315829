import { XOR } from 'ts-essentials';

import { Channel } from '@cca/types';

import {
  Language,
  OnlineOutletId,
  Profile,
  ProfileColor,
  SalesLine,
  Subsidiary,
} from './common';

export interface ChannelConfig {
  subsidiary: Subsidiary;
  salesLine: SalesLine;
  language: Language;
  onlineOutletId: OnlineOutletId;
  includeLanguage?: boolean;
}

export interface WithChannelConfig {
  channel: ChannelConfig;
}

export interface EnvironmentModeConfigDevelopment {
  isDevelopment: true;
  isQa: false;
  isProduction: false;
}

export interface EnvironmentModeConfigQa {
  isDevelopment: false;
  isQa: true;
  isProduction: false;
}

export interface EnvironmentModeConfigProduction {
  isDevelopment: false;
  isQa: false;
  isProduction: true;
}

export type WithEnvironmentModeConfig = XOR<
  XOR<EnvironmentModeConfigDevelopment, EnvironmentModeConfigQa>,
  EnvironmentModeConfigProduction
>;

export enum EnvironmentMode {
  development = 'development',
  qa = 'qa',
  production = 'production',
}

export type ProfileConfig<Environment> = Record<
  EnvironmentMode,
  Environment
> & {
  name: Profile;
  local?: Environment;
};

export interface ProfileChannelMapping extends Channel {
  profile: Profile;
  color: ProfileColor;
}

export type Env =
  | EnvironmentMode.development
  | EnvironmentMode.qa
  | EnvironmentMode.production
  | 'local';

export type GraphqlClientConfig = {
  url: string;
};

export type WithGraphqlClientConfig = {
  graphqlClient: GraphqlClientConfig;
};
