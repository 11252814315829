/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { Position } from '@cca/util';

type Props = {
  children: ReactNode;
  rotate: Extract<Position, Position.Left | Position.Right>;
  disabledAfterClick?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  onDisable?: () => void;
  onClickAnimationComplete?: () => void;
};
const AnimatedFeedbackThumb = ({
  children,
  rotate,
  disabledAfterClick = false,
  onClick = () => {},
  disabled = false,
  onDisable = () => {},
  onClickAnimationComplete = () => {},
}: Props) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(disabled);

  const [clickedOnce, setClickedOnce] = useState<boolean>(false);

  const rotateValue = useMemo(
    () => (rotate === Position.Left ? -10 : 10),
    [rotate],
  );

  useEffect(() => {
    setDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    if (clicked) {
      setClickedOnce(true);
    }
  }, [clicked]);

  useEffect(() => {
    if (disabledAfterClick && clickedOnce && !clicked) {
      setDisabled(true);
      onDisable();
    }
  }, [disabledAfterClick, clicked, clickedOnce, onDisable]);

  return (
    <motion.div
      sx={{
        padding: '4px 6px',
      }}
      animate={{
        rotate: 0,
        scale: 1,
        ...(!isDisabled &&
          clicked && {
            rotate: 0,
            scale: [0.9, 1.1, 1],
            transition: { duration: 0.2 },
          }),
        ...(!isDisabled &&
          !clicked &&
          hovered && { rotate: rotateValue, scale: 1.1 }),
      }}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      onClick={() => {
        setClicked(true);
        onClick();
      }}
      onAnimationComplete={() => {
        if (clicked) {
          setClicked(false);
          onClickAnimationComplete();
        }
      }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedFeedbackThumb;
