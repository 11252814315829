import { graphql } from '@cca/chatbot-graphql-types';

const botAvatarFragmentGql = graphql(/* GraphQL*/ `
  fragment BotAvatarMessageFields on BotAvatarMessage {
    id
    scope
    type
    kind
  }
`);

export default botAvatarFragmentGql;
