import { ForwardedRef, Fragment, Suspense, lazy, useMemo } from 'react';
import * as React from 'react';
import { Flex } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';
import { UiTheme } from '../../../theme';
import useThemeBase from '../../../theme/provider/useThemeBase';

type BotAvatarKind = 'service' | 'error' | 'feedback';

function getAvatar(kind: BotAvatarKind, theme: UiTheme) {
  const kindMap: Record<
    BotAvatarKind,
    () => React.LazyExoticComponent<
      (props: {
        height: number;
        ref: ForwardedRef<SVGSVGElement>;
      }) => JSX.Element
    >
  > = {
    service: () => lazy(() => import(`./kinds/${theme}/ServiceAvatar`)),
    error: () => lazy(() => import(`./kinds/${theme}/ErrorAvatar`)),
    feedback: () => lazy(() => import(`./kinds/${theme}/FeedbackAvatar`)),
  };
  return kindMap[kind]();
}

export type BotAvatarProps = {
  kind?: BotAvatarKind;
  height?: number;
};
const BotAvatar = (
  { kind = 'service', height = 46 }: BotAvatarProps,
  ref: ForwardedRef<SVGSVGElement>,
) => {
  const theme = useThemeBase();
  const Avatar = useMemo(() => getAvatar(kind, theme), [kind, theme]);

  return (
    <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Fragment>
        <Suspense fallback="">
          <Avatar ref={ref} height={height} />
        </Suspense>
      </Fragment>
    </Flex>
  );
};

export default forwardRefWithDisplayName(BotAvatar, 'BotAvatar');
