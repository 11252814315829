import { flow } from 'lodash';
import { MarkOptional } from 'ts-essentials';

import { ApolloError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { messageFragmentGql } from '@cca/chatbot-graphql-fragments';
import {
  ExecuteActionMutationVariables,
  withFragment,
  withMessage,
} from '@cca/chatbot-graphql-types';

import apolloClient from '../../../services/apolloClient';
import logger from '../../../services/logger';
import { inputActions } from '../input';

import { apiResponseFragmentsGql } from './fragments.gql';
import { messagesActions } from './index';
import { executeActionGql } from './queries.gql';

export const executeBotAction = createAsyncThunk<
  void,
  MarkOptional<ExecuteActionMutationVariables, 'params'> & {
    resetActiveResponseIds?: boolean;
  }
>(
  'messages/executeAction',
  async (
    { action, sessionId, params = {}, resetActiveResponseIds = true },
    { dispatch },
  ) => {
    try {
      dispatch(inputActions.setShowTypingIndicator(true));
      if (resetActiveResponseIds) {
        dispatch(messagesActions.setActiveResponseIds([]));
      }

      const result = await apolloClient.mutate({
        mutation: executeActionGql,
        variables: {
          sessionId,
          action,
          params,
        },
      });

      if (result.data) {
        const resolvedBotMessage = flow(
          (fragment) => withFragment(apiResponseFragmentsGql, fragment),
          (fragment) => ({
            ...fragment,
            messages: withFragment(messageFragmentGql, fragment.messages),
          }),
          (fragment) => ({
            ...fragment,
            messages: fragment.messages.map(withMessage),
          }),
        )(result.data.executeAction);

        dispatch(
          messagesActions.handleBotMessage({
            ...resolvedBotMessage,
            // Whenever "old" messages should be still active (e.g. when a user clicks on a product card while bot asks
            // for location), we also want to preserve the current input type to ensure that a specific input prompt is
            // not interrupted (e.g. preserve location input after clicking on a product card; without, the user would
            // no more be able to enter the location).
            resetInput: resetActiveResponseIds,
          }),
        );
        dispatch(
          resetActiveResponseIds
            ? messagesActions.setActiveResponseIds([resolvedBotMessage.id])
            : messagesActions.addActiveResponseId(resolvedBotMessage.id),
        );
      } else {
        const error = new ApolloError({ graphQLErrors: result.errors });
        logger.error(error);
        dispatch(messagesActions.triggerErrorMessage());
      }
    } catch (error) {
      logger.unknownError(error);
      dispatch(messagesActions.triggerErrorMessage());
    } finally {
      dispatch(inputActions.setShowTypingIndicator(false));
    }
  },
);
