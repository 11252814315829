import { ComponentType, PropsWithChildren, useCallback, useMemo } from 'react';

import { Position } from '@cca/util';

import TypingIndicator from '../typing-indicator/TypingIndicator';

import ChatHistoryContainer from './chat-history-container/ChatHistoryContainer';
import BotMessageEntry, {
  BotMessageEntryProps,
} from './chat-history-entry/BotMessageEntry';
import ChatHistoryEntry from './chat-history-entry/ChatHistoryEntry';
import UserMessageEntry, {
  UserMessageEntryProps,
} from './chat-history-entry/UserMessageEntry';
import { ChatMessage, BotMessage as IBotMessage } from './types';

interface Message {
  type: string;
}

export type MessageWrapperProps = PropsWithChildren<{
  message: ChatMessage<Message>;
}>;
const DefaultMessageWrapper = ({ children }: MessageWrapperProps) => {
  return <>{children}</>;
};

type Props = {
  messages: ChatMessage<Message>[];
  showTypingIndicator: boolean;
  activeResponseIds: string[];
  firstMessageOfActiveResponse?: IBotMessage<Message>;
  componentLoaders: Record<
    string,
    () => Promise<{ default: ComponentType<any> }>
  >;
  disableLazyLoader?: boolean;
  MessageWrapperComponent?: ComponentType<MessageWrapperProps>;
  BotMessageEntryComponent?: ComponentType<BotMessageEntryProps>;
  UserMessageEntryComponent?: ComponentType<UserMessageEntryProps>;
};
const ChatHistory = ({
  messages,
  showTypingIndicator,
  activeResponseIds,
  firstMessageOfActiveResponse,
  componentLoaders,
  MessageWrapperComponent = DefaultMessageWrapper,
  BotMessageEntryComponent = BotMessageEntry,
  UserMessageEntryComponent = UserMessageEntry,
}: Props) => {
  const processedMessages = useMemo(
    () =>
      messages.map((message, index) => ({
        ...message,
        disabled: !(
          message.isBot &&
          activeResponseIds.includes(message.dialogflowResponseId)
        ),
        // we do NOT automatically scroll to the message if
        // 1. it is the first message in the history, because we want to display the greeting avatar
        // 2. it is NOT the first message in a complete response (which can contain multiple messages)
        scrollToOnInit:
          index !== 0 && firstMessageOfActiveResponse?.id === message.id,
      })),
    [messages, activeResponseIds, firstMessageOfActiveResponse],
  );

  const getComponentLoader = useCallback(
    (type: string) => {
      const componentLoader = componentLoaders[type];
      if (!componentLoader) {
        throw new Error(`No component loader for '${type}' found.`);
      }
      return componentLoader;
    },
    [componentLoaders],
  );

  return (
    <ChatHistoryContainer>
      {processedMessages.map((message) => (
        <MessageWrapperComponent message={message} key={message.id}>
          {message.isBot ? (
            <BotMessageEntryComponent
              message={message}
              key={message.id}
              showAvatar={false}
              showBotName={true}
              disabled={message.disabled}
              scrollToOnInit={message.scrollToOnInit}
              componentLoader={getComponentLoader(message.message.type)}
            />
          ) : (
            <UserMessageEntryComponent message={message} key={message.id} />
          )}
        </MessageWrapperComponent>
      ))}
      {/* TODO: add a time slot for the indicator to be displayed at a minimum */}
      {showTypingIndicator && (
        <ChatHistoryEntry
          orientation={Position.Left}
          animate={false}
          scrollToOnInit={true}
        >
          <TypingIndicator />
        </ChatHistoryEntry>
      )}
    </ChatHistoryContainer>
  );
};

export default ChatHistory;
