/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion';
import { ForwardedRef, ReactNode, forwardRef, useEffect } from 'react';
import { Box } from 'theme-ui';

import { Position } from '@cca/util';
import { useForwardedRefProxy } from '@cca/util-react';

type Props = {
  children: ReactNode;
  orientation: Extract<Position, Position.Left | Position.Right>;
  animate?: boolean;
  hasFullWidth?: boolean;
  scrollToOnInit?: (() => boolean) | boolean;
  messageId?: string;
};
const ChatHistoryEntry = (
  {
    children,
    orientation,
    animate = false,
    hasFullWidth = false,
    scrollToOnInit = false,
    messageId = '',
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const divRef = useForwardedRefProxy<HTMLDivElement>(ref, null);

  const item = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 100 },
  };

  useEffect(() => {
    scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function scrollIntoView() {
    if (scrollToOnInit) {
      divRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }

  return (
    <motion.div
      id={`ChatHistoryEntry-${messageId}`}
      ref={divRef}
      variants={item}
      animate="visible"
      onAnimationComplete={scrollIntoView}
      // layout
      initial={animate ? 'hidden' : 'visible'}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent:
          orientation === Position.Left ? 'flex-start' : 'flex-end',
      }}
    >
      <Box sx={{ paddingTop: '20px', width: hasFullWidth ? '100%' : '90%' }}>
        {children}
      </Box>
    </motion.div>
  );
};

export default forwardRef(ChatHistoryEntry);
