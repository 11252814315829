import { ReactNode } from 'react';
import { ThemeUIStyleObject, Box as UIBox } from 'theme-ui';

type Props = {
  flex?: boolean;
  justifyEnd?: boolean;
  children: ReactNode;
};
const Box = ({ children, justifyEnd, flex }: Props) => {
  return (
    <UIBox
      sx={
        {
          ...(justifyEnd && { justifyContent: 'flex-end' }),
          ...(flex && { display: 'flex' }),
        } as ThemeUIStyleObject
      }
    >
      {children}
    </UIBox>
  );
};

export default Box;
