export type PriceParts = {
  first: string;
  last: string;
};

export function getPriceParts(price: number): PriceParts {
  if (Number.isInteger(price)) {
    return { first: `${price}.–`, last: '' };
  }
  const [first, last] = `${price}`.split('.');
  return { first: `${first}.`, last: last.length > 1 ? last : `${last}0` };
}
