import { Theme } from 'theme-ui';

export default {
  layout: {
    surveyCategory: {
      listItem: {
        listStyle: 'none',
        my: '8px',
      },
      radioButton: {
        'input:checked ~ &': {
          color: 'black',
        },
        'input:focus ~ &': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
} as Theme;
