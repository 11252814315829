import { motion, useAnimation } from 'framer-motion';
import { Suspense, useCallback, useEffect } from 'react';

import { useSyncedRef } from '@react-hookz/web';

import { LoadingIndicator } from '@cca/ui';
import { useLazyComponent, useRefCallback } from '@cca/util-react';

import { useAppStateSelector } from '../../store';

export type ChatbotInputRefObject = { focus: () => void };

const variants = {
  initial: {
    scale: 1,
  },
  bump: {
    scale: [0.98, 1.02, 0.98, 1],
    transition: { duration: 0.4 },
  },
};

type Props = {};
// eslint-disable-next-line no-empty-pattern
const ChatbotInput = ({}: Props) => {
  const controls = useAnimation();

  const input = useAppStateSelector((state) => state.input.input);
  const lastInput = useSyncedRef(input);
  const showTypingIndicator = useAppStateSelector(
    (state) => state.input.showTypingIndicator,
  );
  const inputFocusTriggered = useAppStateSelector(
    (state) => state.input.inputFocusTriggered,
  );

  const [ref, setRef] = useRefCallback<ChatbotInputRefObject>(() => {
    if (lastInput.current?.type !== input?.type) {
      controls.start('bump');
    }

    ref.current?.focus();
  }, [input, lastInput]);

  const componentLoader = useCallback(
    () => import(`../messages/${input ? input.type : 'common/text-input'}`),
    [input],
  );

  const Input = useLazyComponent<{
    ref: (refObject: ChatbotInputRefObject) => void;
    disabled: boolean;
  }>(componentLoader, input ? input.type : 'default');

  useEffect(() => {
    if (!showTypingIndicator) {
      ref.current?.focus();
    }
  }, [ref, showTypingIndicator]);

  useEffect(() => {
    ref.current?.focus();
  }, [ref, inputFocusTriggered]);

  return (
    <Suspense fallback={<LoadingIndicator size="md" />}>
      <motion.div variants={variants} initial="initial" animate={controls}>
        <Input ref={setRef} {...input} disabled={showTypingIndicator} />
      </motion.div>
    </Suspense>
  );
};

export default ChatbotInput;
