import { Box } from 'theme-ui';

import { Position } from '@cca/util';

import ArrowHead from './ArrowHead';
import GradeWithPlus from './GradeWithPlus';

const colors: Record<CCA.GraphQLEnergyEfficiencyClass, string> = {
  'A+++': '#00963f',
  'A++': '#51b032',
  'A+': '#c7d400',
  A: '#feec02',
  B: '#faba00',
  C: '#eb6504',
  D: '#e3010f',
};

export type LegacyEnergyEfficiencyLabelProps = {
  grade: CCA.GraphQLEnergyEfficiencyClass;
};

const LegacyEnergyEfficiencyLabel = ({
  grade,
}: LegacyEnergyEfficiencyLabelProps) => {
  return (
    <Box
      sx={{
        height: '20px',
        display: 'flex',
      }}
    >
      <ArrowHead direction={Position.Left} color={colors[grade]} />
      <Box
        sx={{
          backgroundColor: `${colors[grade]}`,
          minWidth: '28px',
          color: 'white',
          fontWeight: 'bold',
          lineHeight: '20px',
          fontSize: '20px',
          paddingRight: '2px',
        }}
      >
        {grade.includes('+') ? <GradeWithPlus grade={grade} /> : grade}
      </Box>
    </Box>
  );
};

export default LegacyEnergyEfficiencyLabel;
