import { ReactNode } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

type Props = {
  question: string;
  children: ReactNode;
  styles?: ThemeUIStyleObject;
};
const SurveyQuestion = ({ question, children, styles = {} }: Props) => {
  return (
    <Box sx={{ paddingBottom: '7px', ...styles }}>
      <Box>{question}</Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default SurveyQuestion;
