/** @jsxImportSource theme-ui */
import useThemeBase from '../../../theme/provider/useThemeBase';

type Props = { color?: string };
const MailIcon = ({ color = 'iconPrimary' }: Props) => {
  const theme = useThemeBase();

  const mmIcon = (
    <svg
      width="20"
      height="16"
      viewBox="2 4 20 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        sx={{ fill: color }}
        d="M20 4H4a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2zm0 2v2.9l-7.35 5.15a1.14 1.14 0 01-1.3 0L4 8.9V6zM4 18v-7l6.35 4.44a2.88 2.88 0 003.3 0L20 11v7z"
      />
    </svg>
  );
  const seIcon = (
    <svg
      width="20"
      height="16"
      viewBox="0 0 40.044 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        sx={{ fill: color }}
        d="M 0 32 L 40 32 L 40 0 L 0 0 L 0 32 Z M 4 9.798 L 4 4 L 36 4 L 36 9.798 L 20 20.998 L 4 9.798 Z M 36 14.072 L 36 28 L 4 28 L 4 14.072 L 20 25.278 L 36 14.072 Z"
      />
    </svg>
  );

  return theme === 'mm' ? mmIcon : seIcon;
};

export default MailIcon;
