/** @jsxImportSource theme-ui */
import { MotionValue, motion } from 'framer-motion';
import { ReactNode, useEffect, useState } from 'react';

const botAvatarHeaderScale = 1.15;
const botAvatarHistoryScale = 2;
const botAvatarTranslationYInHeader = 0;
const botAvatarTranslationYInHistory = 88;
const botAvatarAnimationTriggerPoint = 25;

type Props = {
  children: ReactNode;
  scrollY: MotionValue<number>;
};
const AnimatedBotAvatar = ({ children, scrollY }: Props) => {
  const [botAvatarScale, setBotAvatarScale] = useState(botAvatarHistoryScale);
  const [botAvatarTranslationY, setBotAvatarTranslationY] = useState(
    botAvatarTranslationYInHistory,
  );

  useEffect(
    () =>
      scrollY.on('change', (scrollPosition) => {
        if (scrollPosition > botAvatarAnimationTriggerPoint) {
          setBotAvatarScale(botAvatarHeaderScale);
          setBotAvatarTranslationY(botAvatarTranslationYInHeader);
        } else {
          setBotAvatarScale(botAvatarHistoryScale);
          setBotAvatarTranslationY(botAvatarTranslationYInHistory);
        }
      }),
    [scrollY],
  );

  // https://www.framer.com/docs/component/###transformtemplate
  return (
    <motion.div
      sx={{
        display: 'flex',
      }}
      animate={{
        scale: botAvatarScale,
        y: botAvatarTranslationY,
      }}
      transformTemplate={({ y, scale }) => `translateY(${y}) scale(${scale})`}
      transition={{ ease: 'backInOut', delay: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedBotAvatar;
