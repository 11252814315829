import { graphql } from '@cca/chatbot-graphql-types';

const interpolatedTextFragmentGql = graphql(/* GraphQL*/ `
  fragment InterpolatedTextMessageFields on InterpolatedTextMessage {
    id
    scope
    type
    text
    interpolations {
      name
      type

      ... on TranslationInterpolation {
        parameters {
          name
          value
        }
      }
    }
  }
`);

export default interpolatedTextFragmentGql;
