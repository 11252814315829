import { Theme } from 'theme-ui';

export default {
  layout: {
    list: {
      margin: 0,
      padding: 0,
    },
    listItem: {
      padding: '16px 24px',
      margin: 0,
      listStyle: 'none',
      borderBottomWidth: '0.5px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'grey2',
      '&:nth-of-type(1)': {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      },
      '&:last-child': {
        border: 'none',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      },
    },
  },
} as Theme;
