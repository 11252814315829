import {
  Children,
  ReactElement,
  RefAttributes,
  cloneElement,
  isValidElement,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { MessageWrapperProps } from '@cca/ui';

import useClickTrackingContext from './useClickTrackingContext';

type Props = MessageWrapperProps;
const ClickTrackingMessageWrapper = ({ children, message }: Props) => {
  const clickTrackingContext = useClickTrackingContext();
  const [rendered, setRendered] = useState<Date>(new Date());

  const onClick = useCallback(() => {
    if (message.isBot) {
      // check necessary for typescript cast
      clickTrackingContext.context({
        responseId: message.dialogflowResponseId,
        messageId: message.id,
      });
      clickTrackingContext.rendered(rendered);
    }
  }, [clickTrackingContext, message, rendered]);

  const onRefLoad = useCallback(
    (current: HTMLElement) => {
      if (message.isBot) {
        setRendered(new Date());
        current.addEventListener('click', onClick);
      }
    },
    [message.isBot, onClick],
  );

  const childWithRef = useMemo(
    () =>
      Children.map(Children.only(children), (child) =>
        isValidElement(child)
          ? // FIXME: child could be theoretically a Portal without a ref
            //   Instead of using `cloneElement` choose an alternative from here: https://beta.reactjs.org/apis/react/cloneElement#alternatives
            cloneElement(child as ReactElement<RefAttributes<HTMLElement>>, {
              ref: onRefLoad,
            })
          : child,
      ),
    [children, onRefLoad],
  );

  return <>{childWithRef}</>;
};

export default ClickTrackingMessageWrapper;
