import { graphql } from '@cca/chatbot-graphql-types';

export const executeActionGql = graphql(/* GraphQL*/ `
  mutation ExecuteAction(
    $sessionId: String!
    $action: ReplyActionInput!
    $params: JSON!
  ) {
    executeAction(
      sessionId: $sessionId
      action: $action
      queryParams: { parameters: $params }
    ) {
      ...ApiResponseFields
    }
  }
`);

export const sendMessageGql = graphql(/* GraphQL*/ `
  mutation SendMessage($sessionId: String!, $message: String!) {
    sendMessage(sessionId: $sessionId, message: $message) {
      ...ApiResponseFields
    }
  }
`);

export const startConversationGql = graphql(/* GraphQL*/ `
  mutation StartConversation {
    startConversation {
      ...ApiResponseFields
    }
  }
`);
