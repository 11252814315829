import { Position } from '@cca/util';

import LoadingIndicator from '../../loader/loading-indicator/LoadingIndicator';

import ChatHistoryEntry from './ChatHistoryEntry';

type Props = {};
// eslint-disable-next-line no-empty-pattern
const LoadingMessageEntry = ({}: Props) => {
  return (
    <ChatHistoryEntry
      orientation={Position.Left}
      animate={false}
      hasFullWidth={true}
    >
      <LoadingIndicator size={'sm'} />
    </ChatHistoryEntry>
  );
};

export default LoadingMessageEntry;
