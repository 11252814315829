import { graphql } from '@cca/chatbot-graphql-types';

const productCardFragmentGql = graphql(/* GraphQL*/ `
  fragment ProductCardMessageFields on ProductCardMessage {
    id
    scope
    type
    product {
      ...ProductFields
    }
    availabilityAction {
      ...ActionFields
    }
  }
`);

export default productCardFragmentGql;
