import { graphql } from '@cca/chatbot-graphql-types';

const messageFragmentGql = graphql(/* GraphQL*/ `
  fragment MessagesFields on Message {
    __typename

    ...TextMessageFields
    ...BotAvatarMessageFields
    ...CardMessageFields
    ...QuickReplyMessageFields
    ...FlowFeedbackMessageFields
    ...ContactCardMessageFields
    ...ProductSearchInputMessageFields
    ...ProductCardMessageFields
    ...LocationSearchInputMessageFields
    ...OutletsCardMessageFields
    ...FaqCardMessageFields
    ...InterpolatedTextMessageFields
    ...SurveyButtonMessageFields
  }
`);

const actionFragmentGql = graphql(/* GraphQL*/ `
  fragment ActionFields on ReplyAction {
    type
    value
  }
`);

export { messageFragmentGql, actionFragmentGql };
