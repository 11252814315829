import { graphql } from '@cca/chatbot-graphql-types';

export const addClickTrackingGql = graphql(/* GraphQL*/ `
  mutation AddClickTracking($clickTracking: ClickTrackingInput!) {
    clickTracking(clickTracking: $clickTracking) {
      id
      created
    }
  }
`);
