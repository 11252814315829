/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion';
import { ReactNode, Ref, forwardRef, useImperativeHandle, useRef } from 'react';

import ChatHistoryContext, {
  ChatHistoryContextProps,
} from './ChatHistoryContext';

export type ChatHistoryRef = Ref<ChatHistoryContextProps>;

export type ChatHistoryContainerProps = { children: ReactNode };
const ChatHistoryContainer = (
  { children }: ChatHistoryContainerProps,
  ref: ChatHistoryRef,
) => {
  useImperativeHandle(ref, () => ({ scrollToBottom }));

  const containerRef = useRef<HTMLDivElement>(null);

  // TODO: remove after some time of testing the new scrolling behavior:
  // scroll to first message of active response instead of scrolling down
  // to the end of the history.
  function scrollToBottom() {
    containerRef.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }

  return (
    <ChatHistoryContext.Provider value={{ scrollToBottom }}>
      {/*<AnimateSharedLayout>*/}
      <motion.div
        ref={containerRef}
        sx={{ marginTop: '-20px', paddingBottom: '20px' }}
        // layout
      >
        {children}
      </motion.div>
      {/*</AnimateSharedLayout>*/}
    </ChatHistoryContext.Provider>
  );
};

export default forwardRef(ChatHistoryContainer);
