import { Message } from '../../message';
import { Text } from '../../text';
import { UserMessageInputWithProps } from '../types';

const UserMessageEntryMessage = ({
  orientation,
  onLinkClick = () => {},
  value,
}: UserMessageInputWithProps) => (
  <Message orientation={orientation}>
    <Text onLinkClick={onLinkClick}>{value}</Text>
  </Message>
);

export default UserMessageEntryMessage;
