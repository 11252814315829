import { ForwardedRef, ReactNode } from 'react';
import { Flex } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';
import Themed from '../../utility/themed/Themed';

type Props = {
  mainColor?: string;
  left?: ReactNode;
  main: ReactNode;
  right?: ReactNode;
  customRef?: ForwardedRef<HTMLDivElement>;
};
const StructuredStartEntry = (
  { mainColor = 'grey5', left, main, right, customRef }: Props,
  _: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Flex ref={customRef} sx={{ alignItems: 'center' }}>
      {left}
      <Themed
        sx={{
          fontFamily: 'prominent',
          paddingLeft: left ? '10px' : '0',
          paddingRight: '24px',
          fontSize: '18px',
          color: mainColor,
        }}
        seSx={{ variant: 'layout.prominentFix' }}
      >
        {main}
      </Themed>
      {right}
    </Flex>
  );
};

export default forwardRefWithDisplayName(
  StructuredStartEntry,
  'StructuredStartEntry',
);
