import { graphql } from '@cca/chatbot-graphql-types';

const outletsCardFragmentGql = graphql(/* GraphQL*/ `
  fragment OutletsCardMessageFields on OutletsCardMessage {
    id
    scope
    type
    outletsWithDistance {
      ...OutletWithDistanceFields
    }
  }
`);

export default outletsCardFragmentGql;
