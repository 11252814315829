import { ForwardRefRenderFunction, forwardRef } from 'react';

const forwardRefWithDisplayName = <P, T>(
  component: ForwardRefRenderFunction<P, T>,
  name?: string,
) => {
  const componentWithForwardedRef = forwardRef<P, T>(component);
  componentWithForwardedRef.displayName =
    name || component.name || component.displayName;
  return componentWithForwardedRef;
};

export default forwardRefWithDisplayName;
