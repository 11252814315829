import { Fragment, Suspense, lazy, useMemo } from 'react';

import { Position } from '@cca/util';

const LeftArrowIcon = lazy(() => import('./arrows/LeftArrowIcon'));
const RightArrowIcon = lazy(() => import('./arrows/RightArrowIcon'));
const DownArrowIcon = lazy(() => import('./arrows/DownArrowIcon'));
const UpArrowIcon = lazy(() => import('./arrows/UpArrowIcon'));

type Props = {
  color?: string;
  orientation?: Extract<
    Position,
    Position.Down | Position.Up | Position.Left | Position.Right
  >;
};
const ArrowIcon = ({
  color = 'iconPrimary',
  orientation = Position.Right,
}: Props) => {
  const renderIcon = useMemo(
    () => ({
      [Position.Down]: () => <DownArrowIcon color={color} />,
      [Position.Left]: () => <LeftArrowIcon color={color} />,
      [Position.Right]: () => <RightArrowIcon color={color} />,
      [Position.Up]: () => <UpArrowIcon color={color} />,
    }),
    [color],
  );

  return (
    <Fragment>
      <Suspense fallback={<></>}>
        {(renderIcon[orientation] || (() => <></>))()}
      </Suspense>
    </Fragment>
  );
};

export default ArrowIcon;
