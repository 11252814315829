import { Theme } from 'theme-ui';

export default {
  cards: {
    primary: {
      width: '100%',
      backgroundColor: 'white',
      boxShadow:
        'rgba(0, 0, 0, 0.07) 0px 3px 4px 0px, rgba(0, 0, 0, 0.06) 0px 3px 3px -2px, rgba(0, 0, 0, 0.1) 0px 1px 8px 0px;',
      margin: 0,
      padding: 0,
    },
  },
  layout: {
    cardHeader: {
      borderBottomWidth: '0.5px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'grey2',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      alignItems: 'center',
    },
    cardBody: {
      padding: '16px 24px',
    },
  },
} as Theme;
