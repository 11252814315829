import { motion } from 'framer-motion';
import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Flex } from 'theme-ui';

import { FeedbackValue } from '@cca/chatbot-graphql-types';

import { MoreFeedbackIcon } from '../icons';

import ThumbsFeedback, { ThumbsFeedbackProps } from './ThumbsFeedback';
import { getFeedbackState } from './util';

const TOP_POSITION_OFFSET = -5;
const LEFT_POSITION_OFFSET = -40;
const LEFT_POSITION_OFFSET_AFTER_POSITIVE_FEEDBACK = 20;
const LEFT_POSITION_OFFSET_AFTER_NEGATIVE_FEEDBACK = -20;
const TRANSITION_DELAY = 0.05;
const MORE_FEEDBACK_BUTTON_OFFSET_AFTER_POSITIVE_FEEDBACK = 51;
const MORE_FEEDBACK_BUTTON_OFFSET_AFTER_NEGATIVE_FEEDBACK = 90;

type Props = {
  state?: FeedbackValue;
  onLike?: () => void;
  onDislike?: () => void;
  disable?: boolean;
  disabledAfterClick?: boolean;
  ThumbsFeedbackComponent?: ComponentType<ThumbsFeedbackProps>;
  hideLike?: boolean;
  hideDislike?: boolean;
  onSurveyTrigger?: () => void;
  surveyButtonDisabled?: boolean;
  showSurveyButtonAfterClick?: boolean;
};
const IntentFeedback = ({
  state,
  onLike = () => {},
  onDislike = () => {},
  disable = false,
  disabledAfterClick = false,
  ThumbsFeedbackComponent = ThumbsFeedback,
  hideLike = false,
  hideDislike = false,
  onSurveyTrigger = () => {},
  surveyButtonDisabled = false,
  showSurveyButtonAfterClick = true,
}: Props) => {
  const [hidePositive, setHidePositive] = useState<boolean>(hideLike);
  const [hideNegative, setHideNegative] = useState<boolean>(hideDislike);

  const onPositive = useCallback(() => {
    onLike();
    setHideNegative(true);
  }, [onLike]);

  const onNegative = useCallback(() => {
    onDislike();
    setHidePositive(true);
  }, [onDislike]);

  const xOffset = useMemo(() => {
    if (state === FeedbackValue.Like) {
      return LEFT_POSITION_OFFSET_AFTER_POSITIVE_FEEDBACK;
    } else if (state === FeedbackValue.Dislike) {
      return LEFT_POSITION_OFFSET_AFTER_NEGATIVE_FEEDBACK;
    } else {
      return LEFT_POSITION_OFFSET;
    }
  }, [state]);

  useEffect(() => {
    setHideNegative(hideDislike);
    setHidePositive(hideLike);
  }, [hideLike, hideDislike]);

  return (
    <motion.div
      initial={{
        x: LEFT_POSITION_OFFSET,
        y: TOP_POSITION_OFFSET,
      }}
      animate={{
        x: xOffset,
        transition: {
          delay: TRANSITION_DELAY,
        },
      }}
    >
      <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <ThumbsFeedbackComponent
          state={state && getFeedbackState(state)}
          disabledAfterClick={disabledAfterClick}
          onPositive={onPositive}
          onNegative={onNegative}
          disable={disable}
          iconHeight={20}
          feedbackContainerSx={{ variant: 'layout.intentFeedback' }}
          hidePositive={hidePositive}
          hideNegative={hideNegative}
        />
        {state && showSurveyButtonAfterClick && (
          <Button
            variant="layout.intentFeedback.moreFeedback"
            onClick={onSurveyTrigger}
            disabled={surveyButtonDisabled}
            sx={{
              left:
                state === FeedbackValue.Dislike
                  ? MORE_FEEDBACK_BUTTON_OFFSET_AFTER_NEGATIVE_FEEDBACK
                  : MORE_FEEDBACK_BUTTON_OFFSET_AFTER_POSITIVE_FEEDBACK,
            }}
          >
            <MoreFeedbackIcon />
          </Button>
        )}
      </Flex>
    </motion.div>
  );
};

export default IntentFeedback;
