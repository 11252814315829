import { ForwardedRef, useMemo } from 'react';
import { Box } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';
import { Rating } from '../../rating';

export type ProductRatingProps = {
  averageRating?: number | null;
  ratingsCount?: number;
};

type Props = ProductRatingProps;
const ProductRating = (
  { averageRating, ratingsCount }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const description = useMemo(
    () => (ratingsCount ? `(${ratingsCount})` : undefined),
    [ratingsCount],
  );
  const value = useMemo(
    () => (averageRating ? averageRating : undefined),
    [averageRating],
  );

  return (
    <Box ref={ref}>
      <Rating value={value} description={description} size="sm" disabled />
    </Box>
  );
};

export default forwardRefWithDisplayName(ProductRating, 'ProductRating');
