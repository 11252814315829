type LoggingFunction = (...messages: unknown[]) => void;

type LoggingInterface = {
  error: LoggingFunction;
  info: LoggingFunction;
  unknownError: (errorInstance: unknown) => void;
};

export function createLogger(
  name: string,
  loggingEnabled: boolean,
): LoggingInterface {
  const log = (handler: LoggingFunction) =>
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    loggingEnabled ? handler : () => {};

  const error: LoggingInterface['error'] = log((...messages) => {
    console.error(`[${name}][Error]`, ...messages);
  });

  const info: LoggingInterface['info'] = log((...messages) => {
    console.info(`[${name}][Info]`, ...messages);
  });

  const unknownError: LoggingInterface['unknownError'] = (errorInstance) => {
    if (errorInstance instanceof Error) {
      error();
    } else {
      error('Unknown Error: ' + errorInstance);
    }
  };

  return {
    error,
    info,
    unknownError,
  };
}
