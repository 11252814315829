import { Theme } from 'theme-ui';

export default {
  layout: {
    frame: {
      flexDirection: 'column',
      height: '100vh',
    },
    header: {
      display: 'flex',
      position: 'relative',
      flex: '0 0 auto',
      bg: 'white',
      boxShadow: '0px 2px 3px #00000029',
      zIndex: '1',
    },
    main: {
      // https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336
      flex: 'auto',
      overflow: 'hidden',
      padding: '120px 20px 0 20px',
      bg: 'white',
      overflowY: 'auto',
    },
    mainRegular: {
      // https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336
      flex: 'auto',
      overflow: 'hidden',
      padding: '20px',
      bg: 'white',
      overflowY: 'auto',
    },
    footer: {
      flex: '0 0 auto',
      bg: 'white',
      position: 'relative',
      height: '90px',
    },
  },
} as Theme;
