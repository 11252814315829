/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion';
import { ForwardedRef } from 'react';

import { Position } from '@cca/util';

import { forwardRefWithDisplayName } from '../../../hocs';
import { ArrowIcon } from '../../icons';

export type ExpandButtonProps = {
  expand?: boolean;
  onChange?: (expand: boolean) => void;
};

type Props = ExpandButtonProps;
const ExpandButton = (
  { expand = false, onChange = () => {} }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <motion.div
      ref={ref}
      initial={{ rotate: 0 }}
      animate={{
        rotate: expand ? -180 : 0,
        transition: {
          duration: 0.35,
        },
      }}
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: '-16px',
        right: '-16px',
        padding: '16px',
      }}
      onClick={() => {
        onChange(!expand);
      }}
    >
      <ArrowIcon orientation={Position.Down} />
    </motion.div>
  );
};

export default forwardRefWithDisplayName(ExpandButton, 'ExpandButton');
