import { createAsyncThunk } from '@reduxjs/toolkit';

import apolloClient from '../../../services/apolloClient';
import logger from '../../../services/logger';

import { productsActions } from './index';
import { getProductSuggestionsGql } from './queries.gql';

export const getProductSuggestions = createAsyncThunk<void, { query: string }>(
  'products/getProductSuggestions',
  async ({ query }, { dispatch, signal }) => {
    try {
      const result = await apolloClient.query({
        query: getProductSuggestionsGql,
        variables: {
          query,
        },
        context: {
          fetchOptions: {
            signal,
          },
        },
      });

      dispatch(
        productsActions.setProductSuggestions(
          result.data.productSuggestions.suggestions,
        ),
      );
    } catch (error) {
      dispatch(productsActions.setProductSuggestions([]));
      logger.error(error);
    }
  },
);
