import { PayloadAction } from '@reduxjs/toolkit';

import { BotResponseWithFragments } from '@cca/chatbot-graphql-types';

import { MessagesState } from '../index';

export function addBotResponse(
  state: MessagesState,
  action: PayloadAction<BotResponseWithFragments>,
) {
  state.botResponses = {
    ...state.botResponses,
    [action.payload.id]: action.payload,
  };
}
