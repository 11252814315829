import { ComponentType, ForwardedRef, useMemo } from 'react';
import { Box } from 'theme-ui';

import { SurveyCategoryValue } from '@cca/chatbot-graphql-types';

import { forwardRefWithDisplayName } from '../../hocs';
import { Button, ButtonProps } from '../button';
import { FeedbackState } from '../feedback/types';
import { List } from '../list';
import { TextAreaInput } from '../text-area-input';
import { TextAreaInputProps } from '../text-area-input/TextAreaInput';

import SurveyCategoryListItem from './survey-category-list-item/SurveyCategoryListItem';
import SurveyQuestion from './survey-question/SurveyQuestion';
import { SurveyContext, SurveySubmitState } from './types';

export type SurveyProps = {
  feedbackState?: FeedbackState;
  context: SurveyContext;
  category: SurveyCategoryValue;
  onCategoryChange?: (category: SurveyCategoryValue) => void;
  feedbackText: string;
  onFeedbackTextChange?: (feedbackText: string) => void;
  onSubmit?: () => void;
  submitState: SurveySubmitState;
  translate: (key: string) => string;
  TextAreaInputComponent?: ComponentType<TextAreaInputProps>;
  ButtonComponent?: ComponentType<ButtonProps>;
};

const Survey = (
  {
    feedbackState,
    context,
    category,
    onCategoryChange = () => {},
    feedbackText,
    onFeedbackTextChange = () => {},
    onSubmit = () => {},
    submitState,
    translate,
    TextAreaInputComponent = TextAreaInput,
    ButtonComponent = Button,
  }: SurveyProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const headerText = useMemo(
    () =>
      feedbackState === FeedbackState.POSITIVE
        ? translate('survey.headerText.positive')
        : translate('survey.headerText.negative'),
    [feedbackState, translate],
  );

  const placeholderText = useMemo(
    () =>
      feedbackState === FeedbackState.POSITIVE
        ? translate('survey.placeholderText.positive')
        : translate('survey.placeholderText.negative'),
    [feedbackState, translate],
  );

  const submitLabels = useMemo(
    () => ({
      initial: translate('survey.submit'),
      submitting: translate('survey.submitting'),
      submitted: translate('survey.submitted'),
    }),
    [translate],
  );

  return (
    <Box ref={ref}>
      <SurveyQuestion
        question={headerText}
        styles={{ '& textarea': { marginTop: 0 }, fontWeight: 'bold' }}
      >
        <TextAreaInputComponent
          placeholder={placeholderText}
          rows={4}
          value={feedbackText}
          onChange={onFeedbackTextChange}
        />
      </SurveyQuestion>
      {feedbackState === FeedbackState.NEGATIVE && (
        <List>
          <SurveyCategoryListItem
            label={translate(`survey.category.${context}.notHelpful`)}
            checked={category === SurveyCategoryValue.NOT_HELPFUL}
            onChange={() => onCategoryChange(SurveyCategoryValue.NOT_HELPFUL)}
          />
          <SurveyCategoryListItem
            label={translate(`survey.category.${context}.incorrect`)}
            checked={category === SurveyCategoryValue.INCORRECT}
            onChange={() => onCategoryChange(SurveyCategoryValue.INCORRECT)}
          />
          <SurveyCategoryListItem
            label={translate(`survey.category.${context}.offensive`)}
            checked={category === SurveyCategoryValue.OFFENSIVE}
            onChange={() => onCategoryChange(SurveyCategoryValue.OFFENSIVE)}
          />
        </List>
      )}
      <ButtonComponent
        color="secondary"
        fullWidth
        onClick={onSubmit}
        disabled={
          !feedbackText || ['submitting', 'submitted'].includes(submitState)
        }
      >
        {submitLabels[submitState]}
      </ButtonComponent>
    </Box>
  );
};
export default forwardRefWithDisplayName(Survey, 'Survey');
