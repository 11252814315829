/** @jsxImportSource theme-ui */
type Props = { color?: string };
const SelectIcon = ({ color = 'iconPrimary' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.898"
      height="33.898"
      viewBox="0 0 33.898 33.898"
    >
      <g transform="translate(-7.116 59.24) rotate(-135)">
        <g transform="translate(0 35.838)">
          <path
            sx={{ fill: color }}
            d="M14.691,35.838l-1.423,1.423,8.654,8.654H0v2.012H21.922l-8.654,8.654L14.691,58,25.774,46.921Z"
            transform="translate(0 -35.838)"
          />
        </g>
      </g>
    </svg>
  );
};

export default SelectIcon;
