/** @jsxImportSource theme-ui */
import { ForwardedRef } from 'react';
import { Box } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';

export type ProductImageProps = {
  url?: string | null;
  alt?: string;
};

type Props = ProductImageProps;
const ProductImage = (
  { url, alt = '' }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => (
  <Box
    ref={ref}
    sx={{
      width: '100%',
      textAlign: 'center',
      margin: '25px 0px 16px',
    }}
  >
    <picture>
      <img
        sx={{
          display: 'block',
          margin: '0px auto',
          width: '100%',
          maxHeight: '150px',
          objectFit: 'contain',
        }}
        src={`${url}/mobile_200_200_png`}
        alt={alt}
        loading="eager"
        width="100%"
        height="150px"
      />
    </picture>
  </Box>
);

export default forwardRefWithDisplayName(ProductImage, 'ProductImage');
