import findLast from 'lodash/findLast';
import { last } from 'ramda';

import {
  BotResponseWithFragments,
  MessageFields,
} from '@cca/chatbot-graphql-types';
import { createFeature, reducers } from '@cca/store';
import { BotMessage, ChatMessage } from '@cca/ui';

import { executeBotAction } from './executeAction.state';
import { addBotResponse } from './message-handler/addBotResponse.state';
import { addMessage } from './message-handler/addMessage.state';
import { handleBotMessage } from './message-handler/handleBotMessage.state';
import { setCurrentSessionId } from './message-handler/setCurrentSessionId.state';
import { sendMessage } from './sendMessage.state';
import { addActiveResponseId } from './setActiveResponseIds.state';
import { startConversation } from './startConversation.state';
import { triggerErrorMessage } from './triggerErrorMessage.state';

export type HistoryMessageDeserialized = ChatMessage<MessageFields>;

export type HistoryMessage = ChatMessage<MessageFields, string>;

export interface MessagesState {
  history: HistoryMessage[];
  botResponses: {
    [responseId: string]: BotResponseWithFragments;
  };
  currentSessionId: string;
  activeResponseIds: string[];
}

export const initialMessagesState: MessagesState = {
  history: [],
  botResponses: {},
  currentSessionId: '',
  activeResponseIds: [],
};

export const { addThunks, messagesSelectors, messagesReducer } = createFeature({
  name: 'messages',
  initialState: initialMessagesState,
  reducers: {
    ...reducers<MessagesState>().createSetters(['activeResponseIds']),
    addActiveResponseId,
    addMessage,
    addBotResponse,
    setCurrentSessionId,
  },
  selectors: {
    getLastActiveResponseId: (state, props: void) =>
      last(state.activeResponseIds),
    getSerializedMessageHistory: (state, props: void) => state.history,
    getFirstMessageOfActiveResponse: (state, props: void) => {
      const activeResponseId = last(state.activeResponseIds);
      // Performance improvement:
      // We start looking for messages in the reversed order because the first
      // message of the active response will be likely found after 3-5 iterations.
      // In order to identify the first message of the active response,
      // we need to match the response ids, i.e. at positions `itemIndex` & `itemIndex - 1`.
      return findLast(
        state.history,
        (item, itemIndex) =>
          item.isBot &&
          item.dialogflowResponseId === activeResponseId &&
          (!state.history[itemIndex - 1]?.isBot ||
            (state.history[itemIndex - 1]?.isBot &&
              (
                state.history[itemIndex - 1] as BotMessage<
                  MessageFields,
                  string
                >
              )?.dialogflowResponseId !== activeResponseId)),
      ) as BotMessage<MessageFields> | undefined;
    },
  },
});
export const { messagesActions } = addThunks({
  sendMessage,
  triggerErrorMessage,
  handleBotMessage,
  executeBotAction,
  startConversation,
});
