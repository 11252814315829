import { ComponentType, LazyExoticComponent, Ref, lazy, useState } from 'react';

import { useUpdateEffect } from '@react-hookz/web';

const cachedComponentPromises: Record<
  string,
  Promise<{ default: ComponentType<any> }>
> = {};
function cacheComponentPromise(
  type: string,
  componentLoader: () => Promise<{ default: ComponentType<any> }>,
) {
  if (!cachedComponentPromises[type]) {
    cachedComponentPromises[type] = componentLoader();
  }
  return cachedComponentPromises[type];
}

const useLazyComponent = <T extends unknown | { ref: Ref<any> }>(
  componentLoader: () => Promise<{ default: ComponentType<T> }>,
  cacheId: string,
) => {
  const [lazyComponent, setLazyComponent] = useState<
    LazyExoticComponent<ComponentType<T>>
  >(lazy(() => cacheComponentPromise(cacheId, componentLoader)));

  useUpdateEffect(() => {
    setLazyComponent(
      lazy(() => cacheComponentPromise(cacheId, componentLoader)),
    );
  }, [cacheId, componentLoader]);

  return lazyComponent;
};

export default useLazyComponent;
