import {
  ComponentType,
  ForwardRefRenderFunction,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Flex } from 'theme-ui';

import { useDebouncedState } from '@react-hookz/web';

import { useClickedOutside, useEffectWithTriggers } from '@cca/util-react';

import { forwardRefWithDisplayName } from '../../hocs';
import { CloseIcon } from '../icons';
import SearchIcon from '../icons/svgs/SearchIcon';
import TextInput, {
  RelevantKeys,
  TextInputRefObject,
} from '../text-input/TextInput';

import SuggestionListItem, {
  SuggestionListItemProps,
} from './suggestion-list-item/SuggestionListItem';
import SuggestionList, {
  SuggestionListProps,
} from './suggestion-list/SuggestionList';

export type SearchInputRefObject = {
  focus: () => void;
  addEventListener: HTMLDivElement['addEventListener'];
};

type Suggestion = SuggestionListItemProps;

export type SearchInputEventType = 'click' | 'key';

export type SearchInputProps = {
  suggestions: Suggestion[];
  onSelect?: (input: string, eventType: SearchInputEventType) => void;
  onInput?: (input: string) => void;
  onClear?: () => void;
  placeholder?: string;
  requireSelection?: boolean;
  disabled?: boolean;
  SuggestionListComponent?: ComponentType<SuggestionListProps>;
  SuggestionListItemComponent?: ComponentType<SuggestionListItemProps>;
};
const SearchInput: ForwardRefRenderFunction<
  SearchInputRefObject,
  SearchInputProps
> = (
  {
    suggestions,
    onSelect = () => {},
    onInput = () => {},
    onClear = () => {},
    placeholder = '',
    requireSelection = true,
    disabled = false,
    SuggestionListComponent = SuggestionList,
    SuggestionListItemComponent = SuggestionListItem,
  }: SearchInputProps,
  ref: Ref<SearchInputRefObject>,
) => {
  const outerRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<TextInputRefObject>(null);
  const clickedOutside = useClickedOutside(outerRef);
  const dropdownTopOffset = useMemo(
    () => -26 - suggestions.length * 34,
    [suggestions.length],
  );
  const [suggestionListVisible, setSuggestionListVisible] =
    useState<boolean>(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] =
    useState<number>(0);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [inputValue, setInputValue] = useDebouncedState('', 300);

  useImperativeHandle(ref, () => {
    return {
      focus: () => textInputRef.current?.focus(),
      addEventListener: (
        type: Parameters<SearchInputRefObject['addEventListener']>[0],
        listener: Parameters<SearchInputRefObject['addEventListener']>[1],
        capture: Parameters<SearchInputRefObject['addEventListener']>[2],
      ) => {
        outerRef.current?.addEventListener(type, listener, capture);
      },
    };
  }, []);

  const selectLowerSuggestion = useCallback(() => {
    if (selectedSuggestionIndex === -1) {
      // user has not yet navigated in dropdown
      setSelectedSuggestionIndex(0);
    } else if (selectedSuggestionIndex === suggestions.length - 1) {
      // user navigates down although last element is selected
      setSelectedSuggestionIndex(0);
    } else if (
      selectedSuggestionIndex >= 0 &&
      selectedSuggestionIndex < suggestions.length - 1
    ) {
      setSelectedSuggestionIndex((index) => index + 1);
    }
  }, [selectedSuggestionIndex, suggestions.length]);

  const selectUpperSuggestion = useCallback(() => {
    if (selectedSuggestionIndex === -1) {
      // user has not yet navigated in dropdown
      setSelectedSuggestionIndex(suggestions.length - 1);
    } else if (selectedSuggestionIndex === 0) {
      // user navigates up although first element is selected
      setSelectedSuggestionIndex(suggestions.length - 1);
    } else if (
      selectedSuggestionIndex > 0 &&
      selectedSuggestionIndex <= suggestions.length - 1
    ) {
      setSelectedSuggestionIndex((index) => index - 1);
    }
  }, [selectedSuggestionIndex, suggestions.length]);

  useEffect(() => {
    if (clickedOutside) {
      onBlur();
    }
  }, [clickedOutside]);

  useEffect(() => {
    setSuggestionListVisible(isFocused && suggestions.length > 0);
  }, [isFocused, suggestions]);

  useEffect(() => {
    if (suggestionListVisible) {
      setSelectedSuggestionIndex(-1);
    }
  }, [suggestionListVisible]);

  useEffect(() => {
    // auto select first item if suggestion list opens
    if (
      selectedSuggestionIndex === -1 &&
      suggestionListVisible &&
      requireSelection
    ) {
      selectLowerSuggestion();
    }
  }, [
    selectLowerSuggestion,
    selectedSuggestionIndex,
    suggestionListVisible,
    requireSelection,
  ]);

  useEffectWithTriggers(
    () => onInputInternal(inputValue),
    [inputValue, onInputInternal],
    [inputValue],
  );

  function onInputInternal(input: string) {
    setIsFocused(true);
    onInput(input);
  }

  function onFocus() {
    setIsFocused(true);
  }

  function onBlur() {
    setIsFocused(false);
    setSuggestionListVisible(false);
    setSelectedSuggestionIndex(-1);
  }

  function onSelectInternal(input: string, eventType: SearchInputEventType) {
    onClearInternal();
    onSelect(input, eventType);
  }

  function onSubmit(input: string, eventType: SearchInputEventType) {
    onSelectInternal(input, eventType);
  }

  function onKeyDown(
    key: RelevantKeys,
    input: string,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) {
    switch (key) {
      case 'ArrowDown':
        event.preventDefault();
        selectLowerSuggestion();
        break;
      case 'ArrowUp':
        event.preventDefault();
        selectUpperSuggestion();
        break;
      case 'Enter':
        if (suggestions[selectedSuggestionIndex]) {
          onSubmit(suggestions[selectedSuggestionIndex].value, 'key');
          suggestions[selectedSuggestionIndex]?.onClick?.();
        } else if (!requireSelection && textInputRef.current?.value) {
          onSubmit(textInputRef.current?.value, 'key');
        }
        break;
      case 'Escape':
        onBlur();
        break;
      default:
        break;
    }
  }

  function onClearInternal() {
    setSuggestionListVisible(false);
    textInputRef.current?.clear();
    setIsFocused(false);
    onClear();
  }

  return (
    <Box sx={{ position: 'relative' }} ref={outerRef}>
      <TextInput
        ref={textInputRef}
        icon={<SearchIcon />}
        actionIcon={
          <Flex onClick={() => onClearInternal()}>
            <CloseIcon color="grey2" />
          </Flex>
        }
        onInput={(value) => {
          onClear(); // when user gives new input, latest suggestions have to be replaced
          setInputValue(value);
        }}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        disabled={disabled}
      />
      {suggestionListVisible && (
        <SuggestionListComponent
          sx={{
            position: 'absolute',
            left: '0',
            top: `${dropdownTopOffset}px`, // '56px' for positioning dropdown below text input
            right: '0',
            zIndex: '1',
          }}
        >
          {suggestions.map((suggestion, suggestionIndex) => (
            <SuggestionListItemComponent
              key={suggestionIndex}
              value={suggestion.value}
              icon={suggestion.icon}
              selected={selectedSuggestionIndex === suggestionIndex}
              onClick={() => {
                onSelectInternal(suggestion.value, 'click');
                suggestion.onClick?.();
              }}
              searchValue={textInputRef.current?.value ?? ''}
            />
          ))}
        </SuggestionListComponent>
      )}
    </Box>
  );
};

export default forwardRefWithDisplayName(SearchInput, 'SearchInput');
