import { Theme, ThemeUIStyleObject } from 'theme-ui';

export default {
  layout: {
    textAreaInput: {
      display: 'flex',
      flexWrap: 'wrap',
      '& textarea': {
        flex: '0 0 100%',
        fontFamily: 'body',
        fontSize: '16px',
        width: '100%',
        color: 'black',
        resize: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        outline: 0,
        lineHeight: '24px',
        paddingBottom: '8px',
        borderBottomWidth: '2px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'hrColor',
        '&:focus, &:hover': {
          borderBottomColor: 'hrHover',
        },
        margin: 0,
      },
    },
    textAreaInputHelpText: {
      flex: '0 0 100%',
      marginTop: '8px',
      padding: '4px 0',
      fontSize: '12px',
      color: 'hrHover',
    },
  },
} as Theme;

export const mmStyles: Record<string, ThemeUIStyleObject> = {
  disabled: {
    '& textarea': {
      opacity: 0.4,
      cursor: 'not-allowed',
      '&:focus, &:hover': {
        borderBottomColor: 'grey3',
      },
    },
  },
};
