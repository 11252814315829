import {
  ComponentType,
  LazyExoticComponent,
  lazy,
  useEffect,
  useState,
} from 'react';

const useDeferredComponent = <
  Props extends Record<string, unknown>,
  DeferredProps,
>(
  Component: ComponentType<Props & DeferredProps>,
  deferFunction: () => Promise<DeferredProps>,
) => {
  const [lazyComponent, setLazyComponent] = useState<
    LazyExoticComponent<ComponentType<any>> | ComponentType<any>
  >(() => () => <></>);

  useEffect(() => {
    setLazyComponent(
      lazy(() =>
        deferFunction().then((deferredProps) => ({
          default: (props: Props & DeferredProps) => (
            <Component {...props} {...deferredProps} />
          ),
        })),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return lazyComponent;
};

export default useDeferredComponent;
