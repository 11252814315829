import { DateTime } from 'luxon';
import { last } from 'ramda';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  BotResponseWithFragments,
  Message,
  ProductSearchInputMessage,
} from '@cca/chatbot-graphql-types';

import { inputActions } from '../../input';
import { messagesActions } from '../index';

import { forBot } from './addMessage.state';

export const handleBotMessage = createAsyncThunk(
  'messages/handleBotMessage',
  (
    botResponse: BotResponseWithFragments & { resetInput?: boolean },
    { dispatch },
  ) => {
    dispatch(messagesActions.setCurrentSessionId(botResponse.sessionId));
    dispatch(messagesActions.addBotResponse(botResponse));

    const dialogflowResponseId = botResponse.id;

    botResponse.messages.forEach((message) => {
      // Since we are not able to use graphql interface types yet,
      // we have to access the `scope` property (which is ensured to be present
      // in all message types) by casting each message to a random message type,
      // in this case ProductSearchInput (could be any other message type).
      if ((message as ProductSearchInputMessage).scope !== 'input') {
        dispatch(
          messagesActions.addMessage(
            forBot({
              dialogflowResponseId,
              message,
              created: DateTime.local(),
            }),
          ),
        );
      }
    });

    if (botResponse.resetInput) {
      const lastMessage = last(botResponse.messages) as Message & {
        scope: 'input';
      };
      dispatch(
        inputActions.setInput(
          lastMessage.scope === 'input' ? lastMessage : null,
        ),
      );
    }
  },
);
