import { ForwardedRef, useMemo } from 'react';
import { Box } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../hocs';
import useThemeBase from '../../theme/provider/useThemeBase';

import { mmStyles } from './TextAreaInput.theme.mm';
import { seStyles } from './TextAreaInput.theme.se';

export type TextAreaInputProps = {
  placeholder?: string;
  helpText?: string;
  rows: number;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

type Props = TextAreaInputProps;
const TextAreaInput = (
  {
    placeholder,
    helpText,
    rows,
    value,
    onChange = () => {},
    disabled = false,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const theme = useThemeBase();

  const styles = useMemo(() => (theme === 'mm' ? mmStyles : seStyles), [theme]);

  return (
    <Box
      ref={ref}
      variant="layout.textAreaInput"
      sx={disabled ? styles.disabled : {}}
    >
      <textarea
        rows={rows}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
      {helpText && <Box variant="layout.textAreaInputHelpText">{helpText}</Box>}
    </Box>
  );
};

export default forwardRefWithDisplayName(TextAreaInput, 'TextAreaInput');
