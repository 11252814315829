import './App.css';
import ChatbotFrame from './components/chatbot-frame/ChatbotFrame';
import InitialFetcher from './components/layout/InitialFetcher';

function App() {
  return (
    <InitialFetcher>
      <ChatbotFrame />
    </InitialFetcher>
  );
}

export default App;
