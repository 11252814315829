import { createListenerMiddleware as createReduxListenerMiddleware } from '@reduxjs/toolkit';

import { AnyTypedStartListening, TypedListener } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createListenerMiddleware() {
  const listenerMiddleware = createReduxListenerMiddleware();

  return {
    listenerMiddleware: listenerMiddleware.middleware,
    registerListeners: <StartListening extends AnyTypedStartListening>(
      listeners: TypedListener<StartListening>[],
    ) => registerListeners(listeners),
  };

  function registerListeners<StartListening extends AnyTypedStartListening>(
    listeners: TypedListener<StartListening>[],
  ) {
    const startListening = listenerMiddleware.startListening as StartListening;

    listeners.forEach((listener) => listener(startListening));
  }
}
