import { ForwardedRef, ReactNode, useEffect, useState } from 'react';
import { Box, Flex } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';
import { SelectIcon } from '../../icons';

export type SuggestionListItemProps = {
  value: string;
  icon?: ReactNode;
  onClick?: () => void;
  selected?: boolean;
  searchValue?: string;
};
type Props = SuggestionListItemProps;
const SuggestionListItem = (
  {
    value,
    icon,
    onClick = () => {},
    selected = false,
    searchValue = '',
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const [formattedValues, setFormattedValues] = useState<
    { value: string; highlighted?: boolean }[]
  >([]);

  useEffect(() => {
    const searchValueIndex = value
      .toLowerCase()
      .indexOf(searchValue.toLowerCase());

    if (searchValueIndex > -1) {
      setFormattedValues([
        { value: value.substr(0, searchValueIndex) },
        {
          value: value.substr(searchValueIndex, searchValue.length),
          highlighted: true,
        },
        {
          value: value.substr(searchValueIndex + searchValue.length),
        },
      ]);
    } else {
      setFormattedValues([{ value }]);
    }
  }, [searchValue, value]);

  return (
    <Flex
      ref={ref}
      sx={{
        padding: '0 12px',
        cursor: 'pointer',
        alignItems: 'center',
        ...(selected && { backgroundColor: 'grey1' }),
        '&:hover': { backgroundColor: 'grey1' },
      }}
      onClick={() => onClick()}
    >
      {icon && (
        <Flex sx={{ paddingRight: '8px', flex: '0 0 auto' }}>{icon}</Flex>
      )}
      <Box
        sx={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          flex: '0 1 auto',
          paddingRight: '8px',
        }}
      >
        {formattedValues.map((value, valueIndex) => (
          <Box
            key={valueIndex}
            sx={{
              fontWeight: value.highlighted ? 'semiBold' : 'normal',
              display: 'inline',
            }}
          >
            {value.value}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          width: '2px',
          backgroundColor: 'grey2',
          margin: '3px 0 3px auto',
          alignSelf: 'stretch',
          flex: '0 0 auto',
        }}
      />
      <Flex sx={{ paddingLeft: '10px', flex: '0 0 auto' }}>
        <SelectIcon color="grey2" />
      </Flex>
    </Flex>
  );
};

export default forwardRefWithDisplayName(
  SuggestionListItem,
  'SuggestionListItem',
);
