import { ReactNode, useMemo } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import useThemeBase from '../../../theme/provider/useThemeBase';

type Props = {
  children: ReactNode;
  mmSx?: ThemeUIStyleObject;
  seSx?: ThemeUIStyleObject;
  sx?: ThemeUIStyleObject;
};
const Themed = ({ children, mmSx = {}, seSx = {}, sx = {} }: Props) => {
  const theme = useThemeBase();

  const sxProp = useMemo(
    () => ({ ...sx, ...(theme === 'mm' ? mmSx : seSx) }),
    [sx, mmSx, seSx, theme],
  );

  return <Box sx={sxProp}>{children}</Box>;
};

export default Themed;
