import { ReactNode, useMemo } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { Position } from '@cca/util';

const styles: Record<string, ThemeUIStyleObject> = {
  right: {
    marginLeft: 'auto',
    padding: '20px 20px 20px 0',
    display: 'flex',
    alignItems: 'flex-end',
  },
  center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
  },
  left: {
    marginRight: 'auto',
    padding: '20px 0 20px 20px',
    display: 'flex',
    alignItems: 'flex-start',
  },
};

type Props = {
  position: Extract<Position, Position.Left | Position.Right | Position.Center>;
  children: ReactNode;
};
const HeaderSlot = ({ position, children }: Props) => {
  const positionMap: Record<typeof position, keyof typeof styles> = useMemo(
    () => ({
      [Position.Left]: 'left',
      [Position.Right]: 'right',
      [Position.Center]: 'center',
    }),
    [],
  );

  return <Box sx={styles[positionMap[position]]}>{children}</Box>;
};

export default HeaderSlot;
