import { RefObject, useEffect, useState } from 'react';

const useClickedOutside = <T extends Element>(ref: RefObject<T>) => {
  const [clickedOutside, setClickedOutside] = useState<boolean>(false);

  function onClick(event: MouseEvent) {
    const isClickInside =
      ref.current?.contains(event.target as Element) ?? true;
    setClickedOutside(!isClickInside);
  }

  useEffect(() => {
    document.addEventListener('click', onClick);

    return () => {
      document.removeEventListener('click', onClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return clickedOutside;
};

export default useClickedOutside;
