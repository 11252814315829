import { Theme } from 'theme-ui';

export default {
  cards: {
    primary: {
      borderWidth: '0.5px',
      borderStyle: 'solid',
      borderColor: 'grey2',
      width: '100%',
      backgroundColor: 'white',
      boxShadow: '0px 3px 3px #00000029',
      borderRadius: '4px',
      margin: 0,
      padding: 0,
    },
  },
  layout: {
    cardHeader: {
      borderBottomWidth: '0.5px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'grey2',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      alignItems: 'center',
    },
    cardBody: {
      padding: '16px 24px',
    },
  },
} as Theme;
