import { Flex } from 'theme-ui';

import { Text } from '../text';

import { OpeningTime } from './opening-times.types';

export type OpeningTimesProps = {
  openingTimes: OpeningTime[];
  openingTimeExceptionMessage?: string;
};
const OpeningTimes = ({
  openingTimes,
  openingTimeExceptionMessage,
}: OpeningTimesProps) => {
  return (
    <>
      {openingTimeExceptionMessage && (
        <Text styles={{ fontWeight: 'semiBold' }}>
          {openingTimeExceptionMessage}
        </Text>
      )}
      <Flex>
        {openingTimes.length > 0 ? (
          <>
            <Flex sx={{ flexDirection: 'column', paddingRight: '10px' }}>
              {openingTimes.map((openingTime, index) => (
                <Text key={`weekDays-${index}`}>{openingTime.weekDays}</Text>
              ))}
            </Flex>
            <Flex sx={{ flexDirection: 'column' }}>
              {openingTimes.map((openingTime, index) => (
                <Text key={`duration-${index}`}>{openingTime.duration}</Text>
              ))}
            </Flex>
          </>
        ) : (
          <>k.A.</>
        )}
      </Flex>
    </>
  );
};

export default OpeningTimes;
