import { ComponentType, ForwardedRef, ReactNode } from 'react';
import { Box, Flex, ThemeUIStyleObject, Message as UiMessage } from 'theme-ui';

import { Position } from '@cca/util';

import { forwardRefWithDisplayName } from '../../hocs';
import { BotAvatar } from '../icons';
import { Text } from '../text';
import { OnLinkClickHandler } from '../text/LinkParser';

import Sender, { SenderProps } from './Sender';

export type MessageComponentProps = {
  orientation: Extract<Position, Position.Left | Position.Right>;
  sender?: string;
  showAvatar?: boolean;
  children: ReactNode | string;
  onLinkClick?: OnLinkClickHandler;
  SenderComponent?: ComponentType<SenderProps>;
  messageSx?: ThemeUIStyleObject;
  bottomRightSlot?: ReactNode;
};
const Message = (
  {
    orientation,
    sender = '',
    children,
    showAvatar = false,
    onLinkClick = () => {},
    SenderComponent = Sender,
    messageSx,
    bottomRightSlot,
  }: MessageComponentProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Flex
      ref={ref}
      sx={{
        alignItems: 'flex-end',
        width: '100%',
        justifyContent:
          orientation === Position.Left ? 'flex-start' : 'flex-end',
      }}
    >
      {showAvatar && (
        <Flex
          sx={{
            order: orientation === Position.Left ? 0 : 1,
            paddingRight: orientation === Position.Left ? '8px' : '0',
            paddingLeft: orientation === Position.Left ? '0' : '8px',
            flex: '0 0 auto',
          }}
        >
          <BotAvatar />
        </Flex>
      )}
      <Box sx={{ flex: '0 1 auto' }}>
        <UiMessage
          variant={
            orientation === Position.Left ? 'leftMessage' : 'rightMessage'
          }
          sx={messageSx}
        >
          {typeof children === 'string' ? (
            <Text onLinkClick={onLinkClick}>{children}</Text>
          ) : (
            children
          )}
        </UiMessage>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <SenderComponent sender={sender} orientation={orientation} />
          {bottomRightSlot && (
            <Box sx={{ marginLeft: 'auto' }}>{bottomRightSlot}</Box>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default forwardRefWithDisplayName(Message, 'Message');
