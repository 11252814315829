import { createFeature } from '@cca/store';

import { submitSurvey } from './submitSurvey.state';

export interface SurveyState {}

export const initialSurveyState: SurveyState = {};

export const { addThunks, surveyReducer } = createFeature({
  name: 'survey',
  initialState: initialSurveyState,
  reducers: {},
});
export const { surveyActions } = addThunks({
  submitSurvey,
});
