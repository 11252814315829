import { DateTime, Interval } from 'luxon';

export interface OpeningTimeEntry {
  openingTime: string;
  closingTime: string;
}

export function isOpened(
  dateTime: DateTime,
  openingTime: DateTime,
  closingTime: DateTime,
): boolean {
  return Interval.fromDateTimes(openingTime, closingTime).contains(dateTime);
}

export function wasOpened(
  dateTime: DateTime,
  openingTime: DateTime,
  closingTime: DateTime,
): boolean {
  return Interval.fromDateTimes(openingTime, closingTime).contains(
    DateTime.fromObject(
      {
        hour: dateTime.hour,
        minute: dateTime.minute,
        second: dateTime.second,
      },
      { zone: openingTime.zone },
    ),
  );
}
