import { graphql } from '@cca/chatbot-graphql-types';

const outletWithDistanceFragmentGql = graphql(/* GraphQL*/ `
  fragment OutletWithDistanceFields on OutletWithDistance {
    distance
    outlet {
      id
      displayName
      url
      timezone
      address {
        city
        emailAddress
        faxNumber
        phoneNumber
        street
        streetNumber
        zipCode
      }
      geolocation {
        latitude
        longitude
      }
      openingTimes {
        weekDay
        openingTime
        closingTime
      }
      openingTimeExceptions {
        date
        description
      }
      isOpened
    }
  }
`);

export default outletWithDistanceFragmentGql;
