import { PayloadAction } from '@reduxjs/toolkit';

import { Resetters, Setters } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function reducers<State>() {
  return {
    createSetters: <StateProps extends readonly Exclude<keyof State, symbol>[]>(
      properties: StateProps,
    ) => createSetters<State, StateProps>(properties),
    createResetter: <StateProp extends Exclude<keyof State, symbol>>(
      property: StateProp,
      resetValue: State[StateProp],
    ) => createResetter<State, StateProp>(property, resetValue),
  };
}

function createSetters<
  State,
  StateProps extends readonly Exclude<keyof State, symbol>[],
>(properties: StateProps): Setters<Pick<State, (typeof properties)[number]>> {
  return properties.reduce(
    (result, property) => ({
      ...result,
      [`set${firstLetterToUpperCase(`${property}`)}`]: (
        state: State,
        action: PayloadAction<State[typeof property]>,
      ) => {
        state[property] = action.payload;
      },
    }),
    {},
  ) as Setters<Pick<State, (typeof properties)[number]>>;
}

function createResetter<State, StateProp extends Exclude<keyof State, symbol>>(
  property: StateProp,
  resetValue: State[StateProp],
): Resetters<Pick<State, StateProp>> {
  return {
    [`reset${firstLetterToUpperCase(`${property}`)}`]: (state: State) => {
      state[property] = resetValue;
    },
  } as Resetters<Pick<State, StateProp>>;
}

function firstLetterToUpperCase(text: string) {
  return `${text.charAt(0).toUpperCase()}${text.substring(1)}`;
}
