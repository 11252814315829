import { Theme } from 'theme-ui';

export default {
  messages: {
    leftMessage: {
      hyphens: 'auto',
      wordBreak: 'break-word',
      border: 'none',
      backgroundColor: 'white',
      padding: '10px 16px',
      borderRadius: 'unset',
    },
    rightMessage: {
      hyphens: 'auto',
      wordBreak: 'break-word',
      border: 'none',
      backgroundColor: 'lightOrange',
      padding: '10px 16px',
      borderRadius: 'unset',
    },
  },
} as Theme;
