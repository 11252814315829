import { Theme } from 'theme-ui';

export default {
  radii: {
    leftMessage: '12px 12px 12px 0',
    rightMessage: '12px 12px 0 12px',
  },
  messages: {
    leftMessage: {
      hyphens: 'auto',
      wordBreak: 'break-word',
      border: 'none',
      backgroundColor: 'grey1',
      padding: '10px 16px',
      borderRadius: 'leftMessage',
    },
    rightMessage: {
      hyphens: 'auto',
      wordBreak: 'break-word',
      border: 'none',
      backgroundColor: 'lightRed',
      padding: '10px 16px',
      borderRadius: 'rightMessage',
    },
  },
} as Theme;
