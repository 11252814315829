/** @jsxImportSource theme-ui */
type Props = { color?: string };
const LocationIcon = ({ color = 'iconPrimary' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.373"
      height="20.522"
      viewBox="0 0 14.373 20.522"
    >
      <g transform="translate(-8.203)">
        <path
          sx={{ fill: color }}
          d="M23.181,12A2.624,2.624,0,1,0,25.8,14.624,2.627,2.627,0,0,0,23.181,12Zm0,4.5a1.874,1.874,0,1,1,1.874-1.874A1.876,1.876,0,0,1,23.181,16.5Z"
          transform="translate(-7.724 -7.503)"
        />
        <path
          sx={{ fill: color }}
          d="M20.472,2.105a7.187,7.187,0,0,0-10.164,0,8.186,8.186,0,0,0-.676,10.1l5.758,8.316,5.75-8.3A8.189,8.189,0,0,0,20.472,2.105Zm.06,9.675L15.39,19.205l-5.15-7.437a7.4,7.4,0,0,1,.6-9.133,6.437,6.437,0,0,1,9.1,0A7.4,7.4,0,0,1,20.532,11.78Z"
          transform="translate(0 0)"
        />
      </g>
    </svg>
  );
};

export default LocationIcon;
