/** @jsxImportSource theme-ui */
import { clamp } from 'lodash';
import { ForwardedRef, useMemo, useState } from 'react';
import { Box } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../hocs';
import { WithSizeProp } from '../../types/size';
import { Star } from '../star';

export type IntegerRatingValue = 0 | 1 | 2 | 3 | 4 | 5;

export type RatingProps = WithSizeProp<{
  value?: number;
  description?: string;
  // If the value changes it will always change to a whole number
  onChange?: (value: IntegerRatingValue) => void;
  disabled?: boolean;
}>;

const Rating = (
  {
    value = 0,
    description,
    size,
    onChange = () => {},
    disabled = false,
  }: RatingProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const [hoveredStarIndex, setHoveredStarIndex] = useState(-1);

  const stars = useMemo(
    () =>
      [0, 1, 2, 3, 4].map((_, starIndex) => (
        <Star
          key={starIndex}
          size={size}
          fillPercentage={
            hoveredStarIndex < 0 ? clamp(value - starIndex, 0, 1) * 100 : 0
          }
          onMouseEnter={() => setHoveredStarIndex(starIndex)}
          onMouseLeave={() => setHoveredStarIndex(-1)}
          onClick={() => onChange((starIndex + 1) as IntegerRatingValue)}
          hovered={hoveredStarIndex >= starIndex}
          disabled={disabled}
        />
      )),
    [disabled, hoveredStarIndex, onChange, size, value],
  );

  return (
    <Box ref={ref}>
      <div sx={{ display: 'inline-flex' }}>
        {stars}
        {description && (
          <span
            sx={{
              color: 'grey4',
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: 400,
            }}
          >
            {description}
          </span>
        )}
      </div>
    </Box>
  );
};

export default forwardRefWithDisplayName(Rating, 'Rating');
