import { createAsyncThunk } from '@reduxjs/toolkit';

import { AddFeedbackSurveyMutationVariables } from '@cca/chatbot-graphql-types';

import apolloClient from '../../../services/apolloClient';

import { addFeedbackSurveyGql } from './queries.gql';

export const submitSurvey = createAsyncThunk<
  void,
  AddFeedbackSurveyMutationVariables
>('survey/submitSurvey', async ({ survey }) => {
  await apolloClient.mutate({
    mutation: addFeedbackSurveyGql,
    variables: {
      survey,
    },
  });
});
