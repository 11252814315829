import articleHeaderTheme from '../../components/article-header/ArticleHeader.theme.se';
import badgeTheme from '../../components/badge/Badge.theme.se';
import buttonTheme from '../../components/button/Button.theme.se';
import cardTheme from '../../components/card/Card.theme.se';
import faqTheme from '../../components/faq/Faq.theme.se';
import intentFeedbackTheme from '../../components/feedback/IntentFeedback.theme.se';
import frameTheme from '../../components/frame/Frame.theme.se';
import headingTheme from '../../components/heading/Heading.theme.se';
import listTheme from '../../components/list/List.theme.se';
import messageTheme from '../../components/message/Message.theme.se';
import popoverTheme from '../../components/popover/Popover.theme.se';
import productTileTheme from '../../components/product/product-tile/ProductTile.theme.se';
import searchInputTheme from '../../components/search-input/SearchInput.theme.se';
import surveyTheme from '../../components/survey/Survey.theme.se';
import textInputAreaTheme from '../../components/text-area-input/TextAreaInput.theme.se';
import textInputTheme from '../../components/text-input/TextInput.theme.se';
import textTheme from '../../components/text/Text.theme.se';
import typingIndicatorTheme from '../../components/typing-indicator/TypingIndicator.theme.se';
import { ThemeConfig } from '../types';
import composeTheme from '../util/composeTheme';

import baseTheme from './base.theme';
import fonts from './fonts';

const theme = composeTheme(
  baseTheme,
  textTheme,
  frameTheme,
  cardTheme,
  headingTheme,
  listTheme,
  badgeTheme,
  messageTheme,
  typingIndicatorTheme,
  faqTheme,
  articleHeaderTheme,
  textInputTheme,
  popoverTheme,
  searchInputTheme,
  productTileTheme,
  buttonTheme,
  textInputAreaTheme,
  intentFeedbackTheme,
  surveyTheme,
);

const themeConfig: ThemeConfig = { theme, fonts };

export default themeConfig;
