import { ForwardedRef } from 'react';

import { forwardRefWithDisplayName } from '../../../../hocs';
import { MarkerIcon } from '../../../icons';
import { StructuredEntry } from '../../../structured-content';

export type AddressEntryProps = {
  address: string;
};

type Props = AddressEntryProps;

const AddressEntry = (
  { address }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => (
  <StructuredEntry ref={ref} icon={<MarkerIcon color="grey3" />}>
    {address}
  </StructuredEntry>
);

export default forwardRefWithDisplayName(AddressEntry, 'AddressEntry');
