import {
  ComponentType,
  ForwardedRef,
  RefAttributes,
  forwardRef,
  useCallback,
} from 'react';

import { setForwardedRef } from '@cca/util-react';

import useClickTrackingContext from './useClickTrackingContext';

export const withUiComponentClickTracking = <P,>(
  Component: ComponentType<P>,
): ComponentType<P & RefAttributes<HTMLElement>> => {
  return forwardRef((props: P, ref: ForwardedRef<HTMLElement>) => {
    const context = useClickTrackingContext();

    const onClick = useCallback(() => {
      context.uiComponent(Component.displayName ?? '<no-name>');
    }, [context]);

    const refAvailable = useCallback(
      (current: HTMLElement | null) => {
        if (current) {
          current.addEventListener('click', onClick);
          setForwardedRef(ref, current);
        }
      },
      // Do not depend on `currentValue` and `setCurrentValue` since the idea
      // behind `useState` is to prevent updates!
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    return <Component ref={refAvailable} {...props} />;
    // TODO: Conversion from `ForwardRefExoticComponent` to `ComponentType` was
    //  always possible. Suddenly a type issue prevents the conversion. Therefore,
    //  we have to enforce it via an `unknown` cast. Fix in future!
  }) as unknown as ComponentType<P & RefAttributes<HTMLElement>>;
};

const withUiComponentClickTrackingFactory = <
  Components extends ComponentType<any>[],
>(
  components: Components,
): Components => components.map(withUiComponentClickTracking) as Components;

export default withUiComponentClickTrackingFactory;
