import { ForwardedRef } from 'react';

import { forwardRefWithDisplayName } from '../../../../hocs';
import { Badge } from '../../../badge';
import { Status } from '../../../status';
import { StructuredStartEntry } from '../../../structured-content';

export type CityEntryProps = {
  distance: number;
  city: string;
  showOutletStatus: boolean;
  isOpened: boolean;
  openedLabel: string;
  closedLabel: string;
};

type Props = CityEntryProps;

const CityEntry = (
  {
    distance,
    city,
    showOutletStatus,
    isOpened,
    openedLabel,
    closedLabel,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => (
  <StructuredStartEntry
    customRef={ref}
    left={<Badge>{distance.toString()} km</Badge>}
    main={city}
    right={
      showOutletStatus && (
        <Status color={isOpened ? 'green' : 'orange'}>
          {isOpened ? openedLabel : closedLabel}
        </Status>
      )
    }
  />
);

export default forwardRefWithDisplayName(CityEntry, 'CityEntry');
