import { graphql } from '@cca/chatbot-graphql-types';

const faqCardFragmentGql = graphql(/* GraphQL*/ `
  fragment FaqCardMessageFields on FaqCardMessage {
    id
    scope
    type
    serviceSite {
      ...ServiceSiteFields
    }
    faqWithScore {
      ...FaqWithScoreFields
    }
  }
`);

export default faqCardFragmentGql;
