/** @jsxImportSource theme-ui */
import { motion, useAnimation } from 'framer-motion';
import {
  ForwardRefRenderFunction,
  ReactNode,
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { useValue } from '@cca/util-react';

import {
  hideSlide,
  showSlide,
  slideHeight,
  slideHeightChanged,
} from './card-slider-utils';
import { CardSlideRefObject } from './card-slider.types';

type Props = {
  children: ReactNode;
  onReady?: () => void;
};
const CardPrimarySlide: ForwardRefRenderFunction<CardSlideRefObject, Props> = (
  { children, onReady = () => {} }: Props,
  ref: Ref<CardSlideRefObject>,
) => {
  const animation = useAnimation();
  const divRef = useRef<HTMLDivElement>(null);
  const [isHighestSlide, setIsHighestSlide] = useValue(false);

  useImperativeHandle(ref, () => ({
    animation,
    height: slideHeight(divRef),
    markAsHighestSlide: () => setIsHighestSlide(true),
    unmarkAsHighestSlide: () => setIsHighestSlide(false),
    show: showSlide(animation, isHighestSlide),
    hide: hideSlide(animation, isHighestSlide),
    heightChanged: slideHeightChanged(divRef),
  }));

  return (
    <motion.div
      ref={divRef}
      sx={{
        width: '100%',
        flex: '1 0 auto',
      }}
      initial="visible"
      variants={{
        hidden: { x: '-100%' },
        visible: { x: '0%' },
        shrunk: { height: 0 },
        grown: { height: 'auto' },
      }}
      animate={animation}
      transition={{
        duration: 0.5,
      }}
    >
      {children}
    </motion.div>
  );
};

export default forwardRef(CardPrimarySlide);
