import { ComponentType, lazy, useMemo } from 'react';
import { Suspense } from 'react';

import { UiTheme } from '../../..';
import useTheme from '../../../theme/provider/useTheme';

import { PhoneEntryProps } from './entries/PhoneEntry';

const determineContent = async (theme: UiTheme) => {
  return theme === 'mmes'
    ? import('./ContactCardContent.mmes')
    : import('./ContactCardContent.default');
};

export type ContactCardContentProps = {
  showIndicator: boolean;
  isAvailable: boolean;
  availableLabel: string;
  notAvailableLabel: string;
  callInvitationLabel: string;
  contactFormLabel: string;
  contactFormLink: string;
  showPhoneNumber: boolean;
} & PhoneEntryProps;
const ContactCardContent = (props: ContactCardContentProps) => {
  const theme = useTheme();
  const SelectedCardComponent = useMemo(
    () =>
      lazy<ComponentType<ContactCardContentProps>>(() =>
        determineContent(theme),
      ),
    [theme],
  );

  return (
    <Suspense fallback="">
      <SelectedCardComponent {...props} />
    </Suspense>
  );
};

export default ContactCardContent;
