import { graphql } from '@cca/chatbot-graphql-types';

const productFragmentGql = graphql(/* GraphQL*/ `
  fragment ProductFields on Product {
    id
    name
    url
    imageUrl
    onlineAvailability
    energyEfficiencyClass
    energyEfficiencyClassEu2017_1369
    price {
      salesPrice
      strikePrice
      strikePriceType
      currency
      recommendedRetailPrice
    }
    basePrice {
      baseInfo
      unitPrice
    }
    mainFeatures {
      name
      value
    }
    rating {
      averageRating
      ratingsCount
    }
  }
`);

export default productFragmentGql;
