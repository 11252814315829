import { Box } from 'theme-ui';

import { PriceParts } from './util';

export type FormattedPriceProps = {
  prefix?: string;
  crossedOut?: boolean;
} & PriceParts;
const FormattedPrice = ({
  first,
  last,
  prefix,
  crossedOut = false,
}: FormattedPriceProps) => (
  <Box
    sx={{
      fontWeight: 'semiBold',
    }}
  >
    <Box sx={{ display: 'inline', fontSize: '20px' }}>
      {prefix && `${prefix} `}
      <Box
        sx={{
          display: 'inline',
          position: 'relative',
          ...(crossedOut && {
            '&:after': {
              content: "''",
              position: 'absolute',
              left: 0,
              width: '100%',
              pointerEvents: 'none',
              top: 'calc(50% - 2px)',
              height: '2px',
              borderTop: '2px solid',
            },
          }),
        }}
      >
        {first}
      </Box>
    </Box>
    <Box
      sx={{
        display: 'inline',
        fontSize: '15px',
        position: 'relative',
        top: '-3px',
        verticalAlign: 'baseline',
      }}
    >
      {last}
    </Box>
  </Box>
);

export default FormattedPrice;
