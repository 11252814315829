import { createFeature } from '@cca/store';

import { addFlowContextLabelByMessageId } from './addFlowContextLabelByMessageId.state';
import { submitFlowFeedback } from './submitFlowFeedback.state';
import { submitIntentFeedback } from './submitIntentFeedback.state';

export interface FeedbackState {
  flowContextLabelsByMessageId: Record<string, string>;
}

export const initialFeedbackState: FeedbackState = {
  flowContextLabelsByMessageId: {},
};

export const { addThunks, feedbackReducer } = createFeature({
  name: 'feedback',
  initialState: initialFeedbackState,
  reducers: {
    addFlowContextLabelByMessageId,
  },
});
export const { feedbackActions } = addThunks({
  submitFlowFeedback,
  submitIntentFeedback,
});
