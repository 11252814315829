import { createAsyncThunk } from '@reduxjs/toolkit';

import { AddFlowFeedbackMutationVariables } from '@cca/chatbot-graphql-types';

import apolloClient from '../../../services/apolloClient';

import { addFlowFeedbackGql } from './queries.gql';

export const submitFlowFeedback = createAsyncThunk<
  void,
  AddFlowFeedbackMutationVariables
>('feedback/submitFlowFeedback', async ({ feedback }) => {
  await apolloClient.mutate({
    mutation: addFlowFeedbackGql,
    variables: {
      feedback,
    },
  });
});
