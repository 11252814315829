import { ReactNode } from 'react';
import { Flex } from 'theme-ui';

type Props = {
  children: ReactNode;
};
const Frame = ({ children }: Props) => {
  return <Flex variant="layout.frame">{children}</Flex>;
};

export default Frame;
