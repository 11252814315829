import { Message } from '@cca/chatbot-graphql-types';
import { createFeature, reducers } from '@cca/store';

import { focusInput } from './focusInput.state';

export interface InputState {
  input: (Message & { scope: 'input' }) | null;
  inputFocusTriggered: number;
  showTypingIndicator: boolean;
}

export const initialInputState: InputState = {
  input: null,
  inputFocusTriggered: 0,
  showTypingIndicator: false,
};

export const { inputReducer, inputActions } = createFeature({
  name: 'input',
  initialState: initialInputState,
  reducers: {
    ...reducers<InputState>().createSetters(['input', 'showTypingIndicator']),
    focusInput,
  },
});
