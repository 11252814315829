// primitive colors
export const grey1 = '#f2f3f4';
export const grey2 = '#c9cccd';
export const grey3 = '#8b8f91';
export const grey4 = '#454647';
export const grey5 = '#242424';
export const red = '#df0000';
// export const lightRed = '#ffc0cb';
export const lightOrange = '#ffd7ad';
export const black = '#000';
export const white = '#fff';
export const green = '#70c118';
export const orange = '#ffa500';
export const primaryHover = '#db7100';

// predefined ui-theme color variables
export const text = grey5;
export const background = 'transparent';
export const primary = '#ef7c00';
export const secondary = '#009fe3';
export const accent = red;
export const highlight = red;
export const muted = '#f6f6f6';

// custom theme colors
export const iconPrimary = grey5;
// TODO: look if there is any other secondary color defined for icons for mms
export const iconSecondary = secondary;
