import { Theme } from 'theme-ui';

export default {
  layout: {
    articleHeader: {
      '::before': {
        content: '" "',
        position: 'absolute',
        width: '100%',
        height: '50%',
        top: '0',
        right: '-50px',
        zIndex: -1,
        transform: 'skew(25deg)',
        backgroundColor: 'white',
      },

      '::after': {
        content: '" "',
        position: 'absolute',
        width: '100%',
        height: '50%',
        top: '50%',
        right: '-50px',
        zIndex: -1,
        transform: 'skew(-25deg)',
        boxShadow: 'rgba(0, 0, 0, 0.07) 8px 8px 8px 0px',
        backgroundColor: 'white',
      },
    },
  },
} as Theme;
