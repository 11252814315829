import { UiTheme } from '../types';

import useTheme from './useTheme';

const useThemeBase = () => {
  const mapping: Record<UiTheme, Exclude<UiTheme, 'mmes'>> = {
    mm: 'mm',
    mmes: 'mm',
    se: 'se',
  };

  const theme = useTheme();
  return mapping[theme];
};

export default useThemeBase;
