import { useMemo } from 'react';
import { Box } from 'theme-ui';

type Props = {
  grade: CCA.GraphQLEnergyEfficiencyClass;
};

const GradeWithPlus = ({ grade }: Props) => {
  const plusPosition = useMemo(() => grade.indexOf('+'), [grade]);
  return (
    <Box>
      <Box sx={{ display: 'inline' }}>{grade.substring(0, plusPosition)}</Box>
      <Box
        sx={{
          display: 'inline',
          position: 'relative',
          top: '-3px',
          fontSize: '19px',
        }}
      >
        {grade.substring(plusPosition)}
      </Box>
    </Box>
  );
};

export default GradeWithPlus;
