import { PayloadAction } from '@reduxjs/toolkit';

import { MessagesState } from '../index';

export function setCurrentSessionId(
  state: MessagesState,
  action: PayloadAction<string>,
) {
  state.currentSessionId = action.payload;
}
