import { useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Box, Container } from 'theme-ui';

import ThemeContext from '../../../theme/provider/ThemeContext';
import LoadingIndicator from '../loading-indicator/LoadingIndicator';

type Props = {
  visible?: boolean;
};
const LoadingOverlay = ({ visible = false }: Props) => {
  const { portal } = useContext(ThemeContext);

  const overlay = useMemo(
    () =>
      createPortal(
        <Container
          sx={{
            position: 'absolute',
            left: '0',
            top: '0',
            right: '0',
            bottom: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '99999',
          }}
        >
          <Box sx={{ position: 'relative', width: '60px', height: '60px' }}>
            <LoadingIndicator size={'md'} />
          </Box>
        </Container>,
        portal,
      ),
    [portal],
  );

  return <>{visible ? overlay : null}</>;
};

export default LoadingOverlay;
