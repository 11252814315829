import { BaseParserProps } from './text.types';

export interface MarkOptions extends BaseParserProps {}

type Props = MarkOptions;
const MarkParser = ({ children }: Props) => {
  return <mark>{children}</mark>;
};

export default MarkParser;
