/** @jsxImportSource theme-ui */
type Props = { color?: string; height?: number };
const MoreFeedbackIcon = ({ color = 'iconPrimary', height = 24 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox="0 0 24 24"
      stroke="#000"
    >
      <g
        id="Gruppe_3345"
        data-name="Gruppe 3345"
        transform="translate(-12127 13484)"
      >
        <path
          id="Pfad_3295"
          data-name="Pfad 3295"
          className="cls-2"
          d="M7.725,18H6a3,3,0,0,1-3-3V7A3,3,0,0,1,6,4H18a3,3,0,0,1,3,3v5.472"
          transform="translate(12127 -13485)"
          sx={{
            fill: 'none',
            stroke: color,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px',
          }}
        />
        <path
          id="Pfad_3296"
          data-name="Pfad 3296"
          className="cls-2"
          d="M17.8,20.817l-2.172,1.138a.392.392,0,0,1-.568-.41l.415-2.411-1.757-1.707a.389.389,0,0,1,.217-.665l2.428-.352,1.086-2.193a.392.392,0,0,1,.7,0l1.086,2.193,2.428.352a.39.39,0,0,1,.217.665l-1.757,1.707.414,2.41a.39.39,0,0,1-.567.411Z"
          transform="translate(12127 -13485)"
          fill="#fff"
          stroke={color}
        />
        <path
          id="Pfad_3297"
          data-name="Pfad 3297"
          className="cls-2"
          d="M8,9H18.2"
          transform="translate(12125.9 -13485)"
          stroke={color}
        />
        <path
          id="Pfad_3298"
          data-name="Pfad 3298"
          className="cls-2"
          d="M8,13h5.92"
          transform="translate(12126 -13485)"
          stroke={color}
        />
      </g>
    </svg>
  );
};

export default MoreFeedbackIcon;
