import capitalize from 'lodash/capitalize';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import { MarkOptional } from 'ts-essentials';

import {
  AsyncThunk,
  CreateSliceOptions,
  Selector,
  SliceCaseReducers,
  UnsubscribeListener,
  createSlice,
} from '@reduxjs/toolkit';

import { AnyTypedStartListening } from '../listener';

import {
  EffectsWithPrefix,
  FeatureState,
  FeatureWithPrefix,
  FeatureWithPrefixAndThunks,
  FeatureWithoutPrefix,
  ReducerObject,
  RootStateSelectors,
  SelectorsObject,
  StateSelectors,
} from './types';

function createSelectors<State>() {
  return <
    Selectors extends StateSelectors<State>,
    Name extends string = string,
  >(
    name: Name,
    selectors: Selectors,
  ): RootStateSelectors<State, Selectors, Name> => {
    const mainSelector: Selector<FeatureState<State, Name>, State> = (
      rootState,
    ) => rootState[name];

    return {
      getState: mainSelector,
      ...mapValues(
        selectors,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (selector) => (rootState: FeatureState<State, Name>, props: any) =>
          selector(mainSelector(rootState), props),
      ),
    };
  };
}

export function createFeature<
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Selectors extends StateSelectors<State>,
  Name extends string = string,
>(
  options: CreateSliceOptions<State, CaseReducers, Name> &
    MarkOptional<SelectorsObject<State, Selectors>, 'selectors'>,
): FeatureWithPrefix<State, CaseReducers, Selectors, Name> & {
  addThunks: <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ThunksMap extends Record<string, AsyncThunk<any, any, Record<string, any>>>,
  >(
    thunks: ThunksMap,
  ) => FeatureWithPrefixAndThunks<
    ThunksMap,
    State,
    CaseReducers,
    Selectors,
    Name
  >;
} & {
  addEffects: <
    EffectsMap extends Record<
      string,
      (startListening: AnyTypedStartListening) => UnsubscribeListener
    >,
  >(
    effects: EffectsMap,
  ) => EffectsWithPrefix<EffectsMap, Name>;
} {
  const slice = createSlice(options);
  const reducedSlice: FeatureWithoutPrefix<
    State,
    CaseReducers,
    Selectors,
    Name
  > = {
    ...slice,
    selectors: createSelectors<State>()(
      options.name,
      options?.selectors ?? ({} as Selectors),
    ),
    // { [slice.name]: slice.reducer } resolves to { [x: string]: typeof slice.reducer }
    reducer: { [slice.name]: slice.reducer } as ReducerObject<
      State,
      CaseReducers,
      Name
    >['reducer'],
  };
  const prefixedSlice = {
    ...mapKeys(reducedSlice, (value, key) => `${slice.name}${capitalize(key)}`),
    name: reducedSlice.name,
  } as FeatureWithPrefix<State, CaseReducers, Selectors, Name>;

  return {
    ...prefixedSlice,
    addThunks(thunks) {
      const enhancedSlice = {
        ...reducedSlice,
        actions: {
          ...reducedSlice.actions,
          ...thunks,
        },
      };

      return {
        ...(mapKeys(
          enhancedSlice,
          (value, key) => `${slice.name}${capitalize(key)}`,
        ) as FeatureWithPrefixAndThunks<
          typeof thunks,
          State,
          CaseReducers,
          Selectors,
          Name
        >),
        name: enhancedSlice.name,
      };
    },
    addEffects(effects) {
      const listener = {
        listener: (startListening: AnyTypedStartListening) => {
          Object.values(effects).forEach((effect) => effect(startListening));
        },
      };

      return {
        ...(mapKeys(
          listener,
          (value, key) => `${slice.name}${capitalize(key)}`,
        ) as EffectsWithPrefix<typeof effects, Name>),
        name: prefixedSlice.name,
      };
    },
  };
}
