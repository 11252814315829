import GraphQLBackend from './graphqlBackendPlugin';
import { InitI18nProps } from './types';
import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';

// import LanguageDetector from 'i18next-browser-languagedetector';

export function initI18n({
  apolloClient,
  debug,
  language,
}: InitI18nProps): Promise<TFunction> {
  return (
    i18n
      // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-http-backend
      .use(GraphQLBackend)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      // .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        fallbackLng: 'en',
        lng: language,
        load: 'all',
        debug,

        backend: {
          apolloClient,
          debug,
        },

        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      })
  );
}

export function i18nIsInitialized(): boolean {
  return i18n.isInitialized;
}

export const translate: TFunction = i18n.t.bind(i18n);
