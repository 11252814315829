import { Spinner } from 'theme-ui';

import { WithSizeProp } from '../../../types/size';
import { sizes } from '../../../util/sizes';

const getSize = sizes({ sm: 20, md: 60 });

type Props = WithSizeProp<{}>;

const LoadingIndicator = ({ size }: Props) => {
  return <Spinner size={getSize(size)} />;
};

export default LoadingIndicator;
