/** @jsxImportSource theme-ui */
import useThemeBase from '../../../theme/provider/useThemeBase';

type Props = { color?: string };
const MinimizeIcon = ({ color = 'iconPrimary' }: Props) => {
  const theme = useThemeBase();

  const mmIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="3"
      viewBox="0 0 23 3"
    >
      <rect sx={{ fill: color }} width="23" height="3" rx="1" />
    </svg>
  );
  const seIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="3"
      viewBox="0 0 23 3"
    >
      <rect sx={{ fill: color }} width="23" height="3" />
    </svg>
  );

  return theme === 'mm' ? mmIcon : seIcon;
};

export default MinimizeIcon;
