import { ReactNode } from 'react';

import { useDeferredComponent } from '@cca/util-react';

import { initI18n } from './init';
import { InitI18nProps } from './types';

const I18nLoader = ({
  children,
  ...initI18nProps
}: InitI18nProps & { children: ReactNode }) => {
  const DeferredChildren = useDeferredComponent(
    () => <>{children}</>,
    () => initI18n(initI18nProps),
  );

  return <DeferredChildren />;
};

export default I18nLoader;
