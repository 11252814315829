import { DependencyList, RefObject, useCallback, useState } from 'react';

const useRefCallback = <T,>(
  callback: (refObject: T) => void,
  deps: DependencyList,
): [RefObject<T>, (refObject: T) => void] => {
  const [ref] = useState<{ current: T | null }>({ current: null });

  const setRef = useCallback(
    (refObject: T) => {
      ref.current = refObject;
      callback(refObject);
    },
    // We need to forward the deps which is not covered by following eslint rule:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, ref, ...deps],
  );

  return [ref as RefObject<T>, setRef];
};

export default useRefCallback;
