import isEmpty from 'lodash/isEmpty';
import { ReactNode, useEffect } from 'react';

import { useAppDispatch, useAppStateSelector } from '../../store';
import { messagesActions } from '../../store/actions/messages';
import { userAgentActions } from '../../store/actions/user-agent';

type Props = { children: ReactNode };
const InitialFetcher = ({ children }: Props) => {
  const dispatch = useAppDispatch();

  const sessionId = useAppStateSelector(
    (state) => state.messages.currentSessionId,
  );

  useEffect(() => {
    if (!isEmpty(sessionId)) {
      dispatch(userAgentActions.submitUserAgent(sessionId));
    }
  }, [dispatch, sessionId]);

  useEffect(() => {
    const thunk = dispatch(messagesActions.startConversation());
    // https://beta.reactjs.org/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
    return () => {
      thunk.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default InitialFetcher;
