import { Box } from 'theme-ui';

import { Heading } from '../heading';

export type ArticleHeaderProps = {
  label: string;
};
const ArticleHeader = ({ label }: ArticleHeaderProps) => {
  return (
    <Box
      sx={{
        minWidth: '130px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          variant: 'layout.articleHeader',
          width: 'calc(100% - 61px)',
          boxSizing: 'border-box',
          position: 'relative',
          zIndex: 1,
          backgroundColor: 'white',
          boxShadow: '8px 0 8px 8px rgba(0, 0, 0, 0.07)',

          // orig value is padding: 24px => too big for our little chatbot
          paddingLeft: '20px',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
      >
        <Heading variant="heading2">{label}</Heading>
      </Box>
    </Box>
  );
};

export default ArticleHeader;
