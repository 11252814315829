import { Theme } from 'theme-ui';

export default {
  layout: {
    articleHeader: {
      borderRadius: '4px',

      '::after': {
        content: '" "',
        position: 'absolute',
        width: '100%',
        top: 0,
        zIndex: -1,
        //   ${media.sm} {
        //   display: block;
        // }

        transform: 'skew(-19.7deg)',
        height: '100%',
        right: '-50px',
        borderTopRightRadius: `${160 / 25}px`,
        // orig value: `${160 / 5}px` => the header is not high enough to have such a big radius on the bottom
        borderBottomRightRadius: `${160 / 10}px`,
        boxShadow: '8px 0 8px 0 rgba(0, 0, 0, 0.07)',
        background: 'white',
      },
    },
  },
} as Theme;
