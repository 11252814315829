import { ForwardedRef, useMemo } from 'react';
import { Box } from 'theme-ui';

import {
  OnlineAvailability,
  ProductFieldsFragment,
} from '@cca/chatbot-graphql-types';

import { forwardRefWithDisplayName } from '../../../hocs';
import { CircleIcon } from '../../icons';
import { OnlineAvailabilityState, StatusColors } from '../types';

export type ProductAvailabilityProps = {
  availableLabel: string;
  soonAvailableLabel: string;
  preorderLabel: string;
  notAvailableLabel: string;
  onlineAvailability: ProductFieldsFragment['onlineAvailability'];
};

type Props = ProductAvailabilityProps;
const ProductAvailability = (
  {
    availableLabel,
    preorderLabel,
    soonAvailableLabel,
    notAvailableLabel,
    onlineAvailability,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const onlineStates = useMemo(
    () =>
      new Map<boolean, OnlineAvailabilityState>([
        [
          onlineAvailability === OnlineAvailability.Available,
          { status: 'AVAILABLE', label: availableLabel },
        ],
        [
          onlineAvailability === OnlineAvailability.SoonAvailable,
          { status: 'PARTIAL_AVAILABLE', label: soonAvailableLabel },
        ],
        [
          onlineAvailability === OnlineAvailability.Preorder,
          { status: 'PARTIAL_AVAILABLE', label: preorderLabel },
        ],
        [
          onlineAvailability === OnlineAvailability.NotAvailable,
          { status: 'NOT_AVAILABLE', label: notAvailableLabel },
        ],
      ]),
    [
      availableLabel,
      notAvailableLabel,
      preorderLabel,
      onlineAvailability,
      soonAvailableLabel,
    ],
  );

  const onlineState = useMemo<OnlineAvailabilityState>(
    () =>
      onlineStates.get(true) || {
        status: 'NOT_AVAILABLE',
        label: notAvailableLabel,
      },
    [notAvailableLabel, onlineStates],
  );

  return (
    <Box
      ref={ref}
      sx={{ display: 'flex', justifyContent: 'center', fontSize: '12px' }}
    >
      <Box sx={{ marginRight: '8px' }}>
        <CircleIcon color={StatusColors[onlineState.status]} />
      </Box>
      <Box sx={{ fontWeight: 'semiBold', color: 'grey5' }}>
        {onlineState.label}
      </Box>
    </Box>
  );
};

export default forwardRefWithDisplayName(
  ProductAvailability,
  'ProductAvailability',
);
