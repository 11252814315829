import { ReactNode } from 'react';
import { Link } from 'theme-ui';

export type OnLinkClickHandler = (data: OnLinkClickHandlerParams) => void;

type Node = TextNode | UnknownNode;

type TextNode = {
  type: 'text';
  value: string;
};

type UnknownNode = {
  type: unknown;
};

function isTextNode(node: Node): node is TextNode {
  return node.type === 'text';
}

export interface LinkOptions {
  children: ReactNode;
  href?: string;
  target?: string;
  onLinkClick?: OnLinkClickHandler;
  node: {
    children: Node[];
  };
}

// export type UrlPayload = { [actionType: string]: string[] };

export interface OnLinkClickHandlerParams {
  title: string;
  url: string;
  // payload: UrlPayload;
}

type Props = LinkOptions;
const LinkParser = ({
  children,
  href = '',
  target = '',
  onLinkClick = () => {},
  node,
}: Props) => {
  return (
    <Link
      href={href}
      target={target}
      onClick={() => {
        const firstChild = node.children[0];

        onLinkClick({
          title: isTextNode(firstChild) ? firstChild.value : '<no-title>',
          url: href,
        });

        // Archive internal link logic which we currently do not use:
        //
        // const url = new URL(href);
        // if (url.host === 'internal.link') {
        //   event.preventDefault();
        //   // parse the query params of the url
        //   const urlPayload: UrlPayload = Array.from(
        //     url.searchParams.keys(),
        //   ).reduce(
        //     (data, key) => ({ ...data, [key]: url.searchParams.getAll(key) }),
        //     {},
        //   );
        //
        //   onLinkClick?.({
        //     payload: urlPayload,
        //   });
        // }
      }}
    >
      {children}
    </Link>
  );
};

export default LinkParser;
