/** @jsxImportSource theme-ui */
import { useThemeUI } from 'theme-ui';

import { WithSizeProp } from '../../../types/size';
import { sizes } from '../../../util/sizes';

const widthOrHeight = sizes({ sm: '16px', md: '24px' });

type Props = WithSizeProp<{
  color?: string;
  backgroundColor: string;
  fillPercentage?: number;
}>;

const StarIcon = ({
  color = 'primary',
  backgroundColor = 'grey2',
  size,
  fillPercentage = 0,
}: Props) => {
  const { theme } = useThemeUI();
  // TODO:
  //  This is not the best approach as it does not avoid duplicate ids.
  //  It only works because a duplicate id e.g. `ratingStar-50` will result in the same 50% filled star
  //  even for the svgs that use that duplicate id (instead of a new unique one)
  const id = 'ratingStar-' + fillPercentage;
  return (
    <svg
      sx={{
        marginRight: '4px',
        width: widthOrHeight(size),
        height: widthOrHeight(size),
      }}
      viewBox="0 0 24 24"
    >
      <linearGradient id={id}>
        <stop
          offset={`${fillPercentage}%`}
          stopColor={`${theme.colors && theme.colors[color]}`}
        />
        <stop
          offset={`${fillPercentage}%`}
          stopColor={`${theme.colors && theme.colors[backgroundColor]}`}
        />
      </linearGradient>

      <path
        fill={'url(#' + id + ')'}
        d="M2.18,9.86A.49.49,0,0,1,2,9.32l.08-.25a.49.49,0,0,1,.44-.35l6.39-.51,2.45-5.89A.51.51,0,0,1,11.87,2h.26a.49.49,0,0,1,.47.32l2.46,5.89,6.39.51a.49.49,0,0,1,.44.35l.08.25a.49.49,0,0,1-.15.54L17,14l1.48,6.21a.51.51,0,0,1-.19.53L18,20.9a.5.5,0,0,1-.56,0L12,17.6,6.53,20.93a.5.5,0,0,1-.56,0l-.22-.15a.51.51,0,0,1-.19-.53L7,14Z"
      ></path>
    </svg>
  );
};

export default StarIcon;
