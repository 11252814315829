import {
  Children,
  ReactElement,
  ReactNode,
  RefObject,
  cloneElement,
  createContext,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Flex } from 'theme-ui';

import { useMountEffect } from '@react-hookz/web';

import { CardPrimarySlide, CardSecondarySlide } from '../index';

import { CardSlideRefObject } from './card-slider.types';

interface Animation {
  hide: () => void;
  show: () => void;
}

export interface CardSliderContextParams<T> {
  data: T;
  switchToSecondarySlide: (data: T) => void;
  switchToPrimarySlide: () => void;
}

type Props<T> = {
  children: (params: CardSliderContextParams<T>) => ReactNode;
};

const useSlide = (
  children: ReactElement[],
  element: typeof CardPrimarySlide | typeof CardSecondarySlide,
): [RefObject<CardSlideRefObject>, ReactElement | null] => {
  const ref = useRef<CardSlideRefObject>(null);
  const slide = Children.toArray(children).find(
    (child) => (child as ReactElement).type === element,
  );
  if (slide) {
    const element = cloneElement(slide as ReactElement, {
      ref,
    });
    return [ref, element];
  }
  throw new Error(`[useSlide] ReactElement ${element} not existing`);
};

const useCardSlider = <T,>(defaultData: T) => {
  const params: CardSliderContextParams<T> = {
    data: defaultData,
    switchToSecondarySlide: () => {},
    switchToPrimarySlide: () => {},
  };

  const CardSliderContext = createContext(params);

  type CardSlideContainerProps = {
    children: ReactElement[];
  };
  const CardSlideContainer = forwardRef(
    ({ children }: CardSlideContainerProps, ref) => {
      const [primarySlideRef, primarySlide] = useSlide(
        children,
        CardPrimarySlide,
      );
      const [secondarySlideRef, secondarySlide] = useSlide(
        children,
        CardSecondarySlide,
      );

      useImperativeHandle(
        ref,
        () => ({
          primarySlideAnimation: {
            show: () => primarySlideRef.current?.show(),
            hide: () => primarySlideRef.current?.hide(),
          },
          secondarySlideAnimation: {
            show: () => secondarySlideRef.current?.show(),
            hide: () => secondarySlideRef.current?.hide(),
          },
        }),
        [primarySlideRef, secondarySlideRef],
      );

      const markHighestSlide = useCallback(() => {
        if (
          (primarySlideRef.current?.height() ?? 0) >=
          (secondarySlideRef.current?.height() ?? 0)
        ) {
          primarySlideRef.current?.markAsHighestSlide();
          secondarySlideRef.current?.unmarkAsHighestSlide();
        } else {
          primarySlideRef.current?.unmarkAsHighestSlide();
          secondarySlideRef.current?.markAsHighestSlide();
        }
        primarySlideRef.current?.show(true);
        secondarySlideRef.current?.hide(true);
      }, [primarySlideRef, secondarySlideRef]);

      useMountEffect(() => {
        markHighestSlide();
        primarySlideRef.current?.heightChanged(() => markHighestSlide());
        secondarySlideRef.current?.heightChanged(() => markHighestSlide());
      });

      return (
        <>
          {primarySlide}
          {secondarySlide}
        </>
      );
    },
  );

  const CardSlider = ({ children }: Props<T>) => {
    const [data, setData] = useState<T>(defaultData);

    const containerRef = useRef<{
      primarySlideAnimation: Animation;
      secondarySlideAnimation: Animation;
    }>();
    const switchToSecondarySlide = useCallback((data: T) => {
      setData(data);
      containerRef.current?.primarySlideAnimation.hide();
      containerRef.current?.secondarySlideAnimation.show();
    }, []);
    const switchToPrimarySlide = useCallback(() => {
      containerRef.current?.primarySlideAnimation.show();
      containerRef.current?.secondarySlideAnimation.hide();
    }, []);

    const populatedContext = {
      data,
      switchToPrimarySlide,
      switchToSecondarySlide,
    };

    const renderedChildren = children(populatedContext);

    const container = Children.toArray(renderedChildren).find(
      (child) => (child as ReactElement).type === CardSlideContainer,
    );
    if (!container) {
      throw new Error(
        '[CardSlider] Mandatory child element `CardSlideContainer` not existing',
      );
    }

    const changedContainer = cloneElement(container as ReactElement, {
      ref: containerRef,
    });

    return (
      <CardSliderContext.Provider value={populatedContext}>
        <Flex sx={{ flexWrap: 'nowrap' }}>{changedContainer}</Flex>
      </CardSliderContext.Provider>
    );
  };

  return { CardSlider, CardSlideContainer };
};

export default useCardSlider;
