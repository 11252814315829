import { Theme } from 'theme-ui';

export default {
  text: {
    heading: {
      variant: 'layout.prominentFix',
      fontFamily: 'prominent',
      color: 'primary',
      fontWeight: 'bold',
      fontSize: '18px',
    },
    heading2: {
      variant: 'layout.prominentFix',
      fontFamily: 'prominent',
      color: 'grey5',
      fontWeight: 'bold',
      fontSize: '18px',
    },
    heading3: {
      variant: 'layout.prominentFix',
      fontFamily: 'prominent',
      color: 'grey5',
      fontWeight: 'semiBold',
      fontSize: '18px',
    },
    subHeading: {
      fontFamily: 'body',
      color: 'grey4',
      fontWeight: 'normal',
      fontSize: '16px',
      paddingTop: '4px',
    },
  },
} as Theme;
