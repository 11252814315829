/** @jsxImportSource theme-ui */
import { ForwardedRef, Fragment, ReactNode } from 'react';
import { Flex } from 'theme-ui';

import { Position } from '@cca/util';

import { forwardRefWithDisplayName } from '../../../hocs';
import { ArrowIcon } from '../../icons';
import { Text } from '../../text';
import Themed from '../../utility/themed/Themed';

export interface ListItemProps {
  // add null to type definition as gql sets undefined properties to null
  label?: string | null;
  // add null to type definition as gql sets undefined properties to null
  description?: string | null;
  selected?: boolean;
  withLink?: boolean;
  onClick?: () => void;
  children?: ReactNode | null;
  disabled?: boolean;
}

const ListItem = (
  {
    label = '',
    description = '',
    selected = false,
    withLink = false,
    onClick = () => {},
    children = null,
    disabled = false,
  }: ListItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  const defaultContent = (
    <Fragment>
      {label && (
        <Themed seSx={{ variant: 'layout.prominentFix' }}>
          <Text styles={{ fontWeight: 'semiBold', fontFamily: 'prominent' }}>
            {label}
          </Text>
        </Themed>
      )}
      {description && <Text>{description}</Text>}
    </Fragment>
  );

  const customContent = <Fragment>{children}</Fragment>;

  return (
    <li
      ref={ref}
      sx={{
        variant: 'layout.listItem',
        '&:hover': {
          backgroundColor: !disabled && withLink ? 'grey1' : 'inherit',
        },
        ...(selected && { backgroundColor: withLink ? 'grey1' : 'inherit' }),
        ...(withLink ? { cursor: 'pointer' } : { cursor: 'default' }),
        ...(disabled && { cursor: 'not-allowed' }),
      }}
      onClick={() => {
        if (!disabled && withLink) {
          onClick();
        }
      }}
    >
      <Flex
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
        as="span"
      >
        <Flex
          sx={{
            flexDirection: 'column',
            flex: '0 1 auto',
            ...(withLink && { paddingRight: '20px' }),
          }}
          as="span"
        >
          {children ? customContent : defaultContent}
        </Flex>
        {withLink && (
          <Flex sx={{ flex: '0 0 auto' }}>
            <ArrowIcon orientation={Position.Right} />
          </Flex>
        )}
      </Flex>
    </li>
  );
};

export default forwardRefWithDisplayName(ListItem, 'ListItem');
