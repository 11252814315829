import { createAsyncThunk } from '@reduxjs/toolkit';

import apolloClient from '../../../services/apolloClient';
import logger from '../../../services/logger';
import { AppState } from '../../types';

import { locationActions } from './index';
import { searchLocationGql } from './queries.gql';

export const getLocationSuggestions = createAsyncThunk<
  void,
  string,
  { state: AppState }
>(
  'outlet/getLocationSuggestions',
  async (searchTerm, { getState, dispatch, signal }) => {
    const {
      messages: { currentSessionId },
    } = getState();

    try {
      const result = await apolloClient.query({
        query: searchLocationGql,
        variables: {
          searchTerm,
          sessionId: currentSessionId,
        },
        context: {
          fetchOptions: {
            signal,
          },
        },
      });

      dispatch(
        locationActions.setLocationSuggestions(
          result.data.searchLocation.suggestions,
        ),
      );
    } catch (error) {
      dispatch(locationActions.setLocationSuggestions([]));
      logger.error(error);
    }
  },
);
