import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';

import { Translations } from '@cca/types';

import { getTranslationsGql } from './queries.gql';
import {
  BackendModule,
  CallbackError,
  InitOptions,
  ResourceLanguage,
  Services,
} from 'i18next';

type GraphQLBackendOptions = {
  debug: boolean;
  apolloClient: ApolloClient<NormalizedCacheObject> | null;
};
export default class GraphQLBackend
  implements BackendModule<GraphQLBackendOptions>
{
  // see https://github.com/i18next/i18next/issues/1440
  // for the need to declare the type twice
  static type: 'backend' = 'backend';
  readonly type: 'backend' = 'backend';

  constructor(
    private services: Services,
    private options: GraphQLBackendOptions = {
      debug: true,
      apolloClient: null,
    },
    private allOptions: InitOptions = {},
  ) {}

  // the init function is called by i18n to pass custom options to the plugin
  init(
    services: Services,
    options: GraphQLBackendOptions,
    allOptions: InitOptions,
  ) {
    this.services = services || this.services;
    this.options = options || this.options;
    this.allOptions = allOptions || this.allOptions;
  }

  async read(
    language: string,
    namespace: string,
    callback: (
      error: CallbackError,
      translations: Translations | boolean,
    ) => void,
  ) {
    if (this.options.apolloClient) {
      const translations = await this.options.apolloClient.query({
        query: getTranslationsGql,
        variables: {
          lang: language,
        },
      });

      if (translations.data.translations.translations) {
        callback(null, translations.data.translations.translations);
      } else {
        const errorMessage = `i18n: translation "${language}" not found`;
        this.log(errorMessage);
        callback(new Error(errorMessage), false);
      }
    } else {
      throw new Error('i18n: apollo client not available');
    }
  }

  // only used in backends acting as cache layer
  save(language: string, namespace: string, data: ResourceLanguage) {
    // TODO: somehow `save` and/or `create` are not triggered. investigate and transfer the missing keys to the backend
    // store the translations
  }

  /** Save the missing translation */
  create(
    languages: string[],
    namespace: string,
    key: string,
    fallbackValue: string,
  ) {
    this.log(
      `Translation key "${key}" not found for language(s) "${languages.join(
        ', ',
      )}". Using fallback "${fallbackValue}".`,
    );
  }

  private log(message: string) {
    if (this.options.debug) {
      console.error(message);
    }
  }
}
