import { Theme } from 'theme-ui';

export default {
  layout: {
    typingIndicatorContainer: {
      border: 'none',
      backgroundColor: 'grey1',
      padding: '10px 16px',
      borderRadius: '12px 12px 12px 0',
    },
    typingIndicator: {
      willChange: 'transform',
      padding: '10px',
      display: 'table',
      position: 'relative',
      animation: '2s pulse infinite ease-out',
      span: {
        height: '6px',
        width: '6px',
        float: 'left',
        margin: '0 2px',
        backgroundColor: 'grey5',
        display: 'block',
        opacity: '0.4',
        transform: 'rotate(45deg)',
        '&:nth-of-type(1)': {
          animation: '1s blink infinite 0.3333s',
        },
        '&:nth-of-type(2)': {
          animation: '1s blink infinite 0.6666s',
        },
        '&:nth-of-type(3)': {
          animation: '1s blink infinite 0.9999s',
        },
      },
      '@keyframes blink': {
        '50%': {
          opacity: '1',
        },
      },
      '@keyframes pulse': {
        '50%': {
          transform: 'scale(1.03)',
        },
      },
    },
  },
} as Theme;
