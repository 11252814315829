import environment from 'environment';

import { createStore } from '@cca/store';

import { clickTrackingReducer } from './actions/click-tracking';
import { feedbackReducer } from './actions/feedback';
import { inputReducer } from './actions/input';
import { locationReducer } from './actions/location';
import { messagesReducer } from './actions/messages';
import { productsReducer } from './actions/products';
import { surveyReducer } from './actions/survey';
import { userAgentReducer } from './actions/user-agent';

const { rootReducer, store } = createStore({
  reducers: {
    ...surveyReducer,
    ...productsReducer,
    ...locationReducer,
    ...inputReducer,
    ...feedbackReducer,
    ...clickTrackingReducer,
    ...messagesReducer,
    ...userAgentReducer,
  },
  debug: !environment.isProduction,
});

export { rootReducer, store };
