import { DateTime, Settings } from 'luxon';
import { dropLast, last } from 'ramda';

import { Locale } from '@cca/util';

import { OpeningTime } from '../opening-times';

import {
  OutletAddressUtil,
  OutletOpeningTimeEntryUtil,
  OutletOpeningTimeExceptionEntryUtil,
  OutletOpeningTimeGroupUtil,
} from './outlet.types';

export function concatenateAddress(address: OutletAddressUtil) {
  return `${address.street}${
    address.streetNumber ? ` ${address.streetNumber}` : ''
  }, ${address.zipCode} ${address.city}`;
}

export function getOpeningTimeExceptionValue(
  openingTimes: OutletOpeningTimeExceptionEntryUtil[],
  date: DateTime,
): string | undefined {
  return openingTimes?.find((openingTime) =>
    DateTime.fromSQL(openingTime.date)
      .startOf('day')
      .equals(date.startOf('day')),
  )?.description;
}

export function mapToOpeningTimes(
  openingTimes: OutletOpeningTimeEntryUtil[],
  timeSuffix: string,
  locale: Locale,
  timezone?: string,
): OpeningTime[] {
  return calculateOpeningTimeGroups(openingTimes).map((openingTimeGroup) => ({
    weekDays: mapToOpeningTimeWeekDays(openingTimeGroup, locale),
    duration: mapToOpeningTimeDuration(
      openingTimeGroup,
      timeSuffix,
      locale,
      timezone,
    ),
  }));
}

function calculateOpeningTimeGroups(
  openingTimeEntries: OutletOpeningTimeEntryUtil[],
): OutletOpeningTimeGroupUtil[] {
  return openingTimeEntries.reduce<OutletOpeningTimeGroupUtil[]>(
    (acc, current) => {
      const currentGroup = last(acc);
      if (
        !currentGroup ||
        currentGroup.openingTime !== current.openingTime ||
        currentGroup.closingTime !== current.closingTime
      ) {
        return [
          ...acc,
          {
            openingTime: current.openingTime,
            closingTime: current.closingTime,
            weekDays: [current.weekDay],
          },
        ];
      } else {
        return [
          ...dropLast(1, acc),
          {
            ...currentGroup,
            weekDays: [...currentGroup.weekDays, current.weekDay],
          },
        ];
      }
    },
    [],
  );
}

function mapToOpeningTimeDuration(
  openingTimeGroup: OutletOpeningTimeGroupUtil,
  timeSuffix: string,
  locale: Locale,
  timezone?: string,
): string {
  return `${toLocaleTimeString(
    openingTimeGroup.openingTime,
    locale,
    timezone,
  )} - ${toLocaleTimeString(openingTimeGroup.closingTime, locale, timezone)}${
    timeSuffix ? ` ${timeSuffix}` : ''
  }`;
}

function toLocaleTimeString(
  isoTime: string,
  locale: Locale,
  timezone?: string,
): string {
  return DateTime.fromISO(isoTime)
    .setLocale(locale)
    .setZone(timezone || Settings.defaultZone)
    .toLocaleString(DateTime.TIME_SIMPLE);
}

function mapToOpeningTimeWeekDays(
  openingTimeGroup: OutletOpeningTimeGroupUtil,
  locale: string,
): string {
  return `${mapWeekDayToLabel(openingTimeGroup.weekDays[0], locale)}${
    openingTimeGroup.weekDays.length > 1
      ? ` - ${mapWeekDayToLabel(
          last(openingTimeGroup.weekDays) as number,
          locale,
        )}`
      : ''
  }`;
}

function mapWeekDayToLabel(weekDay: number, locale: string): string {
  return DateTime.local()
    .setLocale(locale)
    .set({ weekday: weekDay })
    .toFormat('EEE');
}
