import { ForwardedRef } from 'react';
import { Flex, Image } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';

export type OutletLocationMapProps = {
  src: string;
};
const OutletLocationMap = (
  { src }: OutletLocationMapProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Flex
      ref={ref}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image sx={{ width: '100%' }} src={src} />
    </Flex>
  );
};

export default forwardRefWithDisplayName(
  OutletLocationMap,
  'OutletLocationMap',
);
