import { graphql } from '@cca/chatbot-graphql-types';

const quickReplyFragmentGql = graphql(/* GraphQL*/ `
  fragment QuickReplyMessageFields on QuickReplyMessage {
    id
    scope
    type
    options {
      action {
        ...ActionFields
      }
      label
    }
    keepPreviousMessagesActive
  }
`);

export default quickReplyFragmentGql;
