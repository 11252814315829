import { Box, Flex } from 'theme-ui';

export type TypingIndicatorProps = {};
// eslint-disable-next-line no-empty-pattern
const TypingIndicator = ({}: TypingIndicatorProps) => {
  return (
    <Flex>
      <Box variant="layout.typingIndicatorContainer">
        <Box variant="layout.typingIndicator">
          <span></span>
          <span></span>
          <span></span>
        </Box>
      </Box>
    </Flex>
  );
};

export default TypingIndicator;
