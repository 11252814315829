import { Box } from 'theme-ui';

type Props = {
  name: string;
  value: string;
};

const Feature = ({ name, value }: Props) => {
  return (
    <Box>
      <Box sx={{ fontSize: '12px' }}>{name}</Box>
      <Box
        sx={{
          fontWeight: 600,
          margin: '0px',
          fontSize: '14px',
        }}
      >
        {value}
      </Box>
    </Box>
  );
};

export default Feature;
