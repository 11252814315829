import { Theme } from 'theme-ui';

export default {
  layout: {
    suggestionList: {
      padding: '9px 0',
      backgroundColor: 'white',
      boxShadow:
        'rgba(0,0,0,0.07) 0px 3px 4px 0px, rgba(0,0,0,0.06) 0px 3px 3px -2px, rgba(0,0,0,0.1) 0px 1px 8px 0px;',
    },
  },
} as Theme;
