import capitalize from 'lodash/capitalize';
import { ReactNode, Ref, useMemo } from 'react';
import { Box } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';

type Props = {
  children: ReactNode;
  floatingSlot?: ReactNode;
  variant?: 'default' | 'regular';
};
const Main = (
  { children, floatingSlot, variant = 'default' }: Props,
  ref: Ref<HTMLDivElement>,
) => {
  const themeVariant = useMemo(
    () =>
      variant === 'default'
        ? 'layout.main'
        : `layout.main${capitalize(variant)}`,
    [variant],
  );

  return (
    <Box variant={themeVariant} ref={ref}>
      {children}
      {floatingSlot && (
        <Box
          sx={{ position: 'fixed', bottom: '75px', right: '20px', zIndex: '1' }}
        >
          {floatingSlot}
        </Box>
      )}
    </Box>
  );
};

export default forwardRefWithDisplayName(Main, 'Main');
