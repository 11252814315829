import { ComponentType, ForwardedRef, Suspense } from 'react';

import { Position } from '@cca/util';
import { useLazyComponent } from '@cca/util-react';

import { forwardRefWithDisplayName } from '../../../hocs';
import ChatHistoryEntry from '../chat-history-entry/ChatHistoryEntry';
import { BotMessage as IBotMessage, MessageAdditionalProps } from '../types';

import LoadingMessageEntry from './LoadingMessageEntry';

export type BotMessageEntryProps = {
  message: IBotMessage<{ type: string }>;
  showAvatar: boolean;
  showBotName: boolean;
  disabled?: boolean;
  scrollToOnInit?: boolean;
  componentLoader: () => Promise<{ default: ComponentType<any> }>;
};

type Props = BotMessageEntryProps;
const BotMessageEntry = (
  {
    message,
    disabled = false,
    scrollToOnInit = false,
    showAvatar,
    showBotName,
    componentLoader,
  }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const MessageComponent = useLazyComponent<MessageAdditionalProps>(
    componentLoader,
    message.message.type,
  );

  return (
    <Suspense fallback={<LoadingMessageEntry />}>
      <ChatHistoryEntry
        ref={ref}
        messageId={message.id}
        orientation={Position.Left}
        animate={message.animate}
        hasFullWidth={
          !['common/text', 'common/interpolated-text'].includes(
            message.message.type,
          )
        }
        scrollToOnInit={scrollToOnInit}
      >
        <MessageComponent
          {...message.message}
          disabled={disabled}
          showAvatar={showAvatar}
          showBotName={showBotName}
          created={message.created}
        />
      </ChatHistoryEntry>
    </Suspense>
  );
};

export default forwardRefWithDisplayName(BotMessageEntry, 'BotMessageEntry');
