import { graphql } from '@cca/chatbot-graphql-types';

const paginationFragmentGql = graphql(/* GraphQL*/ `
  fragment PaginationFields on Pagination {
    pageSize
    skipPages
    currentPage
    totalPages
    hasNextPage
    totalItems
  }
`);

export default paginationFragmentGql;
