import { graphql } from '@cca/chatbot-graphql-types';

const contactCardOpeningsFragmentGql = graphql(/* GraphQL*/ `
  fragment ContactCardOpeningsFields on ContactCardOpenings {
    label
    openingTime
    closingTime
  }
`);

const contactCardFragmentGql = graphql(/* GraphQL*/ `
  fragment ContactCardMessageFields on ContactCardMessage {
    id
    scope
    type
    heading
    callInvitationLabel
    availableLabel
    notAvailableLabel
    phoneNumber
    phoneNumberInfo
    timeLabel
    weekdays {
      ...ContactCardOpeningsFields
    }
    saturday {
      ...ContactCardOpeningsFields
    }
    holidays {
      ...ContactCardOpeningsFields
    }
    contactFormLabel
    contactFormLink
  }
`);

export { contactCardOpeningsFragmentGql, contactCardFragmentGql };
