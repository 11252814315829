import { ForwardedRef, ReactNode } from 'react';
import { Box } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../hocs';

export type ListProps = { children: ReactNode };
const List = ({ children }: ListProps, ref: ForwardedRef<HTMLUListElement>) => {
  return (
    // Hack: we need to do this forced cast since prop "as" does not change typing
    // for ref element from HTMLDivElement to HTMLUListElement
    <Box
      ref={ref as ForwardedRef<HTMLDivElement>}
      variant="layout.list"
      as="ul"
    >
      {children}
    </Box>
  );
};

export default forwardRefWithDisplayName(List, 'List');
