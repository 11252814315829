import { graphql } from '@cca/chatbot-graphql-types';

export const searchLocationGql = graphql(/* GraphQL*/ `
  query SearchLocation($searchTerm: String!, $sessionId: String!) {
    searchLocation(searchTerm: $searchTerm, sessionId: $sessionId) {
      suggestions {
        id
        label
      }
    }
  }
`);
