/** @jsxImportSource theme-ui */
type Props = { color?: string };
const CloseIcon = ({ color = 'iconPrimary' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.13"
      height="16.9"
      viewBox="0 0 17.13 16.9"
    >
      <g id="Cross-icon">
        <path
          id="cross-icon-2"
          d="M17.13,1.71,10.3,8.45l6.83,6.73L15.39,16.9,8.56,10.16,1.74,16.9,0,15.18,6.83,8.45,0,1.71,1.74,0,8.56,6.74,15.39,0Z"
          sx={{ fill: color }}
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
