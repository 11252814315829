import { PayloadAction } from '@cca/store';

import { FeedbackState } from './index';

export function addFlowContextLabelByMessageId(
  state: FeedbackState,
  action: PayloadAction<{ messageId: string; flowContextLabel: string }>,
) {
  state.flowContextLabelsByMessageId[action.payload.messageId] =
    action.payload.flowContextLabel;
}
