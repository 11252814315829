import { graphql } from '@cca/chatbot-graphql-types';

export const addIntentFeedbackGql = graphql(/* GraphQL*/ `
  mutation AddIntentFeedback($feedback: IntentFeedback!) {
    submitIntentFeedback(feedback: $feedback) {
      ... on FeedbackResultWithIntent {
        created
        sessionId
        value
      }
    }
  }
`);

export const addFlowFeedbackGql = graphql(/* GraphQL*/ `
  mutation AddFlowFeedback($feedback: FlowFeedback!) {
    submitFlowFeedback(feedback: $feedback) {
      ... on FeedbackResultWithFlowContext {
        created
        sessionId
        value
      }
    }
  }
`);
