import { ForwardedRef, ReactNode } from 'react';
import { Card as UiCard } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../hocs';

export type CardProps = {
  children: ReactNode;
  withOverflow?: boolean;
  disabled?: boolean;
  clickable?: boolean;
  onClick?: () => void;
};
const Card = (
  {
    children,
    withOverflow = false,
    disabled = false,
    clickable = false,
    onClick = () => {},
  }: CardProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <UiCard
      ref={ref}
      sx={{
        ...(!withOverflow && { overflow: 'hidden' }),
        ...(disabled && { opacity: 0.6 }),
        ...(clickable && { cursor: 'pointer' }),
      }}
      onClick={clickable ? onClick : () => {}}
    >
      {children}
    </UiCard>
  );
};

export default forwardRefWithDisplayName(Card, 'Card');
