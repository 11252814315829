import { graphql } from '@cca/chatbot-graphql-types';

export const addUserAgentGql = graphql(/* GraphQL*/ `
  mutation AddUserAgent($userAgent: UserAgentInput!) {
    submitUserAgent(userAgent: $userAgent) {
      created
      sessionId
    }
  }
`);
