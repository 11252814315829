import { ForwardedRef, useMemo } from 'react';
import { Box } from 'theme-ui';

import { ProductFieldsFragment } from '@cca/chatbot-graphql-types';

import { forwardRefWithDisplayName } from '../../../hocs';

import FormattedPrice from './FormattedPrice';
import { getPriceParts } from './util';

export type ProductPriceProps = {
  product: ProductFieldsFragment;
  strikePricePrefix?: string;
  strikePriceCrossedOut?: boolean;
  locale: string;
};

type Props = ProductPriceProps;
const ProductPrice = (
  { product, strikePricePrefix, strikePriceCrossedOut = false, locale }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const unitPriceText = useMemo(
    () =>
      product?.basePrice?.baseInfo && product?.basePrice?.unitPrice
        ? `(${product.basePrice.baseInfo} = ${Intl.NumberFormat(locale, {
            style: 'currency',
            currency: product.price.currency,
          }).format(product.basePrice.unitPrice)})`
        : undefined,
    [locale, product],
  );

  const strikePriceParts = useMemo(() => {
    if (!product?.price?.strikePrice) {
      return;
    }

    return getPriceParts(product.price.strikePrice);
  }, [product.price.strikePrice]);

  const salesPriceParts = useMemo(
    () => getPriceParts(product.price.salesPrice),
    [product.price.salesPrice],
  );

  return (
    <>
      <Box ref={ref} sx={{ display: 'flex', lineHeight: '23px' }}>
        {strikePriceParts && (
          <Box
            sx={{
              marginRight: '8px',
            }}
          >
            <FormattedPrice
              {...strikePriceParts}
              prefix={strikePricePrefix}
              crossedOut={strikePriceCrossedOut}
            />
          </Box>
        )}
        <Box
          variant={
            strikePriceParts ? 'layout.productTileSalesPrice' : undefined
          }
        >
          <FormattedPrice {...salesPriceParts} />
        </Box>
      </Box>
      {unitPriceText && (
        <Box
          variant="layout.productTileUnitPriceText"
          sx={{ fontSize: '12px', lineHeight: '16px' }}
        >
          {unitPriceText}
        </Box>
      )}
    </>
  );
};

export default forwardRefWithDisplayName(ProductPrice, 'ProductPrice');
