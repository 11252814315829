import { PayloadAction } from '@reduxjs/toolkit';

import { MessagesState } from './index';

export function addActiveResponseId(
  state: MessagesState,
  action: PayloadAction<string>,
) {
  state.activeResponseIds = [...state.activeResponseIds, action.payload];
}
