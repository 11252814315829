import { browserName, deviceType, osName } from 'react-device-detect';

import { createAsyncThunk } from '@reduxjs/toolkit';

import apolloClient from '../../../services/apolloClient';

import { addUserAgentGql } from './queries.gql';

export const submitUserAgent = createAsyncThunk<void, string>(
  'userAgent/submitUserAgent',
  async (sessionId) => {
    await apolloClient.mutate({
      mutation: addUserAgentGql,
      variables: {
        userAgent: {
          sessionId,
          deviceType,
          browserName,
          osName,
        },
      },
    });
  },
);
