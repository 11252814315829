import { createFeature } from '@cca/store';

import { submitClickTracking } from './submitClickTracking.state';

export interface ClickTrackingState {}

export const initialClickTrackingState: ClickTrackingState = {};

export const { addThunks, clickTrackingReducer } = createFeature({
  name: 'clickTracking',
  initialState: initialClickTrackingState,
  reducers: {},
});
export const { clickTrackingActions } = addThunks({
  submitClickTracking,
});
