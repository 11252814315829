/** @jsxImportSource theme-ui */
import {
  ForwardedRef,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, Image } from 'theme-ui';
import { Merge } from 'ts-essentials';

import { setForwardedRef } from '@cca/util-react';

import { forwardRefWithDisplayName } from '../../../hocs';
import useThemeBase from '../../../theme/provider/useThemeBase';
import ArticleHeader from '../../article-header/ArticleHeader';
import { ListItem } from '../../list';
import { ListItemProps } from '../../list/list-item/ListItem';
import { Text } from '../../text';

export type ServiceSiteListItemProps = Merge<
  ListItemProps,
  { label: string; description: string }
>;
const ServiceSiteListItem = (
  {
    label,
    description,
    withLink = false,
    onClick = () => {},
    ...listItemProps
  }: ServiceSiteListItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const theme = useThemeBase();
  const [imageUrl, setImageUrl] = useState<string>();
  useEffect(() => {
    import(`./images/service-site.${theme}.jpg`).then((module) =>
      setImageUrl(module.default),
    );
  }, [theme]);

  const onRefLoaded = useCallback(
    (current: HTMLLIElement) => {
      setForwardedRef(ref, current);
    },
    [ref],
  );

  return (
    <Fragment>
      <li
        ref={onRefLoaded}
        sx={{
          variant: 'layout.serviceSiteListItem',
          height: '140px',
          cursor: withLink ? 'pointer' : 'default',
          '&:hover': {
            backgroundColor: withLink ? 'grey1' : 'inherit',
          },
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          if (withLink) {
            onClick();
          }
        }}
      >
        <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
          <Image
            sx={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
            }}
            src={imageUrl}
          />
          <Box
            sx={{
              width: '80%',
              position: 'absolute',
              bottom: '-11px',
              left: '7px',
            }}
          >
            <ArticleHeader label={label} />
          </Box>
        </Box>
      </li>
      <ListItem
        ref={onRefLoaded}
        {...listItemProps}
        withLink={withLink}
        onClick={onClick}
        selected={isHovered}
      >
        <Text styles={{ paddingTop: '11px' }}>{description}</Text>
      </ListItem>
    </Fragment>
  );
};

export default forwardRefWithDisplayName(
  ServiceSiteListItem,
  'ServiceSiteListItem',
);
