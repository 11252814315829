import { Box } from 'theme-ui';

import { EnergyEfficiencyClassEu2017_1369 } from '@cca/chatbot-graphql-types';
import { Position } from '@cca/util';

import ArrowHead from './ArrowHead';

const colors: Record<EnergyEfficiencyClassEu2017_1369, string> = {
  A: '#00a650',
  B: '#4dbd38',
  C: '#b3db18',
  D: '#fff200',
  E: '#fab20b',
  F: '#f25c19',
  G: '#ed1c24',
};

export type EnergyEfficiencyLabelProps = {
  grade: EnergyEfficiencyClassEu2017_1369;
  direction?: Extract<Position, Position.Left | Position.Right>;
};

const EnergyEfficiencyLabel = ({
  grade,
  direction = Position.Left,
}: EnergyEfficiencyLabelProps) => {
  return (
    <Box
      sx={{
        height: '20px',
        width: '40px',
        display: 'flex',
      }}
    >
      <ArrowHead direction={direction} color={colors[grade]} />
      <Box
        sx={{
          backgroundColor: `${colors[grade]}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {grade}
      </Box>
      <Box
        sx={{
          display: 'flex',
          writingMode: 'vertical-lr',
          textOrientation: 'upright',
          justifyContent: 'center',
          alignContent: 'center',
          fontSize: '4px',
          paddingTop: '1px',
          minWidth: '8px',
          border: '1px solid black',
        }}
      >
        A↑G
      </Box>
    </Box>
  );
};

export default EnergyEfficiencyLabel;
