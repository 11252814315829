import fetch from 'cross-fetch';
import { createClient } from 'graphql-ws';
import uri from 'urijs';

import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  InMemoryCacheConfig,
  NormalizedCacheObject,
  split,
} from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

const WEBSOCKET_KEEP_ALIVE = 15000;

export type CreateClientOptions = {
  uri: string;
  fetch?: typeof fetch;
  cacheTypePolicies?: InMemoryCacheConfig['typePolicies'];
  possibleTypes?: InMemoryCacheConfig['possibleTypes'];
  enableSubscriptions?: boolean;
} & Pick<ApolloClientOptions<NormalizedCacheObject>, 'connectToDevTools'>;

export function createApolloClient(
  options: CreateClientOptions,
): ApolloClient<NormalizedCacheObject> {
  // Since __typename is always appended to query results in order to optimize
  // caching, we need to strip it out later on because mutations will not accept it.
  // Reference: https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-676886539
  // Source for workaround: https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
  const cleanTypeNameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: string, value: unknown) =>
        key === '__typename' ? undefined : value;
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename,
      );
    }
    return forward(operation).map((data) => {
      return data;
    });
  });

  const httpLink = new HttpLink({
    uri: options.uri,
    ...(options.fetch && { fetch: options.fetch }),
  });

  const typeNameCleanedHttpLink = ApolloLink.from([
    cleanTypeNameLink,
    httpLink,
  ]);

  const link = extendLinkWithWebsocket(typeNameCleanedHttpLink, options);

  return new ApolloClient({
    connectToDevTools: options.connectToDevTools,
    link,
    cache: new InMemoryCache({
      ...(options.possibleTypes && {
        possibleTypes: options.possibleTypes,
      }),
      ...(options.cacheTypePolicies && {
        typePolicies: options.cacheTypePolicies,
      }),
    }),
  });
}

function createWebsocketUrl(endpoint: string): string {
  const endpointUri = uri(endpoint);
  const protocol = endpointUri.protocol() === 'http' ? 'ws' : 'wss';
  return endpointUri.protocol(protocol).toString();
}

function extendLinkWithWebsocket(
  inputLink: ApolloLink,
  options: CreateClientOptions,
): ApolloLink {
  return options.enableSubscriptions ? constructLink() : inputLink;
  function constructLink(): ApolloLink {
    const wsLink = new GraphQLWsLink(
      createClient({
        url: createWebsocketUrl(options.uri),
        // keep connection alive by sending/receiving ping & pongs since proxies like Cloudflare cancel connections after approx. 90 seconds
        keepAlive: WEBSOCKET_KEEP_ALIVE,
      }),
    );

    // Ensure that only subscriptions use the websocket link since mutations and
    // queries can simply use http.
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      inputLink,
    );
  }
}
