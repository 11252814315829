import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Box } from 'theme-ui';

import { useWindowSize } from '@react-hookz/web';

import { Position } from '@cca/util';

import ThemeContext from '../../theme/provider/ThemeContext';

function getTopForHorizontalPosition(childRect: DOMRect, popoverRect: DOMRect) {
  const heightDiff = Math.abs(childRect.height - popoverRect.height) / 2;
  return childRect.height >= popoverRect.height
    ? childRect.top + heightDiff
    : childRect.top - heightDiff;
}

function getLeftForVerticalPosition(childRect: DOMRect, popoverRect: DOMRect) {
  const widthDiff = Math.abs(childRect.width - popoverRect.width) / 2;
  return childRect.width >= popoverRect.width
    ? childRect.left + widthDiff
    : childRect.left - widthDiff;
}

export type PopoverProps = {
  children: ReactNode;
  text: string;
  position?: Extract<
    Position,
    Position.Up | Position.Right | Position.Down | Position.Left
  >;
};
const Popover = ({
  children,
  text,
  position = Position.Left,
}: PopoverProps) => {
  const { portal } = useContext(ThemeContext);

  const childRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [topPosition, setTopPosition] = useState<number>(0);
  const [leftPosition, setLeftPosition] = useState<number>(0);
  const [opacity, setOpacity] = useState<number>(0);
  const { height: viewportHeight } = useWindowSize();

  const portalContent = createPortal(
    <Box
      ref={popoverRef}
      sx={{
        variant: 'layout.popover',
        position: 'absolute',
        left: `${leftPosition}px`,
        top: `${topPosition}px`,
        opacity: `${opacity}`,
      }}
    >
      {text}
    </Box>,
    portal,
  );

  useEffect(() => {
    if (!visible || !childRef.current || !popoverRef.current) {
      return;
    }

    setOpacity(1);

    const childRect = childRef.current.getBoundingClientRect();
    const popoverRect = popoverRef.current.getBoundingClientRect();

    switch (position) {
      case Position.Left: {
        setLeftPosition(childRect.left - popoverRect.width - 10);
        setTopPosition(getTopForHorizontalPosition(childRect, popoverRect));
        break;
      }
      case Position.Right: {
        setLeftPosition(childRect.left + childRect.width + 10);
        setTopPosition(getTopForHorizontalPosition(childRect, popoverRect));
        break;
      }
      case Position.Up: {
        setLeftPosition(getLeftForVerticalPosition(childRect, popoverRect));
        setTopPosition(childRect.top - popoverRect.height - 10);
        break;
      }
      case Position.Down: {
        setLeftPosition(getLeftForVerticalPosition(childRect, popoverRect));
        setTopPosition(childRect.top + childRect.height + 10);
        break;
      }
    }
  }, [visible, position, viewportHeight]);

  return (
    <>
      <Box
        sx={{ display: 'inline-flex' }}
        ref={childRef}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        {children}
      </Box>
      {visible && portalContent}
    </>
  );
};

export default Popover;
