import { ForwardedRef } from 'react';
import { Box } from 'theme-ui';

import { Position } from '@cca/util';

import { forwardRefWithDisplayName } from '../../hocs';

export type SenderProps = {
  sender?: string;
  orientation: Extract<Position, Position.Left | Position.Right>;
};

type Props = SenderProps;

const Sender = (
  { sender, orientation }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return sender ? (
    <Box
      ref={ref}
      sx={{
        fontSize: '10px',
        paddingTop: '3px',
        textAlign: orientation === Position.Left ? 'left' : 'right',
      }}
    >
      {sender}
    </Box>
  ) : (
    <></>
  );
};

export default forwardRefWithDisplayName(Sender, 'Sender');
