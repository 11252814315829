/** @jsxImportSource theme-ui */
import useThemeBase from '../../../theme/provider/useThemeBase';

type Props = { color?: string };
const SearchIcon = ({ color = 'iconPrimary' }: Props) => {
  const theme = useThemeBase();

  const mmIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.978"
      height="23"
      viewBox="0 0 22.978 23"
    >
      <g transform="translate(0.758 1)">
        <g transform="translate(-0.758 -1)">
          <path
            sx={{ fill: color }}
            d="M21.939,20.364,14.6,13.02a8.625,8.625,0,1,0-1.358,1.353l7.346,7.346a.958.958,0,0,0,1.355-1.355ZM1.159,7.625A6.708,6.708,0,1,1,12.6,12.379h0A6.708,6.708,0,0,1,1.159,7.625Z"
            transform="translate(0.758 1)"
          />
        </g>
      </g>
    </svg>
  );
  const seIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 38.4 38.4"
    >
      <path
        sx={{ fill: color }}
        d="M 0.27 17.597 C -1.68 7.23 7.243 -1.689 17.613 0.273 C 23.513 1.387 28.228 6.203 29.247 12.122 C 30.028 16.65 28.736 20.88 26.167 24.032 L 26.173 24.033 L 28.3 25.486 L 29.205 26.236 L 38.327 35.357 L 35.35 38.334 L 26.148 29.155 L 25.506 28.41 L 23.929 26.254 C 20.773 28.768 16.571 30.031 12.07 29.237 C 6.17 28.197 1.376 23.484 0.27 17.597 Z M 24.883 17.597 C 27.007 9.597 19.877 2.467 11.877 4.589 C 8.363 5.522 5.523 8.364 4.59 11.877 C 2.466 19.877 9.597 27.006 17.597 24.884 C 21.11 23.951 23.952 21.111 24.883 17.597 Z"
      />
    </svg>
  );

  return theme === 'mm' ? mmIcon : seIcon;
};

export default SearchIcon;
