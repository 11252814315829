import { Theme } from 'theme-ui';

export default {
  badges: {
    primary: {
      color: 'white',
      backgroundColor: 'primary',
      fontSize: '12px',
      borderRadius: '11px',
      padding: '2px 10px',
      fontWeight: 'normal',
    },
  },
} as Theme;
