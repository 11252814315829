import { ProductSuggestion } from '@cca/chatbot-graphql-types';
import { createFeature, reducers } from '@cca/store';

import { getProductSuggestions } from './getProductSuggestions.state';

export interface ProductsState {
  productSuggestions: ProductSuggestion[];
}

export const initialProductsState: ProductsState = {
  productSuggestions: [],
};

export const { addThunks, productsReducer } = createFeature({
  name: 'products',
  initialState: initialProductsState,
  reducers: {
    ...reducers<ProductsState>().createSetters(['productSuggestions']),
  },
});
export const { productsActions } = addThunks({
  getProductSuggestions,
});
