import { graphql } from '@cca/chatbot-graphql-types';

export const getProductSuggestionsGql = graphql(/* GraphQL*/ `
  query GetProductSuggestions($query: String!) {
    productSuggestions(query: $query) {
      suggestions {
        value
      }
    }
  }
`);
