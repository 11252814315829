import { Theme, ThemeUIStyleObject } from 'theme-ui';

const boxShadow =
  'rgb(0 0 0 / 7%) 0px 3px 4px 0px, rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 10%) 0px 1px 8px 0px';

export default {
  buttons: {
    primary: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'body',
      cursor: 'pointer',
      height: '48px',
      borderRadius: '0',
      padding: '0px 24px',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: 'primary',
      '&:hover': {
        backgroundColor: 'primaryHover',
        boxShadow,
      },
    },
  },
} as Theme;

export const seStyles: Record<string, ThemeUIStyleObject> = {
  round: {
    borderRadius: '100%',
  },
  squared: {
    width: '48px',
    padding: '0',
  },
  fullWidth: {
    width: '100%',
  },
  outlined: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'grey2',
    color: 'black',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow,
    },
  },
  small: {
    height: '40px',
  },
  secondary: {
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'grey4',
      boxShadow,
    },
  },
  invertedSecondary: {
    color: 'black',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow,
    },
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'black',
  },
  invertedPrimary: {
    color: 'primary',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow,
    },
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'primary',
  },
  disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
  },
  disabledPrimary: {
    '&:hover': {
      backgroundColor: 'primary',
      boxShadow: 'none',
    },
  },
  disabledSecondary: {
    '&:hover': {
      backgroundColor: 'black',
      boxShadow: 'none',
    },
  },
};
