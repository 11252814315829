import { ForwardedRef } from 'react';

import { forwardRefWithDisplayName } from '../../../hocs';
import { Card, CardBody, CardHeader } from '../../card';
import { Heading } from '../../heading';

import ContactCardContent, {
  ContactCardContentProps,
} from './ContactCardContent';

export type ContactCardProps = ContactCardContentProps & {
  heading: string;
};

const ContactCard = (
  { heading, ...props }: ContactCardProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Card ref={ref}>
      <CardHeader>
        <Heading>{heading}</Heading>
      </CardHeader>
      <CardBody>
        <ContactCardContent {...props} />
      </CardBody>
    </Card>
  );
};

export default forwardRefWithDisplayName(ContactCard, 'ContactCard');
