import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

import { MessageFields } from '@cca/chatbot-graphql-types';
import { FeatureReducerWithPrepare } from '@cca/store';
import { BotMessage, UserMessage } from '@cca/ui';

import {
  HistoryMessage,
  HistoryMessageDeserialized,
  MessagesState,
} from '../index';

export function forUser(text: string): UserMessage {
  return {
    id: uuid(),
    animate: true,
    isBot: false,
    created: DateTime.local(),
    inputType: 'text',
    value: text,
  };
}

export function forBot(
  botMessageProps: Omit<BotMessage<MessageFields>, 'id' | 'animate' | 'isBot'>,
): BotMessage<MessageFields> {
  return {
    id: botMessageProps.message.id,
    animate: true,
    isBot: true,
    ...botMessageProps,
  };
}

export const addMessage: FeatureReducerWithPrepare<
  MessagesState,
  HistoryMessage,
  HistoryMessageDeserialized
> = {
  reducer(state, action) {
    state.history = state.history.concat(action.payload);
  },
  prepare(payload) {
    return {
      payload: {
        ...payload,
        created: payload.created.toISO() || '',
      },
    };
  },
};
