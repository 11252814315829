import environment from 'environment';

import { createApolloClient } from '@cca/util';

const apolloClient = createApolloClient({
  connectToDevTools: !environment.isProduction,
  uri: environment.middlewareUrl,
});

export default apolloClient;
