import { ForwardedRef, ReactNode } from 'react';
import { Box, Flex } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';

type Props = {
  icon?: ReactNode;
  children: ReactNode;
};
const StructuredEntry = (
  { icon, children }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Flex ref={ref} sx={{ alignItems: 'center', marginTop: '16px' }}>
      <Flex
        sx={{
          paddingLeft: '10px',
          paddingRight: '30px',
          fontSize: '16px',
          width: '60px',
          justifyContent: 'center',
          flex: '0 0 auto',
        }}
      >
        {icon}
      </Flex>
      <Box>{children}</Box>
    </Flex>
  );
};

export default forwardRefWithDisplayName(StructuredEntry, 'StructuredEntry');
