import { createContext } from 'react';

import { ClickTracking } from './types';

export type ClickTrackingContextOptions = {
  uiComponent: (props: ClickTracking['uiComponent']) => void;
  position: (props: ClickTracking['position']) => void;
  botState: (props: ClickTracking['botState']) => void;
  context: (props: ClickTracking['context']) => void;
  rendered: (props: ClickTracking['rendered']) => void;
  sessionId: (props: ClickTracking['sessionId']) => void;
  action: (props: ClickTracking['action']) => void;
};

// Only some options of `ClickTrackingContextOptions` will be accessed in child components
// of `ClickTrackingContext`.
export type ClickTrackingContextProps = Pick<
  ClickTrackingContextOptions,
  'uiComponent' | 'context' | 'rendered' | 'action'
>;

const ClickTrackingContext = createContext<ClickTrackingContextProps>({
  uiComponent: () => {},
  context: () => {},
  rendered: () => {},
  action: () => {},
});

export default ClickTrackingContext;
