import { Theme } from 'theme-ui';

export default {
  forms: {
    input: {
      fontFamily: 'body',
      width: '100%',
      backgroundColor: 'white',
      padding: '12px 9px',
      outline: 'none',
      color: 'text',
      borderWidth: '1px',
      borderColor: 'grey5',
      borderStyle: 'solid',
      borderRadius: 'unset',
    },
  },
} as Theme;
