import { Box, Flex } from 'theme-ui';

import { Text } from '../text';

export type StatusProps = {
  color: 'green' | 'orange';
  children: string;
};
const Status = ({ color, children }: StatusProps) => {
  return (
    <Flex color={color} sx={{ alignItems: 'center' }}>
      <Box
        sx={{
          width: '9px',
          height: '9px',
          borderRadius: '50%',
          marginRight: '10px',
          backgroundColor: color,
        }}
      />
      <Text styles={{ color, fontSize: '14px' }}>{children}</Text>
    </Flex>
  );
};

export default Status;
