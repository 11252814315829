import { Theme } from 'theme-ui';

export default {
  layout: {
    suggestionList: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'grey3',
      borderRadius: '4px',
      padding: '9px 0',
      backgroundColor: 'white',
    },
  },
} as Theme;
