import { ReactNode, useMemo } from 'react';
import { Theme, ThemeProvider as UiThemeProvider } from 'theme-ui';

import GlobalFonts from '../global-fonts/GlobalFonts';
import mmTheme from '../mm';
import seTheme from '../se';
import { ThemeConfig, UiTheme } from '../types';
import composeTheme from '../util/composeTheme';

import ThemeContext from './ThemeContext';

const themes: Record<UiTheme | 'default', ThemeConfig> = {
  default: mmTheme,
  mm: mmTheme,
  mmes: mmTheme,
  se: seTheme,
};

export type ThemeProviderProps = {
  children: ReactNode;
  portal: HTMLElement;
  theme: UiTheme;
  themeOverrides?: Theme;
};
const ThemeProvider = ({
  children,
  portal,
  theme,
  themeOverrides = {},
}: ThemeProviderProps) => {
  const selectedTheme = useMemo(() => themes[theme] || themes.default, [theme]);
  const preprocessedTheme = composeTheme(selectedTheme.theme, themeOverrides);

  return (
    <ThemeContext.Provider value={{ portal, theme }}>
      <UiThemeProvider theme={preprocessedTheme}>
        <GlobalFonts fonts={selectedTheme.fonts} />
        {children}
      </UiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
