import { createContext } from 'react';

export type ChatHistoryContextProps = {
  scrollToBottom: () => void;
};

const ChatHistoryContext = createContext<ChatHistoryContextProps>({
  scrollToBottom: () => {},
});

export default ChatHistoryContext;
