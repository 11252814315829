import { AnimatePresence, motion } from 'framer-motion';
import { ComponentType, ForwardedRef, RefAttributes, useState } from 'react';
import { Box } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';
import ProductFeatureList, {
  ProductFeature,
} from '../product-feature-list/ProductFeatureList';

import ExpandButton, { ExpandButtonProps } from './ExpandButton';

export type ProductExpandProps = {
  features: ProductFeature[];
  ExpandButtonComponent?: ComponentType<ExpandButtonProps>;
} & RefAttributes<HTMLDivElement>;

type Props = ProductExpandProps;
const ProductExpand = (
  { features, ExpandButtonComponent = ExpandButton }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Box
      ref={ref}
      sx={{ paddingTop: '30px', cursor: 'pointer', position: 'relative' }}
    >
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 0.35,
            }}
          >
            <ProductFeatureList features={features} />
          </motion.div>
        )}
      </AnimatePresence>
      <ExpandButtonComponent
        expand={expanded}
        onChange={(expand) => {
          setExpanded(expand);
        }}
      />
    </Box>
  );
};

export default forwardRefWithDisplayName(ProductExpand, 'ProductExpand');
