/** @jsxImportSource theme-ui */
import { motion } from 'framer-motion';
import { useState } from 'react';

import * as colors from '../../../theme/mm/colors';

type Props = {
  color?: keyof typeof colors;
  hoverColor?: keyof typeof colors | false;
  height?: number;
};
const DislikeIcon = ({
  color = 'iconPrimary',
  hoverColor = 'iconSecondary',
  height = 32,
}: Props) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const animate = {
    fill: colors[color],
    transition: { duration: 0 },
    ...(hoverColor && hovered && { fill: colors[hoverColor] }),
  };

  return (
    <motion.div
      animate={animate}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height}
        viewBox="0 0 34.848 32.796"
      >
        <g transform="translate(0 0)">
          <path
            d="M34.848,23.857A3.139,3.139,0,0,1,33.9,26.1a3.5,3.5,0,0,1,.929,2.722,3.731,3.731,0,0,1-3.787,3.233H22.076a20.18,20.18,0,0,1,1.156,5.7c0,3.093-2.676,5.7-4.356,5.7a4.61,4.61,0,0,1-2.631-.868.709.709,0,0,1-.273-.558V37.2l-4.183-8.9-.173-.087v.272a.72.72,0,0,1-.726.713H3.63A3.6,3.6,0,0,1,0,25.639V14.232a3.6,3.6,0,0,1,3.63-3.565H7.986A3.635,3.635,0,0,1,11.4,13.024a9.607,9.607,0,0,1,3.844-.931H28.574a3.33,3.33,0,0,1,3.29,2.49,3.167,3.167,0,0,1-.261,2.143A3.19,3.19,0,0,1,33.4,19.579,3.125,3.125,0,0,1,33.057,21,3.19,3.19,0,0,1,34.848,23.857ZM31.786,22.1a.727.727,0,0,1-.607-.483.706.706,0,0,1,.192-.744,1.745,1.745,0,0,0,.574-1.29,1.789,1.789,0,0,0-1.609-1.76.727.727,0,0,1-.607-.483.706.706,0,0,1,.192-.744,1.743,1.743,0,0,0,.526-1.7,1.874,1.874,0,0,0-1.872-1.372H15.246A6.968,6.968,0,0,0,11.4,14.736a.743.743,0,0,1-.791.154.713.713,0,0,1-.449-.659,2.161,2.161,0,0,0-2.178-2.139H3.63a2.161,2.161,0,0,0-2.178,2.139V25.639A2.161,2.161,0,0,0,3.63,27.778h6.534v-.713a.713.713,0,0,1,.346-.607.754.754,0,0,1,.706-.03l1.452.713a.718.718,0,0,1,.334.338l4.356,9.268a.715.715,0,0,1,.067.3v4.6a3.1,3.1,0,0,0,1.452.386c.8,0,2.9-1.942,2.9-4.278a21.872,21.872,0,0,0-1.4-6.163.707.707,0,0,1,.08-.659.734.734,0,0,1,.6-.308h9.991a2.28,2.28,0,0,0,2.341-1.938,2.119,2.119,0,0,0-1.034-2.018.708.708,0,0,1,.049-1.242,1.762,1.762,0,0,0,.995-1.576A1.789,1.789,0,0,0,31.786,22.1Z"
            transform="translate(0 -10.667)"
          />
        </g>
        <g transform="translate(10.164 2.852)">
          <path
            d="M150.059,259.592a.72.72,0,0,1-.726-.713V246.046a.726.726,0,0,1,1.452,0v12.833A.72.72,0,0,1,150.059,259.592Z"
            transform="translate(-149.333 -245.333)"
          />
        </g>
      </svg>
    </motion.div>
  );
};

export default DislikeIcon;
