// primitive colors
export const grey1 = '#f4f4f4';
export const grey2 = '#cdcaca';
export const grey3 = '#958f8f';
export const grey4 = '#4c4a4a';
export const grey5 = '#201d1d';
export const red = '#df0000';
export const redHover = '#cb0000';
export const lightRed = '#ffc0cb';
export const black = '#000';
export const white = '#fff';
export const green = '#70c118';
export const orange = '#ffa500';
export const borderColor = '#cfcbca';
export const blackHover = '#494745';
export const hrColor = '#918e8c';
export const hrHover = '#272422';

// predefined ui-theme color variables
export const text = grey5;
export const background = 'transparent';
export const primary = red;
export const secondary = '#777777';
export const accent = red;
export const highlight = red;
export const muted = '#f6f6f6';

// custom theme colors
export const iconPrimary = grey5;
export const iconSecondary = secondary;
