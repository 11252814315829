import { graphql } from '@cca/chatbot-graphql-types';

const surveyButtonFragmentGql = graphql(/* GraphQL*/ `
  fragment SurveyButtonMessageFields on SurveyButtonMessage {
    id
    scope
    type
    label
  }
`);

export default surveyButtonFragmentGql;
