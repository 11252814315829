import { ForwardedRef, ReactNode } from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';

import { forwardRefWithDisplayName } from '../../../hocs';

export type SuggestionListProps = {
  children: ReactNode;
  sx?: ThemeUIStyleObject;
};

const SuggestionList = (
  { children, sx = {} }: SuggestionListProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <Box
      ref={ref}
      sx={{
        variant: 'layout.suggestionList',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default forwardRefWithDisplayName(SuggestionList, 'SuggestionList');
