import { DateTime } from 'luxon';
import { createSelector } from 'reselect';

import { HistoryMessageDeserialized, messagesSelectors } from '.';

const getMessageHistory = createSelector(
  messagesSelectors.getSerializedMessageHistory,
  (history): HistoryMessageDeserialized[] =>
    history.map((message) => ({
      ...message,
      created: DateTime.fromISO(message.created),
    })),
);

export const messagesCombiners = {
  getMessageHistory,
};
